<template>
  <v-app id="inspire" class="pa-0 ma-0">
    <CustomAppbar @signOutUser="logout()" @signInUser="signInUser()"></CustomAppbar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <!--- dialogs--->
    <!--- User onboarding signup form-->
    <v-dialog v-model="models.sign_up" max-width="500">

      <v-window v-model="models.user_onboarding" touchless>
        <ValidationObserver v-slot="{ handleSubmit }">
          <v-form @submit.prevent="handleSubmit(signUpUser)">
            <v-window-item :value="1">
              <v-card class="pa-10 pt-3">
                <v-card-title>
                  <v-img :src="user_profile" class="d-flex justify-center" height="60" contain width="60"></v-img>
                  <v-icon @click="models.sign_up = false" small class="">mdi-close</v-icon>
                </v-card-title>
                <v-card-text class="mt-5">
                  <h3>This Profile is for </h3>
                  <v-chip-group v-model="new_user.created_for" max="1" column class="pt-2 ma-auto mr-5"
                    :change="validateProfileContinuation()">
                    <v-chip filter class="elevation-2" color="primary" v-for="(item, i) in profile_creation_for"
                      :value="item.text" :key="i">
                      <span>{{ item.text }}</span>
                    </v-chip>
                  </v-chip-group>
                </v-card-text>
                <v-expand-transition>
                  <v-card-text v-if="new_user.created_for == 'My Self'" class="mt-4">
                    <h3>Gender</h3>
                    <v-chip-group v-model="new_user.user_gender" max="1" column class="pt-2"
                      :change="validateProfileContinuation()">
                      <v-chip filter class="elevation-3" color="primary" value="Male">
                        <span>Male</span>
                      </v-chip>
                      <v-chip filter class="elevation-3" color="primary" value="Female">
                        <span>Female</span>
                      </v-chip>
                    </v-chip-group>
                  </v-card-text>
                </v-expand-transition>

                <v-card-actions class="text-center">
                  <v-btn class="pa-4 pl-10 pr-10 ma-auto" color="success" rounded @click="models.user_onboarding = 2"
                    :disabled="disabled_continue_button">
                    Continue
                  </v-btn>

                </v-card-actions>
                <v-card-text class="text-center">
                  <v-btn class="pa-4 mb-5 text-center text-capitalize text-caption" plain text color="primary" link
                    @click="models.sign_in = true; models.sign_up = false; ">
                    Sign In Instead ?
                  </v-btn>
                </v-card-text>

              </v-card>
            </v-window-item>

            <v-window-item :value="2">
              <ValidationObserver v-if="models.user_onboarding === 2">
                <v-card class="pa-10 pt-3">
                  <v-card-title>
                    <v-icon @click="models.user_onboarding = 1" small class="">mdi-arrow-left</v-icon>
                    <v-img :src="user_name" class="d-flex justify-center" height="60" contain width="60"></v-img>
                    <v-icon @click="models.sign_up = false" small class="">mdi-close</v-icon>
                  </v-card-title>
                  <v-card-text class="mt-5">
                    <h3 class="mb-3">Your Details </h3>
                    <v-row>

                      <v-col lg="12">
                        <ValidationProvider
                          :name="new_user.user_gender === 'Male' ? 'Groom First Name' : 'Bride First Name'"
                          v-slot="{ errors }" persist rules="required|max:50|alpha">
                          <v-text-field outlined
                            :label="new_user.user_gender == 'Male' ? 'Groom First Name' : 'Bride First Name'"
                            v-model="new_user.first_name" :error-messages="errors"></v-text-field>
                        </ValidationProvider>
                        <ValidationProvider
                          :name="new_user.user_gender === 'Male' ? 'Groom Last Name' : 'Bride Last Name'"
                          v-slot="{ errors }" persist rules="required|max:50|alpha">
                          <v-text-field outlined
                            :label="new_user.user_gender == 'Male' ? 'Groom Last Name' : 'Bride Last Name'"
                            v-model="new_user.last_name" :error-messages="errors"></v-text-field>
                        </ValidationProvider>
                        <v-dialog ref="dialog" v-model="models.date_picker" :return-value.sync="new_user.date_of_birth"
                          persistent width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <ValidationProvider name="Date of Birth" v-slot="{ errors }" persist rules="required">
                              <v-text-field outlined v-model="new_user.date_of_birth" label="Date of Birth" readonly
                                v-bind="attrs" v-on="on" :error-messages="errors"></v-text-field>
                            </ValidationProvider>
                          </template>
                          <v-date-picker color="orange" v-model="new_user.date_of_birth" :min="getMinDate"
                            :max="getMaxDate" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="orange" @click="models.date_picker = false">
                              Cancel
                            </v-btn>
                            <v-btn text color="orange" @click="$refs.dialog.save(new_user.date_of_birth)">
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>

                      </v-col>

                    </v-row>
                  </v-card-text>
                  <v-card-actions class="text-center">
                    <v-btn class="pa-4 pl-10 pr-10 ma-auto" color="success" rounded type="submit">
                      Continue
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </ValidationObserver>
            </v-window-item>


            <v-window-item :value="3" touchless>
              <ValidationObserver v-if="models.user_onboarding === 3">
                <v-card class="pa-10 pt-3">
                  <v-card-title>
                    <v-icon @click="models.user_onboarding = 2" small class="">mdi-arrow-left</v-icon>
                    <v-img :src="user_email" class="d-flex justify-center" height="60" contain width="60"></v-img>
                    <v-icon @click="models.sign_up = false" small class="">mdi-close</v-icon>
                  </v-card-title>
                  <v-card-text class="text-center">
                    <span class="caption ma-auto">An active email address & phone no.<br>are required to secure
                      your Profile</span>
                  </v-card-text>
                  <v-card-text class="mt-5">
                    <v-row>

                      <v-col lg="12" cols="12">
                        <ValidationProvider name="Religion" v-slot="{ errors }" persist rules="required">
                          <v-select outlined label="Religion" v-model="new_user.religion" :items="religions"
                            :error-messages="errors" item-value="text">
                          </v-select>
                        </ValidationProvider>
                        <ValidationProvider name="Email Address" v-slot="{ errors }" persist rules="required|email">
                          <v-text-field outlined label="Email Address" v-model="new_user.email_address"
                            :error-messages="errors"></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col lg="4" cols="12">
                        <ValidationProvider name="Country" v-slot="{ errors }" persist rules="required">
                          <v-select outlined label="Country" v-model="new_user.country_code" :items="country_codes"
                            :error-messages="errors" item-value="dial_code">
                            <template slot="item" slot-scope="data">
                              {{ data.item.dial_code }} - {{ data.item.name }}
                            </template>
                            <template slot="selection" slot-scope="data">
                              {{ data.item.dial_code }}
                            </template>

                          </v-select>
                        </ValidationProvider>
                      </v-col>
                      <v-col lg="8" cols="12">
                        <ValidationProvider name="Country" v-slot="{ errors }" persist rules="required|numeric|digits:10">
                          <v-text-field outlined label="Phone Number" v-model="new_user.mobile_number"
                            :error-messages="errors"></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col lg="12" cols="12">
                        <v-row>
                          <v-col cols="2">
                            <v-checkbox label="" hide-details class="pa-0 ma-0" v-model="tnc_accepted"></v-checkbox>
                          </v-col>
                          <v-col cols="9">
                            <p> I agree To Kesari Matrimony <a href="/terms-and-conditions" target="_blank">Terms and
                                  Condition</a>
                                &<span>&nbsp;  <a href="/privacy-policies" target="_blank">Privacy
                                  Policy</a></span></p>
                          </v-col>
                        </v-row>
                      </v-col>

                    </v-row>
                  </v-card-text>
                  <v-card-text>
                    <v-alert type="error" v-if="user_exists_mesg">
                      {{ user_exists_mesg }}
                    </v-alert>
                  </v-card-text>
                  <v-card-actions class="text-center">
                    <v-btn class="pa-4 pl-10 pr-10 ma-auto" color="success" :disabled="!tnc_accepted" rounded
                      :loading="loaders.send_otp_loader" type="submit">
                      Continue
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </ValidationObserver>
            </v-window-item>
            <v-window-item :value="4">
              <ValidationObserver v-if="models.user_onboarding === 4">
                <v-card class="pa-10 pt-3">
                  <v-card-title>
                    <v-icon @click="models.user_onboarding = 3" small class="">mdi-arrow-left</v-icon>
                    <v-img :src="user_otp" class="d-flex justify-center" height="60" contain width="60"></v-img>
                    <v-icon @click="models.sign_up = false" small class="">mdi-close</v-icon>
                  </v-card-title>
                  <v-card-text class="text-center">
                    <span class="caption ma-auto">Please enter OTP received on Phone no. via Call or SMS to complete
                      your profile</span>
                  </v-card-text>
                  <v-card-text class="mt-5">
                    <v-row>
                      <v-col lg="12" cols="12">
                        <ValidationProvider name="OTP" rules="required|numeric|digits:6" v-slot="{ errors }">
                          <v-otp-input length="6" v-model="new_user.otp" label="OTP"
                            :error-messages="errors"></v-otp-input>
                        </ValidationProvider>
                      </v-col>
                      <v-col>
                        <span class="text-center"></span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-text>
                    <v-alert type="error" v-if="sign_up_otp_error_msg">
                      {{ sign_up_otp_error_msg }}
                    </v-alert>
                  </v-card-text>
                  <v-card-actions class="text-center">
                    <v-btn class="pa-4 pl-10 pr-10 ma-auto" color="success" rounded type="submit">
                      Submit
                    </v-btn>
                  </v-card-actions>
                  <!-- snackbar-->
                  <v-alert dismissible :value="models.otp_snackbar" color="green accent-4" elevation="2" outlined
                    class="mt-10">
                    OTP Send Sucessfully
                  </v-alert>
                </v-card>
              </ValidationObserver>
            </v-window-item>

          </v-form>
        </ValidationObserver>
      </v-window>
    </v-dialog>

    <!-- user sign in form -->
    <v-dialog v-model="models.sign_in" max-width="400">
      <v-window v-model="models.user_sing_in">
        <v-form @submit.prevent="login">
          <v-window-item :value="1">
            <v-card>
              <ValidationObserver ref="verifyuserform" v-slot="{ handleSubmit }">
                <v-form @submit.prevent="handleSubmit(verifyUser)">
                  <v-card-title>
                    <v-img :src="user_otp" class="d-flex justify-center mt-3" height="60" contain width="60"></v-img>
                    <v-icon @click="models.sign_in = false" small class="">mdi-close</v-icon>
                  </v-card-title>
                  <v-card-text class="text-center">
                    <span class="caption ma-auto">Please enter registered Mobile Number without country code<br>to
                      Login</span>
                  </v-card-text>
                  <v-card-text>
                    <v-col lg="12">
                      <ValidationProvider name="Mobile Number" rules="required|numeric" v-slot="{ errors }">
                        <v-text-field outlined label="Mobile Number" v-model="user_auth.username"
                          :error-messages="errors">
                        </v-text-field>
                      </ValidationProvider>

                    </v-col>
                  </v-card-text>
                  <v-card-text>
                    <v-alert type="error" v-if="otp_response">
                      {{ otp_response }}
                    </v-alert>
                  </v-card-text>
                  <v-card-actions class="">
                    <v-btn class="pa-4 ma-auto mb-0" rounded color="primary" type="submit"
                      :loading="loaders.verify_user_loader">
                      Send OTP
                    </v-btn>
                  </v-card-actions>
                  <v-card-actions class="mt-0 pt-0">
                    <v-btn class="pa-4 ma-auto mb-5 mt-0  text-capitalize text-caption" plain text color="primary" link
                      @click="models.sign_in = false; models.sign_up = true; ">
                      Sign Up Instead ?
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </ValidationObserver>
            </v-card>
          </v-window-item>
          <v-window-item :value="2">
            <v-card>
              <ValidationObserver ref="submitsignotp" v-slot="{ handleSubmit }">
                <v-form @submit.prevent="handleSubmit(login)"></v-form>
                <v-card-title>
                  <v-icon @click="models.user_sing_in = 1" small class="">mdi-arrow-left</v-icon>
                  <v-img :src="user_password" class="d-flex justify-center" height="60" contain width="60"></v-img>
                  <v-icon @click="models.sign_in = false" small class="">mdi-close</v-icon>
                </v-card-title>
                <v-card-text class="text-center">
                  <span class="caption ma-auto">Please enter OTP received on registered Phone no. via Call or SMS to Login</span>
                </v-card-text>
                <v-card-text>
                  <v-col lg="12">
                    <v-col lg="12">
                      <ValidationProvider name="OTP" rules="required|numeric" v-slot="{ errors }">
                        <v-otp-input length="6" v-model="user_auth.otp" label="OTP"
                          :error-messages="errors"></v-otp-input>
                      </ValidationProvider>
                    </v-col>
                  </v-col>
                </v-card-text>
                <v-card-text>
                  <v-alert type="error" v-if="otp_error_msg">
                    {{ otp_error_msg }}
                  </v-alert>
                </v-card-text>
                <v-card-actions class="">
                  <v-btn class="pa-4 ma-auto mb-5" rounded color="primary" type="submit">
                    Login
                  </v-btn>
                </v-card-actions>
              </ValidationObserver>
            </v-card>
          </v-window-item>
        </v-form>
      </v-window>
    </v-dialog>

  </v-app>
</template>

<script>
// import FooterSection from './components/Global/FooterSection.vue';

import CustomAppbar from './components/Global/CustomAppbar.vue';
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
export default {
  components: { CustomAppbar, ValidationObserver, ValidationProvider },
  mounted() {
    this.getMembershipStatus();
  },
  watch: {
    'new_user.created_for'(newValue) {
      if (this.profile_creators.includes(newValue)) {
        this.new_user.user_gender = "Male";
      }
      if (this.profile_creators_female.includes(newValue)) {
        this.new_user.user_gender = "Female";
      }
    },
    '$route.query.action'(nv) {
      if (nv == "signIn") {

        this.models.sign_in = true;
      }
    }

  },
  computed: {
    getMinDate() {

      return moment().subtract(60, 'years').toISOString();
    },
    getMaxDate() {
      return moment().subtract(18, 'years').toISOString();

    },
    ...mapGetters({
      userAction: 'auth/userAction'
    })
  },
  created() {
    this.getAllListData();
  },
  methods: {

    // sign in methods
    signInUser() {
      this.models.sign_in = true;
    },
    ...mapActions('auth', [
      'login',
      "logout"]
    ),
    verifyUser: function () {
      this.otp_response = '';
      this.loaders.verify_user_loader = true
      axios.post(`${process.env.VUE_APP_API_URL}/verifyuser`, this.user_auth).then((response) => {
        if (response.status == 200) {
          this.models.user_sing_in = 2;

        }
      }).catch((error) => {
        if (error.response) {
          if (error.response.status == 422) {
            this.otp_response = error.response.data.message;
            this.loaders.verify_user_loader = false;
          }

        }
      }).finally(() => {
        this.loaders.verify_user_loader = false;
      });
    },
    async login() {
      await axios.get(`${process.env.VUE_APP_URL}/sanctum/csrf-cookie`);
      await axios.post(`${process.env.VUE_APP_API_URL}/login`, this.user_auth).then((response) => {
        if (response.status == 200) {
          this.models.sign_in = false;
          this.models.user_sing_in = 1;
          this.user_auth.otp = "";
          this.user_auth.username = "";
          this.loaders.verify_user_loader = false;
          this.$store.dispatch('auth/login', response.data);
          
          if (this.$store.getters.auth.isLoggedIn == "true") {
            this.$router.push({ name: "MyProfile" })
          }
        }
      }).catch((error) => {
        if (error.response) {
          if (error.response.status == 422) {
            this.otp_error_msg = error.response.data.message;
          }
          if (error.response.status == 500) {
            this.models.sign_in = false;
          }
          this.signOut();
        }
      }).finally(() => {

      })
    },
    validateProfileContinuation() {

      if (this.new_user.created_for) {
        if (this.new_user.created_for != 'My Self') {
          this.disabled_continue_button = false;
        } else if (this.new_user.created_for == 'My Self') {
          if (this.new_user.user_gender) {
            if (this.new_user.user_gender.length > 0) {
              this.disabled_continue_button = false;
            } else {
              this.disabled_continue_button = true;
            }
          } else {
            this.disabled_continue_button = true;

          }
        }
      } else {
        this.disabled_continue_button = true;

      }
    },
    getAllListData() {

      axios.get(`${process.env.VUE_APP_API_URL}/getalllistdata`).then((response) => {
        if (response.status == 200) {
          this.religions = response.data.religions;

        }
      }).catch((error) => {
      }).finally(() => {

      });
    },
    signUpUser() {

      if (this.models.user_onboarding == 3) {
        axios.post(`${process.env.VUE_APP_API_URL}/sendsignupotp`, this.new_user).then((response) => {
          if (response.status == 200) {
            this.models.user_onboarding = 4;
          }
        }).catch((error) => {
          if (error.response) {
            if (error.response.status == 422) {
              this.user_exists_mesg = error.response.data.message;
            }
          }
        }).finally(() => {

        });
      } else if (this.models.user_onboarding == 4) {
        axios.post(`${process.env.VUE_APP_API_URL}/signUpNewUser`, this.new_user).then((response) => {
          if (response.status == 200) {
            this.$store.dispatch('auth/login', response.data);
            this.models.sign_in = false;
            if (this.$store.getters.auth.isLoggedIn == "true") {
              this.models.sign_up = false;
              this.models.sign_in = false;
              this.models.user_onboarding = 1;
              // this.models.sign_in = false;
              this.$router.push({ name: "MyProfile" })
            }
          }
        }).catch((error) => {
          if (error.response) {
            if (error.response.status == 422) {
              this.sign_up_otp_error_msg = error.response.data.message;
            }
            if (error.response.status == 500) {
              this.models.sign_up = false;
              this.models.user_onboarding = 1;
            }
          }
        }).finally(() => {
          this.models.sign_up = false;
          this.models.sign_in = false;
        });
      } else {
        this.models.user_onboarding++;
      }
    },
    async logout() {

      await axios.post(`${process.env.VUE_APP_API_URL}/logOut`).then(({ data }) => {
        this.$store.dispatch('auth/logout');

      })
    },
    getMembershipStatus() {
      if (this.$store.state.auth.isLoggedIn == 'true') {
        axios.get(`${process.env.VUE_APP_API_URL}/getmembershipdetails`).then((response) => {
          if (response.status == 200) {

            this.$store.dispatch('membership/setMembership', response.data.current_subscription);
          }
        }).catch((error) => {

        }).finally(() => {

        });



      }

    }
  },

  data: () => ({
    user_exists_mesg: '',
    sign_up_otp_error_msg: '',
    models: {
      sign_in: false,
      sign_out: false,
      sign_up: false,
      user_onboarding: 1,
      user_sing_in: 1,
      otp_snackbar: false,
      verify_user_loader: false,
      date_picker: false,
      religion: '',
    },
    tnc_accepted: false,
    religions: [],
    otp_response: '',
    otp_error_msg: '',
    user_profile: require('@/assets/images/user_profile.png'),
    user_email: require('@/assets/images/user_email.png'),
    user_otp: require('@/assets/images/user_otp.png'),
    user_name: require('@/assets/images/user_name.png'),
    user_password: require('@/assets/images/user_password.png'),
    disabled_continue_button: true,
    user_auth: {
      username: '',
      otp: ''
    },
    loaders: {
      send_otp_loader: false,
    },
    profile_creators: ["My Son", "My Brother"],
    profile_creators_female: ["My Daughter", "My Sister"],
    profile_creation_for: [
      {
        "id": "Self",
        "text": "My Self"
      },
      {
        "id": "Parent/Guardian",
        "text": "My Son"
      },
      {
        "id": "Parent/Guardian",
        "text": "My Daughter"
      },
      {
        "id": "Sibling",
        "text": "My Brother"
      },
      {
        "id": "Sibling",
        "text": "My Sister"
      },

    ],
    new_user: {
      created_for: "",
      user_gender: "",
      first_name: "",
      last_name: "",
      date_of_birth: "",
      email_address: "",
      mobile_number: "",
      country_code: "",
      otp: '',
    },
    country_codes: [
      // {
      //   "name": "Afghanistan",
      //   "dial_code": "+93",
      //   "code": "AF"
      // },
      // {
      //   "name": "Aland Islands",
      //   "dial_code": "+358",
      //   "code": "AX"
      // },
      // {
      //   "name": "Albania",
      //   "dial_code": "+355",
      //   "code": "AL"
      // },
      // {
      //   "name": "Algeria",
      //   "dial_code": "+213",
      //   "code": "DZ"
      // },
      // {
      //   "name": "AmericanSamoa",
      //   "dial_code": "+1684",
      //   "code": "AS"
      // },
      // {
      //   "name": "Andorra",
      //   "dial_code": "+376",
      //   "code": "AD"
      // },
      // {
      //   "name": "Angola",
      //   "dial_code": "+244",
      //   "code": "AO"
      // },
      // {
      //   "name": "Anguilla",
      //   "dial_code": "+1264",
      //   "code": "AI"
      // },
      // {
      //   "name": "Antarctica",
      //   "dial_code": "+672",
      //   "code": "AQ"
      // },
      // {
      //   "name": "Antigua and Barbuda",
      //   "dial_code": "+1268",
      //   "code": "AG"
      // },
      // {
      //   "name": "Argentina",
      //   "dial_code": "+54",
      //   "code": "AR"
      // },
      // {
      //   "name": "Armenia",
      //   "dial_code": "+374",
      //   "code": "AM"
      // },
      // {
      //   "name": "Aruba",
      //   "dial_code": "+297",
      //   "code": "AW"
      // },
      // {
      //   "name": "Australia",
      //   "dial_code": "+61",
      //   "code": "AU"
      // },
      // {
      //   "name": "Austria",
      //   "dial_code": "+43",
      //   "code": "AT"
      // },
      // {
      //   "name": "Azerbaijan",
      //   "dial_code": "+994",
      //   "code": "AZ"
      // },
      // {
      //   "name": "Bahamas",
      //   "dial_code": "+1242",
      //   "code": "BS"
      // },
      // {
      //   "name": "Bahrain",
      //   "dial_code": "+973",
      //   "code": "BH"
      // },
      // {
      //   "name": "Bangladesh",
      //   "dial_code": "+880",
      //   "code": "BD"
      // },
      // {
      //   "name": "Barbados",
      //   "dial_code": "+1246",
      //   "code": "BB"
      // },
      // {
      //   "name": "Belarus",
      //   "dial_code": "+375",
      //   "code": "BY"
      // },
      // {
      //   "name": "Belgium",
      //   "dial_code": "+32",
      //   "code": "BE"
      // },
      // {
      //   "name": "Belize",
      //   "dial_code": "+501",
      //   "code": "BZ"
      // },
      // {
      //   "name": "Benin",
      //   "dial_code": "+229",
      //   "code": "BJ"
      // },
      // {
      //   "name": "Bermuda",
      //   "dial_code": "+1441",
      //   "code": "BM"
      // },
      // {
      //   "name": "Bhutan",
      //   "dial_code": "+975",
      //   "code": "BT"
      // },
      // {
      //   "name": "Bolivia, Plurinational State of",
      //   "dial_code": "+591",
      //   "code": "BO"
      // },
      // {
      //   "name": "Bosnia and Herzegovina",
      //   "dial_code": "+387",
      //   "code": "BA"
      // },
      // {
      //   "name": "Botswana",
      //   "dial_code": "+267",
      //   "code": "BW"
      // },
      // {
      //   "name": "Brazil",
      //   "dial_code": "+55",
      //   "code": "BR"
      // },
      // {
      //   "name": "British Indian Ocean Territory",
      //   "dial_code": "+246",
      //   "code": "IO"
      // },
      // {
      //   "name": "Brunei Darussalam",
      //   "dial_code": "+673",
      //   "code": "BN"
      // },
      // {
      //   "name": "Bulgaria",
      //   "dial_code": "+359",
      //   "code": "BG"
      // },
      // {
      //   "name": "Burkina Faso",
      //   "dial_code": "+226",
      //   "code": "BF"
      // },
      // {
      //   "name": "Burundi",
      //   "dial_code": "+257",
      //   "code": "BI"
      // },
      // {
      //   "name": "Cambodia",
      //   "dial_code": "+855",
      //   "code": "KH"
      // },
      // {
      //   "name": "Cameroon",
      //   "dial_code": "+237",
      //   "code": "CM"
      // },
      // {
      //   "name": "Canada",
      //   "dial_code": "+1",
      //   "code": "CA"
      // },
      // {
      //   "name": "Cape Verde",
      //   "dial_code": "+238",
      //   "code": "CV"
      // },
      // {
      //   "name": "Cayman Islands",
      //   "dial_code": "+ 345",
      //   "code": "KY"
      // },
      // {
      //   "name": "Central African Republic",
      //   "dial_code": "+236",
      //   "code": "CF"
      // },
      // {
      //   "name": "Chad",
      //   "dial_code": "+235",
      //   "code": "TD"
      // },
      // {
      //   "name": "Chile",
      //   "dial_code": "+56",
      //   "code": "CL"
      // },
      // {
      //   "name": "China",
      //   "dial_code": "+86",
      //   "code": "CN"
      // },
      // {
      //   "name": "Christmas Island",
      //   "dial_code": "+61",
      //   "code": "CX"
      // },
      // {
      //   "name": "Cocos (Keeling) Islands",
      //   "dial_code": "+61",
      //   "code": "CC"
      // },
      // {
      //   "name": "Colombia",
      //   "dial_code": "+57",
      //   "code": "CO"
      // },
      // {
      //   "name": "Comoros",
      //   "dial_code": "+269",
      //   "code": "KM"
      // },
      // {
      //   "name": "Congo",
      //   "dial_code": "+242",
      //   "code": "CG"
      // },
      // {
      //   "name": "Congo, The Democratic Republic of the Congo",
      //   "dial_code": "+243",
      //   "code": "CD"
      // },
      // {
      //   "name": "Cook Islands",
      //   "dial_code": "+682",
      //   "code": "CK"
      // },
      // {
      //   "name": "Costa Rica",
      //   "dial_code": "+506",
      //   "code": "CR"
      // },
      // {
      //   "name": "Cote d'Ivoire",
      //   "dial_code": "+225",
      //   "code": "CI"
      // },
      // {
      //   "name": "Croatia",
      //   "dial_code": "+385",
      //   "code": "HR"
      // },
      // {
      //   "name": "Cuba",
      //   "dial_code": "+53",
      //   "code": "CU"
      // },
      // {
      //   "name": "Cyprus",
      //   "dial_code": "+357",
      //   "code": "CY"
      // },
      // {
      //   "name": "Czech Republic",
      //   "dial_code": "+420",
      //   "code": "CZ"
      // },
      // {
      //   "name": "Denmark",
      //   "dial_code": "+45",
      //   "code": "DK"
      // },
      // {
      //   "name": "Djibouti",
      //   "dial_code": "+253",
      //   "code": "DJ"
      // },
      // {
      //   "name": "Dominica",
      //   "dial_code": "+1767",
      //   "code": "DM"
      // },
      // {
      //   "name": "Dominican Republic",
      //   "dial_code": "+1849",
      //   "code": "DO"
      // },
      // {
      //   "name": "Ecuador",
      //   "dial_code": "+593",
      //   "code": "EC"
      // },
      // {
      //   "name": "Egypt",
      //   "dial_code": "+20",
      //   "code": "EG"
      // },
      // {
      //   "name": "El Salvador",
      //   "dial_code": "+503",
      //   "code": "SV"
      // },
      // {
      //   "name": "Equatorial Guinea",
      //   "dial_code": "+240",
      //   "code": "GQ"
      // },
      // {
      //   "name": "Eritrea",
      //   "dial_code": "+291",
      //   "code": "ER"
      // },
      // {
      //   "name": "Estonia",
      //   "dial_code": "+372",
      //   "code": "EE"
      // },
      // {
      //   "name": "Ethiopia",
      //   "dial_code": "+251",
      //   "code": "ET"
      // },
      // {
      //   "name": "Falkland Islands (Malvinas)",
      //   "dial_code": "+500",
      //   "code": "FK"
      // },
      // {
      //   "name": "Faroe Islands",
      //   "dial_code": "+298",
      //   "code": "FO"
      // },
      // {
      //   "name": "Fiji",
      //   "dial_code": "+679",
      //   "code": "FJ"
      // },
      // {
      //   "name": "Finland",
      //   "dial_code": "+358",
      //   "code": "FI"
      // },
      // {
      //   "name": "France",
      //   "dial_code": "+33",
      //   "code": "FR"
      // },
      // {
      //   "name": "French Guiana",
      //   "dial_code": "+594",
      //   "code": "GF"
      // },
      // {
      //   "name": "French Polynesia",
      //   "dial_code": "+689",
      //   "code": "PF"
      // },
      // {
      //   "name": "Gabon",
      //   "dial_code": "+241",
      //   "code": "GA"
      // },
      // {
      //   "name": "Gambia",
      //   "dial_code": "+220",
      //   "code": "GM"
      // },
      // {
      //   "name": "Georgia",
      //   "dial_code": "+995",
      //   "code": "GE"
      // },
      // {
      //   "name": "Germany",
      //   "dial_code": "+49",
      //   "code": "DE"
      // },
      // {
      //   "name": "Ghana",
      //   "dial_code": "+233",
      //   "code": "GH"
      // },
      // {
      //   "name": "Gibraltar",
      //   "dial_code": "+350",
      //   "code": "GI"
      // },
      // {
      //   "name": "Greece",
      //   "dial_code": "+30",
      //   "code": "GR"
      // },
      // {
      //   "name": "Greenland",
      //   "dial_code": "+299",
      //   "code": "GL"
      // },
      // {
      //   "name": "Grenada",
      //   "dial_code": "+1473",
      //   "code": "GD"
      // },
      // {
      //   "name": "Guadeloupe",
      //   "dial_code": "+590",
      //   "code": "GP"
      // },
      // {
      //   "name": "Guam",
      //   "dial_code": "+1671",
      //   "code": "GU"
      // },
      // {
      //   "name": "Guatemala",
      //   "dial_code": "+502",
      //   "code": "GT"
      // },
      // {
      //   "name": "Guernsey",
      //   "dial_code": "+44",
      //   "code": "GG"
      // },
      // {
      //   "name": "Guinea",
      //   "dial_code": "+224",
      //   "code": "GN"
      // },
      // {
      //   "name": "Guinea-Bissau",
      //   "dial_code": "+245",
      //   "code": "GW"
      // },
      // {
      //   "name": "Guyana",
      //   "dial_code": "+595",
      //   "code": "GY"
      // },
      // {
      //   "name": "Haiti",
      //   "dial_code": "+509",
      //   "code": "HT"
      // },
      // {
      //   "name": "Holy See (Vatican City State)",
      //   "dial_code": "+379",
      //   "code": "VA"
      // },
      // {
      //   "name": "Honduras",
      //   "dial_code": "+504",
      //   "code": "HN"
      // },
      // {
      //   "name": "Hong Kong",
      //   "dial_code": "+852",
      //   "code": "HK"
      // },
      // {
      //   "name": "Hungary",
      //   "dial_code": "+36",
      //   "code": "HU"
      // },
      // {
      //   "name": "Iceland",
      //   "dial_code": "+354",
      //   "code": "IS"
      // },
      {
        "name": "India",
        "dial_code": "+91",
        "code": "IN"
      },
      // {
      //   "name": "Indonesia",
      //   "dial_code": "+62",
      //   "code": "ID"
      // },
      // {
      //   "name": "Iran, Islamic Republic of Persian Gulf",
      //   "dial_code": "+98",
      //   "code": "IR"
      // },
      // {
      //   "name": "Iraq",
      //   "dial_code": "+964",
      //   "code": "IQ"
      // },
      // {
      //   "name": "Ireland",
      //   "dial_code": "+353",
      //   "code": "IE"
      // },
      // {
      //   "name": "Isle of Man",
      //   "dial_code": "+44",
      //   "code": "IM"
      // },
      // {
      //   "name": "Israel",
      //   "dial_code": "+972",
      //   "code": "IL"
      // },
      // {
      //   "name": "Italy",
      //   "dial_code": "+39",
      //   "code": "IT"
      // },
      // {
      //   "name": "Jamaica",
      //   "dial_code": "+1876",
      //   "code": "JM"
      // },
      // {
      //   "name": "Japan",
      //   "dial_code": "+81",
      //   "code": "JP"
      // },
      // {
      //   "name": "Jersey",
      //   "dial_code": "+44",
      //   "code": "JE"
      // },
      // {
      //   "name": "Jordan",
      //   "dial_code": "+962",
      //   "code": "JO"
      // },
      // {
      //   "name": "Kazakhstan",
      //   "dial_code": "+77",
      //   "code": "KZ"
      // },
      // {
      //   "name": "Kenya",
      //   "dial_code": "+254",
      //   "code": "KE"
      // },
      // {
      //   "name": "Kiribati",
      //   "dial_code": "+686",
      //   "code": "KI"
      // },
      // {
      //   "name": "Korea, Democratic People's Republic of Korea",
      //   "dial_code": "+850",
      //   "code": "KP"
      // },
      // {
      //   "name": "Korea, Republic of South Korea",
      //   "dial_code": "+82",
      //   "code": "KR"
      // },
      // {
      //   "name": "Kuwait",
      //   "dial_code": "+965",
      //   "code": "KW"
      // },
      // {
      //   "name": "Kyrgyzstan",
      //   "dial_code": "+996",
      //   "code": "KG"
      // },
      // {
      //   "name": "Laos",
      //   "dial_code": "+856",
      //   "code": "LA"
      // },
      // {
      //   "name": "Latvia",
      //   "dial_code": "+371",
      //   "code": "LV"
      // },
      // {
      //   "name": "Lebanon",
      //   "dial_code": "+961",
      //   "code": "LB"
      // },
      // {
      //   "name": "Lesotho",
      //   "dial_code": "+266",
      //   "code": "LS"
      // },
      // {
      //   "name": "Liberia",
      //   "dial_code": "+231",
      //   "code": "LR"
      // },
      // {
      //   "name": "Libyan Arab Jamahiriya",
      //   "dial_code": "+218",
      //   "code": "LY"
      // },
      // {
      //   "name": "Liechtenstein",
      //   "dial_code": "+423",
      //   "code": "LI"
      // },
      // {
      //   "name": "Lithuania",
      //   "dial_code": "+370",
      //   "code": "LT"
      // },
      // {
      //   "name": "Luxembourg",
      //   "dial_code": "+352",
      //   "code": "LU"
      // },
      // {
      //   "name": "Macao",
      //   "dial_code": "+853",
      //   "code": "MO"
      // },
      // {
      //   "name": "Macedonia",
      //   "dial_code": "+389",
      //   "code": "MK"
      // },
      // {
      //   "name": "Madagascar",
      //   "dial_code": "+261",
      //   "code": "MG"
      // },
      // {
      //   "name": "Malawi",
      //   "dial_code": "+265",
      //   "code": "MW"
      // },
      // {
      //   "name": "Malaysia",
      //   "dial_code": "+60",
      //   "code": "MY"
      // },
      // {
      //   "name": "Maldives",
      //   "dial_code": "+960",
      //   "code": "MV"
      // },
      // {
      //   "name": "Mali",
      //   "dial_code": "+223",
      //   "code": "ML"
      // },
      // {
      //   "name": "Malta",
      //   "dial_code": "+356",
      //   "code": "MT"
      // },
      // {
      //   "name": "Marshall Islands",
      //   "dial_code": "+692",
      //   "code": "MH"
      // },
      // {
      //   "name": "Martinique",
      //   "dial_code": "+596",
      //   "code": "MQ"
      // },
      // {
      //   "name": "Mauritania",
      //   "dial_code": "+222",
      //   "code": "MR"
      // },
      // {
      //   "name": "Mauritius",
      //   "dial_code": "+230",
      //   "code": "MU"
      // },
      // {
      //   "name": "Mayotte",
      //   "dial_code": "+262",
      //   "code": "YT"
      // },
      // {
      //   "name": "Mexico",
      //   "dial_code": "+52",
      //   "code": "MX"
      // },
      // {
      //   "name": "Micronesia, Federated States of Micronesia",
      //   "dial_code": "+691",
      //   "code": "FM"
      // },
      // {
      //   "name": "Moldova",
      //   "dial_code": "+373",
      //   "code": "MD"
      // },
      // {
      //   "name": "Monaco",
      //   "dial_code": "+377",
      //   "code": "MC"
      // },
      // {
      //   "name": "Mongolia",
      //   "dial_code": "+976",
      //   "code": "MN"
      // },
      // {
      //   "name": "Montenegro",
      //   "dial_code": "+382",
      //   "code": "ME"
      // },
      // {
      //   "name": "Montserrat",
      //   "dial_code": "+1664",
      //   "code": "MS"
      // },
      // {
      //   "name": "Morocco",
      //   "dial_code": "+212",
      //   "code": "MA"
      // },
      // {
      //   "name": "Mozambique",
      //   "dial_code": "+258",
      //   "code": "MZ"
      // },
      // {
      //   "name": "Myanmar",
      //   "dial_code": "+95",
      //   "code": "MM"
      // },
      // {
      //   "name": "Namibia",
      //   "dial_code": "+264",
      //   "code": "NA"
      // },
      // {
      //   "name": "Nauru",
      //   "dial_code": "+674",
      //   "code": "NR"
      // },
      // {
      //   "name": "Nepal",
      //   "dial_code": "+977",
      //   "code": "NP"
      // },
      // {
      //   "name": "Netherlands",
      //   "dial_code": "+31",
      //   "code": "NL"
      // },
      // {
      //   "name": "Netherlands Antilles",
      //   "dial_code": "+599",
      //   "code": "AN"
      // },
      // {
      //   "name": "New Caledonia",
      //   "dial_code": "+687",
      //   "code": "NC"
      // },
      // {
      //   "name": "New Zealand",
      //   "dial_code": "+64",
      //   "code": "NZ"
      // },
      // {
      //   "name": "Nicaragua",
      //   "dial_code": "+505",
      //   "code": "NI"
      // },
      // {
      //   "name": "Niger",
      //   "dial_code": "+227",
      //   "code": "NE"
      // },
      // {
      //   "name": "Nigeria",
      //   "dial_code": "+234",
      //   "code": "NG"
      // },
      // {
      //   "name": "Niue",
      //   "dial_code": "+683",
      //   "code": "NU"
      // },
      // {
      //   "name": "Norfolk Island",
      //   "dial_code": "+672",
      //   "code": "NF"
      // },
      // {
      //   "name": "Northern Mariana Islands",
      //   "dial_code": "+1670",
      //   "code": "MP"
      // },
      // {
      //   "name": "Norway",
      //   "dial_code": "+47",
      //   "code": "NO"
      // },
      // {
      //   "name": "Oman",
      //   "dial_code": "+968",
      //   "code": "OM"
      // },
      // {
      //   "name": "Pakistan",
      //   "dial_code": "+92",
      //   "code": "PK"
      // },
      // {
      //   "name": "Palau",
      //   "dial_code": "+680",
      //   "code": "PW"
      // },
      // {
      //   "name": "Palestinian Territory, Occupied",
      //   "dial_code": "+970",
      //   "code": "PS"
      // },
      // {
      //   "name": "Panama",
      //   "dial_code": "+507",
      //   "code": "PA"
      // },
      // {
      //   "name": "Papua New Guinea",
      //   "dial_code": "+675",
      //   "code": "PG"
      // },
      // {
      //   "name": "Paraguay",
      //   "dial_code": "+595",
      //   "code": "PY"
      // },
      // {
      //   "name": "Peru",
      //   "dial_code": "+51",
      //   "code": "PE"
      // },
      // {
      //   "name": "Philippines",
      //   "dial_code": "+63",
      //   "code": "PH"
      // },
      // {
      //   "name": "Pitcairn",
      //   "dial_code": "+872",
      //   "code": "PN"
      // },
      // {
      //   "name": "Poland",
      //   "dial_code": "+48",
      //   "code": "PL"
      // },
      // {
      //   "name": "Portugal",
      //   "dial_code": "+351",
      //   "code": "PT"
      // },
      // {
      //   "name": "Puerto Rico",
      //   "dial_code": "+1939",
      //   "code": "PR"
      // },
      // {
      //   "name": "Qatar",
      //   "dial_code": "+974",
      //   "code": "QA"
      // },
      // {
      //   "name": "Romania",
      //   "dial_code": "+40",
      //   "code": "RO"
      // },
      // {
      //   "name": "Russia",
      //   "dial_code": "+7",
      //   "code": "RU"
      // },
      // {
      //   "name": "Rwanda",
      //   "dial_code": "+250",
      //   "code": "RW"
      // },
      // {
      //   "name": "Reunion",
      //   "dial_code": "+262",
      //   "code": "RE"
      // },
      // {
      //   "name": "Saint Barthelemy",
      //   "dial_code": "+590",
      //   "code": "BL"
      // },
      // {
      //   "name": "Saint Helena, Ascension and Tristan Da Cunha",
      //   "dial_code": "+290",
      //   "code": "SH"
      // },
      // {
      //   "name": "Saint Kitts and Nevis",
      //   "dial_code": "+1869",
      //   "code": "KN"
      // },
      // {
      //   "name": "Saint Lucia",
      //   "dial_code": "+1758",
      //   "code": "LC"
      // },
      // {
      //   "name": "Saint Martin",
      //   "dial_code": "+590",
      //   "code": "MF"
      // },
      // {
      //   "name": "Saint Pierre and Miquelon",
      //   "dial_code": "+508",
      //   "code": "PM"
      // },
      // {
      //   "name": "Saint Vincent and the Grenadines",
      //   "dial_code": "+1784",
      //   "code": "VC"
      // },
      // {
      //   "name": "Samoa",
      //   "dial_code": "+685",
      //   "code": "WS"
      // },
      // {
      //   "name": "San Marino",
      //   "dial_code": "+378",
      //   "code": "SM"
      // },
      // {
      //   "name": "Sao Tome and Principe",
      //   "dial_code": "+239",
      //   "code": "ST"
      // },
      // {
      //   "name": "Saudi Arabia",
      //   "dial_code": "+966",
      //   "code": "SA"
      // },
      // {
      //   "name": "Senegal",
      //   "dial_code": "+221",
      //   "code": "SN"
      // },
      // {
      //   "name": "Serbia",
      //   "dial_code": "+381",
      //   "code": "RS"
      // },
      // {
      //   "name": "Seychelles",
      //   "dial_code": "+248",
      //   "code": "SC"
      // },
      // {
      //   "name": "Sierra Leone",
      //   "dial_code": "+232",
      //   "code": "SL"
      // },
      // {
      //   "name": "Singapore",
      //   "dial_code": "+65",
      //   "code": "SG"
      // },
      // {
      //   "name": "Slovakia",
      //   "dial_code": "+421",
      //   "code": "SK"
      // },
      // {
      //   "name": "Slovenia",
      //   "dial_code": "+386",
      //   "code": "SI"
      // },
      // {
      //   "name": "Solomon Islands",
      //   "dial_code": "+677",
      //   "code": "SB"
      // },
      // {
      //   "name": "Somalia",
      //   "dial_code": "+252",
      //   "code": "SO"
      // },
      // {
      //   "name": "South Africa",
      //   "dial_code": "+27",
      //   "code": "ZA"
      // },
      // {
      //   "name": "South Sudan",
      //   "dial_code": "+211",
      //   "code": "SS"
      // },
      // {
      //   "name": "South Georgia and the South Sandwich Islands",
      //   "dial_code": "+500",
      //   "code": "GS"
      // },
      // {
      //   "name": "Spain",
      //   "dial_code": "+34",
      //   "code": "ES"
      // },
      // {
      //   "name": "Sri Lanka",
      //   "dial_code": "+94",
      //   "code": "LK"
      // },
      // {
      //   "name": "Sudan",
      //   "dial_code": "+249",
      //   "code": "SD"
      // },
      // {
      //   "name": "Suriname",
      //   "dial_code": "+597",
      //   "code": "SR"
      // },
      // {
      //   "name": "Svalbard and Jan Mayen",
      //   "dial_code": "+47",
      //   "code": "SJ"
      // },
      // {
      //   "name": "Swaziland",
      //   "dial_code": "+268",
      //   "code": "SZ"
      // },
      // {
      //   "name": "Sweden",
      //   "dial_code": "+46",
      //   "code": "SE"
      // },
      // {
      //   "name": "Switzerland",
      //   "dial_code": "+41",
      //   "code": "CH"
      // },
      // {
      //   "name": "Syrian Arab Republic",
      //   "dial_code": "+963",
      //   "code": "SY"
      // },
      // {
      //   "name": "Taiwan",
      //   "dial_code": "+886",
      //   "code": "TW"
      // },
      // {
      //   "name": "Tajikistan",
      //   "dial_code": "+992",
      //   "code": "TJ"
      // },
      // {
      //   "name": "Tanzania, United Republic of Tanzania",
      //   "dial_code": "+255",
      //   "code": "TZ"
      // },
      // {
      //   "name": "Thailand",
      //   "dial_code": "+66",
      //   "code": "TH"
      // },
      // {
      //   "name": "Timor-Leste",
      //   "dial_code": "+670",
      //   "code": "TL"
      // },
      // {
      //   "name": "Togo",
      //   "dial_code": "+228",
      //   "code": "TG"
      // },
      // {
      //   "name": "Tokelau",
      //   "dial_code": "+690",
      //   "code": "TK"
      // },
      // {
      //   "name": "Tonga",
      //   "dial_code": "+676",
      //   "code": "TO"
      // },
      // {
      //   "name": "Trinidad and Tobago",
      //   "dial_code": "+1868",
      //   "code": "TT"
      // },
      // {
      //   "name": "Tunisia",
      //   "dial_code": "+216",
      //   "code": "TN"
      // },
      // {
      //   "name": "Turkey",
      //   "dial_code": "+90",
      //   "code": "TR"
      // },
      // {
      //   "name": "Turkmenistan",
      //   "dial_code": "+993",
      //   "code": "TM"
      // },
      // {
      //   "name": "Turks and Caicos Islands",
      //   "dial_code": "+1649",
      //   "code": "TC"
      // },
      // {
      //   "name": "Tuvalu",
      //   "dial_code": "+688",
      //   "code": "TV"
      // },
      // {
      //   "name": "Uganda",
      //   "dial_code": "+256",
      //   "code": "UG"
      // },
      // {
      //   "name": "Ukraine",
      //   "dial_code": "+380",
      //   "code": "UA"
      // },
      // {
      //   "name": "United Arab Emirates",
      //   "dial_code": "+971",
      //   "code": "AE"
      // },
      // {
      //   "name": "United Kingdom",
      //   "dial_code": "+44",
      //   "code": "GB"
      // },
      // {
      //   "name": "United States",
      //   "dial_code": "+1",
      //   "code": "US"
      // },
      // {
      //   "name": "Uruguay",
      //   "dial_code": "+598",
      //   "code": "UY"
      // },
      // {
      //   "name": "Uzbekistan",
      //   "dial_code": "+998",
      //   "code": "UZ"
      // },
      // {
      //   "name": "Vanuatu",
      //   "dial_code": "+678",
      //   "code": "VU"
      // },
      // {
      //   "name": "Venezuela, Bolivarian Republic of Venezuela",
      //   "dial_code": "+58",
      //   "code": "VE"
      // },
      // {
      //   "name": "Vietnam",
      //   "dial_code": "+84",
      //   "code": "VN"
      // },
      // {
      //   "name": "Virgin Islands, British",
      //   "dial_code": "+1284",
      //   "code": "VG"
      // },
      // {
      //   "name": "Virgin Islands, U.S.",
      //   "dial_code": "+1340",
      //   "code": "VI"
      // },
      // {
      //   "name": "Wallis and Futuna",
      //   "dial_code": "+681",
      //   "code": "WF"
      // },
      // {
      //   "name": "Yemen",
      //   "dial_code": "+967",
      //   "code": "YE"
      // },
      // {
      //   "name": "Zambia",
      //   "dial_code": "+260",
      //   "code": "ZM"
      // },
      // {
      //   "name": "Zimbabwe",
      //   "dial_code": "+263",
      //   "code": "ZW"
      // }
    ]

  }),

}
</script>
<style></style>