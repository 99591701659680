<template>
    <v-container>
        <v-card outlined flat>
            <v-card-title>
                <h5>Viewed Profiles</h5>
                <v-spacer></v-spacer>
                <v-btn class="orange-gradient" small dense link href="/search/users">Search Users</v-btn>
            </v-card-title>
            <v-divider>
            </v-divider>
            <v-data-table :headers="profile_view_headers" :loading="loaders.list_loader" :items="viewed_profiles">

                <template v-slot:[`item.id`]="{ item }">
                    <router-link :to="{ name:'viewuserprofile' , params:{ profile_id:item.id }}">
                    {{  item.id }}
                    </router-link>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>
<script>
import axios from 'axios';

export default {
    created() {
        this.getProfileViewHistory();
    },
    methods: {
        getProfileViewHistory: function () {
            this.loaders.list_loader = true;
            axios.get(`${process.env.VUE_APP_API_URL}/getviewedprofiles`).then((response) => {
                if (response.status == 200) {
                    this.viewed_profiles = response.data.data;
                }
            }).catch((error) => {

            }).finally(() => {
                this.loaders.list_loader = false;
            })

        }
    },
    data: () => ({
        loaders: {
            list_loader: false,
        },
        viewed_profiles: [],
        profile_view_headers: [
            {
                text: '#Profile ID',
                align: 'start',
                sortable: true,
                value: 'id',
            },

            {
                text: 'Name',
                align: 'start',
                sortable: false,
                value: "name",
            },
            {
                text: 'Date Of Birth',
                align: 'start',
                sortable: false,
                value: 'date_of_birth',
            },


        ]
    })
}

</script>
<style>
.orange-gradient {
    background: linear-gradient(225deg, rgba(253, 44, 121, 1) 0%, rgba(253, 101, 91, 1) 100%) !important;
    color: #fff !important;
}
</style>