<template>
    <div>
        <section class="blog-detail-hero">
            <v-container>
                <v-row>
                    <v-col lg="7" cols="12" md="12" sm="12">
                        <v-row>
                            <v-col cols="12">
                                <div class="blog-heading">
                                    <h2>{{ blog.title }}</h2>
                                    <h6 class="pt-2 text-caption">{{ blog.created_at }}</h6>
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <div class="blog-image ">
                                    <v-img aspect-ratio="3.0" max-width="900" max-height="400"
                                        class="rounded-lg elevation-1"
                                        src="https://i.pinimg.com/736x/23/c1/cc/23c1ccae3150e4b4afb355015f62178a--planner-organisation-couple-pictures.jpg"></v-img>
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <div class="blog-content" v-html="blog.blog">

                                </div>
                            </v-col>
                            <v-col cols="12" class="social-share-button">
                                <p class="text-caption">Share On:</p>
                                <ShareNetwork v-for="network in networks" :network="network.network"
                                    :key="network.network" :style="{ backgroundColor: network.color }" :url="blogURL"
                                    :title="blog.title" :description="blog.description" :quote="blog.quote"
                                    :hashtags="blog.hashtags" :twitterUser="twitterUser"
                                    style="margin: 5px !important;">
                                    <v-btn :color="network.color" small class="pa-2 white--text ma-1 pl-1">
                                        <v-icon small class="text-left pr-2">
                                            {{ network.icon }}
                                        </v-icon>
                                        <span>{{ network.name }}</span>
                                    </v-btn>

                                </ShareNetwork>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col lg="5" cols="12" class="">
                        <div class="blog-heading">
                            <h2>You May Also Like</h2>
                            
                        </div>
                        <v-row class="">
                            <v-col cols="12" lg="12" sm="6" md="4" v-for="item in blogs" :key="item.slug_title">
                                <v-card class="mt-5" max-width="344">
                                    <v-img src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
                                        height="200px"></v-img>
                                    <v-card-title>
                                        {{ item.title }}
                                    </v-card-title>
                                    <v-card-subtitle class="pt-3">
                                        {{ item.quote }}
                                    </v-card-subtitle>
                                    <v-card-subtitle>
                                        <router-link :to="{ name: 'Blog', params: { blog_id: item.slug_title } }">
                                            <v-btn color="primary" small class="text-capitalize pl-0"
                                                text>Read More</v-btn>
                                        </router-link>
                                    </v-card-subtitle>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <FooterSection></FooterSection>
    </div>
</template>
<script>
import FooterSection from '@/components/Global/FooterSection.vue';
import axios from 'axios';

export default {
    computed: {
        blogURL() {
            return this.url + this.$route.params.blog_id;
        }
    },
    watch:{
        '$route.params.blog_id':{
            handler:function(newtitle){
                this.getBlogDetails();
            },
            deep:true,
            immediate:true,
        }
    },
    mounted() {
        this.getBlogDetails();
    },
    methods: {
        getBlogDetails: function () {
            axios.get(`${process.env.VUE_APP_API_URL}/getblogdetails/`+this.$route.params.blog_id).then((response) => {
                if (response.status == 200) {
                    this.blog = response.data.blog;
                    this.blogs = response.data.blogs;

                }
            }).catch((error) => {

            }).finally(() => {

            });
        }
    },
    data: () => ({
        blog: {
            title: '',
            slug_title: '',
            blog: '',
            quote: '',
            banner_image: '',

        },
        twitterUser: "kesarimatrimony",
        url: 'https://kesarimatrimony.com/blog/',
        blogs: [],
        networks: [
            { network: "email", name: "Email", icon: "mdi-email-fast", color: "#333333" },
            { network: "evernote", name: "Evernote", icon: "mdi-evernote", color: "#2dbe60" },
            { network: "facebook", name: "Facebook", icon: "mdi-facebook", color: "#1877f2" },
            { network: "linkedin", name: "LinkedIn", icon: "mdi-linkedin", color: "#007bb5" },
            { network: "messenger", name: "Messenger", icon: "mdi-facebook-messenger", color: "#0084ff" },
            { network: "pinterest", name: "Pinterest", icon: "mdi-pinterest", color: "#bd081c" },
            { network: "telegram", name: "Telegram", icon: "mdi-navigation-variant-outline", color: "#0088cc" },
            { network: "twitter", name: "Twitter", icon: "mdi-twitter", color: "#1da1f2" },
            { network: "whatsapp", name: "Whatsapp", icon: "mdi-whatsapp", color: "#25d366" },
            { network: "wordpress", name: "Wordpress", icon: "mdi-wordpress", color: "#21759b" },
        ]
    }),
    components: { FooterSection }
}
</script>
<style>
.social-share-button a {
    background-color: #fff !important;
}
</style>