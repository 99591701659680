<template>
    <v-card class="mb-4 rounded-lg profile_card " hover>
        <v-row align-content="start" class="pa-0 ma-0">
            <v-col lg="3" cols="12" sm="12" md="12" class="pa-0 ma-0">
                <v-carousel continuous show-arrows hide-delimiter-background height="300" hide-delimiters cycle
                    v-if=" profile_images.length > 0" interval="2000" show-arrows-on-hover class="pa-0 ma-0 ">
                    <v-carousel-item class="" v-for="profile_image in profile_images" :key="profile_image.uri">
                        <v-img class="" height="300" width="" aspect-ratio="1.7778" :src="profile_image.uri">
                            <v-btn elevation="5" tile class="white--text mt-3 text-capitalize font-weight-bold" dense
                                x-small color="pink">
                                <v-icon left>
                                    mdi-crown-outline
                                </v-icon>
                                Premium
                            </v-btn>

                        </v-img>
                    </v-carousel-item>
                </v-carousel>
                <v-img class="" height="300" width="" aspect-ratio="1.7778" v-else :src="user_no_image">
                    <v-btn elevation="5" tile class="white--text mt-3 text-capitalize font-weight-bold" dense x-small
                        color="pink">
                        <v-icon left>
                            mdi-crown-outline
                        </v-icon>
                        Premium
                    </v-btn>

                </v-img>
            </v-col>
            <v-divider vertical class="pt-3"></v-divider>
            <v-col lg="9" md="12" sm="12" cols=12 class="">
                <v-row>
                    <v-col lg="12" cols="12" class="">
                        <v-card-text class="pt-4 mb-0">
                            <v-row>
                                <v-col lg="6" md="9" cols="12" sm="9">
                                    <h5 class="member_name">{{ user.name }}</h5>
                                    <span class="member_id">Member ID : {{ user.profile_id }}</span>
                                    <span class="member_id"> | Profile Created For : {{
                                        user.profile_created_by
                                    }}</span>
                                </v-col>
                                <v-col lg="6" md="3" cols="12" sm="3" class="text-lg-right text-sm-center"
                                    v-if="user.mobile_verified">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn class="ma-auto" color="green" elevation="0" icon v-bind="attrs"
                                                v-on="on">
                                                <v-icon>mdi-shield-check</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Mobile Verified</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider class="pt-1"></v-divider>
                    </v-col>
                    <v-col lg="12" md="12" sm="12" cols="12" class="pa-0 ma-0">
                        <v-row>
                            <v-col lg="6" md="6" sm="12" cols="12" class="pl-md-8">
                                <v-list-item dense class="pr-3">
                                    <v-list-item-subtitle>
                                        <v-row>
                                            <v-col lg="5" md="5" sm="5" cols="5" class="text-body-2">
                                                Age
                                            </v-col>
                                            <v-col lg="1" md="1" sm="1" cols="1" class="text-body-2 pr-0 mr-0">
                                                :
                                            </v-col>
                                            <v-col lg="5" md="5" sm="5" cols="5" class="text-body-2 pl-0 ml-0">
                                                {{ user.age }}
                                            </v-col>
                                        </v-row>
                                    </v-list-item-subtitle>
                                </v-list-item>
                                <v-list-item dense class="pr-3">
                                    <v-list-item-subtitle>
                                        <v-row>
                                            <v-col lg="5" md="5" sm="5" cols="5" class="text-body-2">
                                                Religion
                                            </v-col>
                                            <v-col lg="1" md="1" sm="1" cols="1" class="text-body-2 pr-0 mr-0">
                                                :
                                            </v-col>
                                            <v-col lg="5" md="5" sm="5" cols="5" class="text-body-2 pl-0 ml-0">
                                                {{ user.religion }}
                                            </v-col>
                                        </v-row>
                                    </v-list-item-subtitle>
                                </v-list-item>
                                <v-list-item dense class="pr-3">
                                    <v-list-item-subtitle>
                                        <v-row>
                                            <v-col lg="5" md="5" sm="5" cols="5" class="text-body-2">
                                                Mother Tongue
                                            </v-col>
                                            <v-col lg="1" md="1" sm="1" cols="1" class="text-body-2 pr-0 mr-0">
                                                :
                                            </v-col>
                                            <v-col lg="5" md="5" sm="5" cols="5" class="text-body-2 pl-0 ml-0">
                                                {{ user.mother_tongue }}
                                            </v-col>
                                        </v-row>
                                    </v-list-item-subtitle>
                                </v-list-item>
                            </v-col>
                            <v-col lg="6" md="6" sm="12" cols="12" class="pl-md-8">
                                <v-list-item dense class="pr-3">
                                    <v-list-item-subtitle>
                                        <v-row>
                                            <v-col lg="5" md="5" sm="5" cols="5" class="text-body-2">
                                                Height
                                            </v-col>
                                            <v-col lg="1" md="1" sm="1" cols="1" class="text-body-2 pr-0 mr-0">
                                                :
                                            </v-col>
                                            <v-col lg="5" md="5" sm="5" cols="5" class="text-body-2 pl-0 ml-0">
                                                {{ user.height }}
                                            </v-col>
                                        </v-row>
                                    </v-list-item-subtitle>
                                </v-list-item>
                                <v-list-item dense class="pr-3">
                                    <v-list-item-subtitle>
                                        <v-row>
                                            <v-col lg="5" md="5" sm="5" cols="5" class="text-body-2">
                                                Maritial Status
                                            </v-col>
                                            <v-col lg="1" md="1" sm="1" cols="1" class="text-body-2 pr-0 mr-0">
                                                :
                                            </v-col>
                                            <v-col lg="5" md="5" sm="5" cols="5" class="text-body-2 pl-0 ml-0">
                                                {{ user.maritial_status }}
                                            </v-col>
                                        </v-row>
                                    </v-list-item-subtitle>
                                </v-list-item>
                                <v-list-item dense class="pr-3">
                                    <v-list-item-subtitle>
                                        <v-row>
                                            <v-col lg="5" md="5" sm="5" cols="5" class="text-body-2">
                                                Occupation
                                            </v-col>
                                            <v-col lg="1" md="1" sm="1" cols="1" class="text-body-2 pr-0 mr-0">
                                                :
                                            </v-col>
                                            <v-col lg="5" md="5" sm="5" cols="5" class="text-body-2 pl-0 ml-0">
                                                {{ user.occupation }}
                                            </v-col>
                                        </v-row>
                                    </v-list-item-subtitle>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col lg="12" cols="12" md="12" sm="12">
                        <v-divider></v-divider>
                        <v-row class="mt-2" align-content-lg="center" align-content-md="center"
                            align-content-sm="start">
                            <v-btn elevation="0" class="white--text ma-auto text-capitalize" dense text color="orange"
                                @click="viewProfile">
                                <v-icon left>
                                    mdi-account-details-outline
                                </v-icon>
                                View Profile
                            </v-btn>
                            <v-btn elevation="0" class="white--text ma-auto text-capitalize" dense text color="orange"
                                :loading="loaders.shortlist_loader" @click="shortListUser"
                                :disabled="loaders.shortlist_loader">
                                <v-icon left>
                                    mdi-format-list-checks
                                </v-icon>
                                Add To Shortlist
                            </v-btn>

                            <v-dialog v-model="dialogs.express_interest" width="300">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn elevation="0" v-on="on" v-bind="attrs"
                                        class="white--text ma-auto text-capitalize" dense text color="orange"
                                        :loading="loaders.send_interest_loader"
                                        :disabled="loaders.send_interest_loader">
                                        <v-icon left>
                                            mdi-account-heart-outline
                                        </v-icon>
                                        Express Interest
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title class="headline lighten-2" primary-title>
                                        Confirmation
                                    </v-card-title>

                                    <v-card-text>
                                        Are you sure you want to send Interest Request.Once done it cannot be
                                        revert.
                                    </v-card-text>

                                    <v-divider></v-divider>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="red" text @click="sendExpressInterest()"
                                            :loading="loaders.send_interest_loader"
                                            :disabled="loaders.send_interest_loader">
                                            Confirm
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>


                            </v-dialog>
                            <v-btn elevation="0" class="red--text ma-auto text-capitalize" dense text color="red" to="/ContactUs">
                                <v-icon left>
                                    mdi-alert-circle-outline
                                </v-icon>
                                Report User
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-snackbar :timeout="6000" v-model="snackbars.error" top color="red" class="white--text font-weight-bold">
            {{ message }}
        </v-snackbar>
        <v-snackbar :timeout="6000" v-model="snackbars.success" top color="indigo" class="white--text font-weight-bold">
            {{ message }}
        </v-snackbar>
    </v-card>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
export default {
    computed: {
    ...mapGetters({
      premiumUser: 'membership/premiumUser',
    }),

    
  },
    mounted() {
        this.getUserImages();
    },
    props: ['user'],
    methods: {
        getUserImages() {
            axios.get(`${process.env.VUE_APP_API_URL}/getuserimages/`+this.user.profile_id).then((response) => {
                if (response.status == 200) {
                    this.profile_images = response.data.user_images;
                }
            }).catch((error) => {
                console.log(error);
            }).finally(() => {

            })
        },
        viewProfile: function () {
           //if(this.premiumUser){
            this.$router.push({ name: 'viewuserprofile', params: { profile_id: this.user.profile_id } });
           //}else{
            //this.$router.push({ name: 'membershipPricing' });
          // }
        },

        shortListUser: function () {
            this.loaders.shortlist_loader = true;
            let user = {};
            user.profile_id = this.user.profile_id;
            axios.post(`${process.env.VUE_APP_API_URL}/shortlistuser`, user).then((response) => {
                if (response.status == 200) {
                    this.message = "User Added to Shortlist";
                    this.snackbars.success = true;
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status == 422) {
                        this.message = error.response.data.message;
                        this.snackbars.error = true;
                    }
                }
            }).finally(() => {
                this.loaders.shortlist_loader = false;
            });
        },
        sendExpressInterest: function () {
            this.loaders.send_interest_loader = true;
            let user = {};
            user.profile_id = this.user.profile_id;
            axios.post(`${process.env.VUE_APP_API_URL}/sendexpressinterest`, user).then((response) => {
                if (response.status == 200) {
                    this.message = "Interest Send Sucessfully.";
                    this.snackbars.success = true;
                    this.$store.dispatch('membership/updateExpressInterest',this.expressInterest - 1)
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status == 422) {
                        this.message = error.response.data.message;
                        this.snackbars.error = true;
                    }
                }
            }).finally(() => {
                this.loaders.send_interest_loader = false;
                this.dialogs.express_interest = false;
            });
        },
    },
    data: () => ({
        user_no_image: require('@/assets/images/user-no-image.png'),
        profile_images: [],
        dialogs: {
            express_interest: false,
        },
        dialog: false,
        loaders: {
            shortlist_loader: false,
            send_interest_loader: false,
            view_contact_loader: false,

        },
        snackbars: {
            error: false,
            success: false,
        },
        message: '',
    })

}
</script>
<style scoped>
.profile_card_highlighter {
    border-color: chocolate !important;
    border-width: 1px !important;
    border-style: solid !important;
}

.profile_card {
    font-family: 'Nunito' !important;
    font-weight: 700 !important;
    font-size: 0.9rem !important;

}

.profile_card .member_name {
    font-size: 1.2rem !important;
}

.profile_card .member_id {
    font-size: 0.7rem !important;
    font-weight: 800 !important;
    letter-spacing: 0.5px !important;
    color: crimson;
}
</style>