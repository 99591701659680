<template>
    <section class="trust-section">
        <v-container class="text-center">
                <v-row>
                    <v-col class="">
                        <h2 class="">1000+</h2>
                        <h3>Daily New Members</h3>
                    </v-col>
                    <v-col class="">
                        <h2 class="">500000+</h2>
                        <h3>Registerd Members</h3>
                    </v-col>
                    <v-col class="">
                        <h2 class="">53+</h2>
                        <h3>Cities Covered</h3>
                    </v-col>
                    
                </v-row>
        </v-container>
    </section>
</template>

<script>
export default {
    mounted() {
        console.log("Trust Badge Landed")
    },
    data: () => ({
       
    }),
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Playfair+Display:wght@400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&family=Great+Vibes&family=Playfair+Display:ital,wght@0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
.trust-section{
height: 15vh !important;
background-color: #fdf5e8 !important;
color: #6f2836 !important;
}
.badge-icon{
    color: #6f2836 !important;
    font-size: 2.5rem !important;

}
.trust-section h2{

    color: #6f2836 !important;
    font-size: 3.5rem !important;
    font-family: 'Great Vibes', cursive !important;   
    font-weight: 700 !important;
    font-style: normal;
    padding-top: 5% !important;
}
.trust-section h3{
    color: #6f2836 !important;
    font-size: 1.5rem !important;
    font-family: 'Poppins', cursive !important;
    font-weight: 700 !important;
    font-style: normal;
}
</style>