<template>
    <div>
        <section class="account-session-container">
            <v-container class="mt-lg-14 ">
                <v-row class="justify-lg-start">
                    <v-col lg="3" cols="12" md="12" sm="12" class="d-none d-lg-block">
                        <v-card width="256" class="elevation-2">
                            <!-- <v-card-title class="">
                            <v-img src="https://randomuser.me/api/portraits/men/45.jpg" max-width="60" contain
                                class="ma-auto  rounded-circle">
                            </v-img>
                        </v-card-title> -->
                            <v-card-text class="text-center">
                                <h3 class="text-heading red--text">{{ authUser.first_name + " " + authUser.last_name }}</h3>

                                <h3 class="text-caption red--text">{{ authUser.profile_id }}</h3>
                                <v-divider class="red ma-3"></v-divider>
                                <v-btn small link class="mt-3 text-capitalize" outlined block color="red">
                                    <span v-if="premiumUser && planPrice > 0">
                                        Premium User
                                    </span>
                                    <span v-else>
                                        Free User
                                    </span>
                                </v-btn>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-navigation-drawer floating permanent class="c-nav-items-session">
                                <v-list class=" pt-5 ml-2">
                                    <v-list-item v-for="item in items" :key="item.title" link
                                        @click="$router.push({ name: item.route_name }).catch(() => { })">
                                        <v-list-item-icon class="pr-0 mr-0">
                                            <v-icon small color="#6f2863">{{ item.icon }}</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="c-nav-item">{{ item.title }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-navigation-drawer>
                            <!-- <v-card-actions class="mt-14">
                            <v-btn block small dense color="#6f2863" class="text-capitalize white--text">
                                Log Out
                            </v-btn>
                        </v-card-actions> -->
                        </v-card>
                    </v-col>
                    <v-col lg="9" xl="8" sm="12" md="12" cols="12" class="session-account-view">
                        <v-col xl="12" lg="12" sm="12" cols="12" v-if="authUser.is_suspended" class="">
                            <v-alert type="error" class="red darken-3    elevation-14 font-weight-medium">{{ "User Has Been Suspend For : " + authUser.suspend_reason
                            }}<br><br>
                         Please Note Your profile will not be visible to anyone until suspension is resolved.</v-alert>
                        </v-col>
                        <router-view></router-view>
                    </v-col>
                </v-row>

            </v-container>

        </section>
        <FooterSection></FooterSection>
    </div>
</template>
<script>
import FooterSection from '@/components/Global/FooterSection.vue';
import { RouterView } from 'vue-router';
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            user: 'auth/user',
            premiumUser: 'membership/premiumUser',
            planPrice:'membership/price',
        }),
        authUser() {
            if (this.$store.getters['auth/isLoggedIn']) {
                return this.$store.getters['auth/user'];
            }
            else {
                return { first_name: "", last_name: "", profile_id: "" };
            }
        },


    },
    mounted() {

    },
    data: () => ({
        items: [
            { title: "Search Users", icon: "mdi-account-search", route_name: 'searchMember' },
            { title: "My Profile", icon: "mdi-account-circle", route_name: 'MyProfile' },
            { title: "Profile Images", icon: "mdi-image", route_name: 'MyImages' },
            { title: "Upload Documents", icon: "mdi-file", route_name: 'MyDocuments' },
            { title: "Viewed Profiles", icon: "mdi-account-clock-outline", route_name: 'MyViewedProfiles' },
            { title: "Shortlist Profiles", icon: "mdi-format-list-checks", route_name: 'MyShortlists' },
            { title: "My Interest", icon: "mdi-hand-heart-outline", route_name: 'MyInterests' },
            { title: "Invitation Request", icon: "mdi-tag-heart", route_name: 'MyInvitations' },
            { title: "Subscription", icon: "mdi-wallet-membership", route_name: 'MySubscriptions' },
            //   { title: "Support Tickets", icon: "mdi-lifebuoy", route_name: 'MySupport' },
            { title: "Account Settings", icon: "mdi-cog-outline", route_name: 'MyAccountSetting' },
        ],
    }),
    components: { RouterView, FooterSection }
}
</script>
<style scoped>
.account-session-container {
    background-image: url('@/assets/images/texture_sample.jpg') !important;
    background-repeat: repeat;
    background-size: contain;
    background-position: center;
}
</style>