<template>
    <section class=" ">
        <div class="view_user_background custom_style">
        </div>
        <v-skeleton-loader
            elevation="10"
            class="ma-5 "
            type="card-heading,article, actions"
            v-if="data_loader"
        ></v-skeleton-loader>
        <v-container class="" v-else>
            <v-row>
                <v-col lg="3" cols="12" sm="12" md="12" class="">
                    <v-card class="mt-lg-14 mt-md-14 md-sm-14">
                        <v-card-text>
                            <v-carousel continuous show-arrows hide-delimiter-background height="300" hide-delimiters
                                cycle v-if="profile_images.length > 0" interval="2000" show-arrows-on-hover
                                class="pa-0 ma-0 elevation-5">
                                <v-carousel-item class="" v-for="profile_image in profile_images"
                                    :key="profile_image.uri">
                                    <v-img class="" height="300" width="" aspect-ratio="1.7778"
                                        :src="profile_image.uri">
                                        <v-btn elevation="5" tile
                                            class="white--text mt-3 text-capitalize font-weight-bold" dense x-small
                                            color="pink">
                                            <v-icon left>
                                                mdi-crown-outline
                                            </v-icon>
                                            Premium
                                        </v-btn>

                                    </v-img>
                                </v-carousel-item>
                            </v-carousel>
                            <v-img class="elevation-5" height="300" width="" aspect-ratio="1.7778" v-else :src="user_no_image" >
                                <v-btn elevation="5" tile class="white--text mt-3  text-capitalize font-weight-bold" v-if="this.$store.getters['membership/premiumUser']"
                                    dense x-small color="pink">
                                    <v-icon left>
                                        mdi-crown-outline
                                    </v-icon>
                                    Premium
                                </v-btn>

                            </v-img>
                        </v-card-text>
                        <v-col xl="11" lg="12" sm="12" cols="12" v-if="user.is_verified" class="ma-auto">
                            <v-alert color="green" dismissible elevation="16" type="success">
                                THis Profile Has been Verified
                            </v-alert>
                        </v-col>
                        <v-card-text>
                            <h2>{{ user.first_name + " " + user.last_name }}</h2>
                            <h5 class="pt-3">{{ "Member ID: #"+ user.profile_id }}</h5>
                            <v-spacer></v-spacer>
                        </v-card-text>
                        </v-card>

                </v-col>
                <v-col lg="9" cols="12" sm="12" md="12" class="mt-lg-14 mt-md-14 md-sm-14 ">
                    <v-timeline dense align-top class="">
                        <v-timeline-item fill-dot color="pink" icon="mdi-phone-classic" left large >
                            <v-expansion-panels class="mb-6" flat hide-arrow inset accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header hide-actions>
                                        <h3>Contact Information</h3>

                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card>
                                            <v-card-text class="text-justify">
                                                <v-list-item dense class="pr-3">
                                                    <v-list-item-subtitle>
                                                        <v-row>
                                                            <v-col lg="5" md="5" sm="5" cols="5" class="text-body-2 text-wrap">
                                                                Mobile Number
                                                            </v-col>
                                                            <v-col lg="1" md="1" sm="1" cols="1"
                                                                class="text-body-2 pr-0 mr-0">
                                                                :
                                                            </v-col>
                                                            <v-col lg="5" md="5" sm="5" cols="5"
                                                                class="text-body-2 text-wrap pl-0 ml-0">
                                                                {{ user.mobile_number }}
                                                            </v-col>
                                                        </v-row>
                                                    </v-list-item-subtitle>
                                                </v-list-item>
                                                <v-list-item dense class="pr-3">
                                                    <v-list-item-subtitle>
                                                        <v-row>
                                                            <v-col lg="5" md="5" sm="5" cols="5" class="text-body-2 text-wrap">
                                                               Email Address
                                                            </v-col>
                                                            <v-col lg="1" md="1" sm="1" cols="1"
                                                                class="text-body-2 pr-0 mr-0">
                                                                :
                                                            </v-col>
                                                            <v-col lg="5" md="5" sm="5" cols="5"
                                                                class="text-body-2 text-wrap pl-0 ml-0">
                                                                {{ user.email_address }}
                                                            </v-col>
                                                        </v-row>
                                                    </v-list-item-subtitle>
                                                </v-list-item>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-timeline-item>
                        <v-timeline-item fill-dot color="pink" icon="mdi-format-quote-open" left large>
                            <v-expansion-panels class="mb-6" flat hide-arrow inset accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header hide-actions>
                                        <h3>About {{ user.first_name + " " + user.last_name }}</h3>

                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card>
                                            <v-card-text class="text-justify text-wrap">
                                                <span v-html="user.about_prospect"></span>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-timeline-item>
                        <v-timeline-item fill-dot color="pink" icon="mdi-tooltip-account" left large>
                            <v-expansion-panels class="mb-6" flat hide-arrow inset accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header hide-actions>
                                        <h3>Basic Information</h3>

                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col lg="12" md="12" sm="12" cols="12">
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        First Name
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap pl-0 ml-0">
                                                                        {{ user.first_name }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Middle Name
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{ user.middle_name }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Last Name
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{ user.last_name }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Mother Name
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{ user.mother_name }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Gender
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{ user.gender }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Date of Birth
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{ dateOfBirth }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Maritial Status
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{ user.maritial_status }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Profile Created For
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap" >
                                                                        {{ user.profile_created_by }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-timeline-item>
                        <v-timeline-item fill-dot color="pink" icon="mdi-school" left large >
                            <v-expansion-panels class="mb-6" flat hide-arrow inset accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header hide-actions>
                                        <h3>Education Information</h3>

                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col  sm="12" cols="12">
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Highest Qualification
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap" v-if="user.employee_information != null">
                                                                        {{
                                                                            user.employee_information.highest_education
                                                                        }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Specialized In
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap" v-if="user.employee_information != null">
                                                                        {{ user.employee_information.specialized_in }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Institue Name
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap" v-if="user.employee_information != null">
                                                                        {{ user.employee_information.institue }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>

                                                    </v-col>

                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-timeline-item>
                        <v-timeline-item fill-dot color="pink" icon="mdi-briefcase" left large>
                            <v-expansion-panels class="mb-6" flat hide-arrow inset accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header hide-actions>
                                        <h3>Career Information</h3>

                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col lg="12" md="12" sm="12" cols="12">
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Occupation
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0" v-if="user.employee_information != null">
                                                                        {{ user.employee_information.occupation }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Employee With
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0" v-if="user.employee_information != null">
                                                                        {{ user.employee_information.employee_with }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Employee Location
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0" v-if="user.employee_information != null">
                                                                        {{
                                                                            user.employee_information.employee_location
                                                                        }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Annual Income
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.employee_information != null &&   !user.employee_information.keep_income_private">
                                                                        {{ user.employee_information.annual_income }}
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5" 
                                                                        class="text-body-2 pl-0 ml-0" v-else>
                                                                        Cannot Disclosed
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                    </v-col>

                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-timeline-item>


                        <v-timeline-item fill-dot color="pink" icon="mdi-gymnastics" left large>
                            <v-expansion-panels class="mb-6" flat hide-arrow inset accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header hide-actions>
                                        <h3>Physical Attributes </h3>

                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col lg="12" md="12" sm="12" cols="12">
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Height
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        {{ user.personal_information.height }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Blood Group
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        {{ user.personal_information.blood_group }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Body Type
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        {{ user.personal_information.body_type }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>


                                                   
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Physically Disabled
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        <span
                                                                            v-if="user.personal_information.physically_challanged">Yes</span><span
                                                                            v-else>No</span>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Skin Complexity
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        {{ user.personal_information.skin_complexity }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Health Information
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap pl-0 ml-0" v-if="user.personal_information.health_information.length != 'undefined' ">
                                                                        {{
                                                                            user.personal_information.health_information.toString()
                                                                        }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-timeline-item>
                        <v-timeline-item fill-dot color="pink" icon="mdi-headphones" left large>
                            <v-expansion-panels class="mb-6" flat hide-arrow inset accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header hide-actions>
                                        <h3>Hobbies & Interest </h3>

                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col lg="12" md="12" sm="12" cols="12">
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Hobbies
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{ user.personal_information.hobbies.toString() }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>

                                                    </v-col>


                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-timeline-item>
                        <v-timeline-item fill-dot color="pink" icon="mdi-map-marker" left large>
                            <v-expansion-panels class="mb-6" flat hide-arrow inset accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header hide-actions>
                                        <h3>Social Information</h3>

                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col lg="12" md="12" sm="12" cols="12">
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Eating Habbit
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{ user.social_information.eating_habbit}}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                       Drinking Habbit
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{ user.social_information.drinking_habbit}}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Smoking Habbit
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                       {{ user.social_information.smoking_habbit}}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-timeline-item>
                        <v-timeline-item fill-dot color="pink" icon="mdi-hands-pray" left large>
                            <v-expansion-panels class="mb-6" flat hide-arrow inset accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header hide-actions>
                                        <h3>Religious Information </h3>

                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col lg="12" md="12" sm="12" cols="12">
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Religion
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        {{ user.religious_information.religion }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Caste
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        {{ user.religious_information.caste }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Mother Tongue
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        {{ user.religious_information.mother_tongue }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                  
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Gotra
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        {{ user.religious_information.gotra }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Caste Preference For Partner   
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        <span
                                                                            v-if="user.religious_information.is_caste_nobar === 1 ">Yes</span>
                                                                            <span v-else>No</span>
                                                                           
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>

                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-timeline-item>
                        <v-timeline-item fill-dot color="pink" icon="mdi-star-david" left large>
                            <v-expansion-panels class="mb-6" flat hide-arrow inset accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header hide-actions>
                                        <h3>Astrological Information </h3>

                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col lg="12" md="12" sm="12" cols="12">
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Place of Birth
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        {{ user.astro_information.birth_place }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Birth Time
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        {{ user.astro_information.birth_time }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Nakshatra
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        {{ user.astro_information.nakshatra }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                   
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Rashi
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        {{ user.astro_information.rashi }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Manglik ?
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        <span
                                                                            v-if="user.astro_information.manglik">Yes</span><span
                                                                            v-else>No</span>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Patrika Dosh ?
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        <span
                                                                            v-if="user.astro_information.patrika_dosh">Yes</span><span
                                                                            v-else>No</span>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>

                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-timeline-item>
                        <v-timeline-item fill-dot color="pink" icon="mdi-account-group" left large>
                            <v-expansion-panels class="mb-6" flat hide-arrow inset accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header hide-actions>
                                        <h3>Family Information </h3>

                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col lg="12" md="12" sm="12" cols="12">
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Father Living Status
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{
                                                                            user.family_information.father_living_status
                                                                        }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3"
                                                            v-if="user.family_information.father_living_status === 'Employed'">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Father Employeed With
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{
                                                                            user.family_information.father_employed_with
                                                                        }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3"
                                                            v-if="user.family_information.father_living_status === 'Employed'">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Father Employeed As
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{ user.family_information.father_employed_as }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3"
                                                            v-if="user.family_information.father_living_status === 'Business'">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Father Nature of Business
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{
                                                                            user.family_information.father_nature_of_business
                                                                        }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3"
                                                            v-if="user.family_information.father_living_status === 'Retired'">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Father Retired From
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{
                                                                            user.family_information.father_retired_from
                                                                        }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3"
                                                            v-if="user.family_information.father_living_status === 'Retired'">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Father Retired As
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{ user.family_information.father_retired_as }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Mother Living Status
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{
                                                                            user.family_information.mother_living_status
                                                                        }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3"
                                                            v-if="user.family_information.mother_living_status === 'Employed'">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Mother Employeed With
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{
                                                                            user.family_information.mother_employed_with
                                                                        }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3"
                                                            v-if="user.family_information.mother_living_status === 'Employed'">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Mother Employeed As
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{ user.family_information.mother_employed_as }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3"
                                                            v-if="user.family_information.mother_living_status === 'Business'">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Mother Nature of Business
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{
                                                                            user.family_information.mother_nature_of_business
                                                                        }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3"
                                                            v-if="user.family_information.mother_living_status === 'Retired'">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Mother Retired From
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{
                                                                            user.family_information.mother_retired_from
                                                                        }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3"
                                                            v-if="user.family_information.mother_living_status === 'Retired'">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Mother Retired As
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{ user.family_information.mother_retired_as }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                   
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Married Sisters
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{ user.family_information.married_sisters }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        UnMarried Sisters
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{ user.family_information.unmarried_sisters }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Married Brothers
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{ user.family_information.married_brothers }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        UnMarried Brothers

                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{ user.family_information.unmarried_brothers }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>

                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Family Type
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{ user.family_information.type }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Family Value
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{ user.family_information.family_value }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Family Affluence
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{ user.family_information.affluence }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-timeline-item>
                        <v-timeline-item fill-dot color="pink" icon="mdi-map-marker" left large>
                            <v-expansion-panels class="mb-6" flat hide-arrow inset accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header hide-actions>
                                        <h3>Residence Information</h3>

                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col lg="12" md="12" sm="12" cols="12">
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Family Location
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{ user.family_information.family_location }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Native Place
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        {{ user.family_information.native_place }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Living With Famliy
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap">
                                                                        <span
                                                                            v-if="user.family_information.living_with_family">Yes</span><span
                                                                            v-else>No</span>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-timeline-item>
                        <v-timeline-item fill-dot color="pink" icon="mdi-food" left large>
                            <v-expansion-panels class="mb-6" flat hide-arrow inset accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header hide-actions>
                                        <h3>Partner Preference </h3>

                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col lg="12" md="12" sm="12" cols="12">
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Age From
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        {{ user.user_preference.age_from }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Age Upto
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        {{ user.user_preference.age_upto }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Expected Height
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        {{ user.user_preference.expected_height }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                      Eating Habbit
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        {{ user.user_preference.eating_habbit }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                      Smoking Habbit
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        {{ user.user_preference.smoking_habbit }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                      Drinking Habbit
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        {{ user.user_preference.drinking_habbit }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                      Expected Income
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        {{ user.user_preference.annual_income }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>

                                                  
                                                        
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Manglik ?
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        <span
                                                                            v-if="user.user_preference.is_manglik">Yes</span><span
                                                                            v-else>No</span>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Patrika Dosh ?
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        <span
                                                                            v-if="user.user_preference.is_dosh_present">Yes</span><span
                                                                            v-else>No</span>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Family Value
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        {{ user.user_preference.family_value }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Family Affluence
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        {{ user.user_preference.family_affluence }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                       Family Type
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        {{ user.user_preference.family_type }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                       Highest Qualification
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        {{ user.user_preference.highest_education }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                       Caste Preferences
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap" v-if=" user.user_preference.caste_preferences != undefined">
                                                                        {{ user.user_preference.caste_preferences.toString() }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>

                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-timeline-item>


                    </v-timeline>
                </v-col>
            </v-row>
        </v-container>
        <FooterSection></FooterSection>
        <v-snackbar :timeout="6000" v-model="snackbars.error" top color="red" class="white--text font-weight-bold">
            {{ message }}
        </v-snackbar>
        <v-snackbar :timeout="6000" v-model="snackbars.success" top color="indigo" class="white--text font-weight-bold">
            {{ message }}
        </v-snackbar>
    </section>
</template>
<script>
import FooterSection from '@/components/Global/FooterSection.vue';
import axios from 'axios';
import moment from 'moment';

export default {
    computed:{
        dateOfBirth(){

            return moment(this.user.date_of_birth).format("DD/MM/YYYY");
        }
    },
    mounted() {
      
    },
    created() {  
        this.previewMyProfile();
    },
    methods: {
        previewMyProfile: function () {
                this.data_loader = true;
            axios.get(`${process.env.VUE_APP_API_URL}/previewmyprofile`).then((response) => {
                
                if (response.status === 200) {
                  
                    this.getMyImages();
                    this.user = response.data.data;
                    console.log(response.data.data);
                          
                }
            }).catch((error) => {
                if (error.response) {
                    console.log(error.response);
                    if (error.response.status == 422) {
                        this.message = error.response.data.message;
                        this.snackbars.error = true;
                    }
                }
            }).finally(() => {
                this.data_loader = false;
            });
        },
       
        getMyImages() {
            axios.get(`${process.env.VUE_APP_API_URL}/getmyimages`).then((response) => {
                if (response.status == 200) {
                    this.profile_images = response.data.user_images;
                }
            }).catch((error) => {
                console.log(error);
            }).finally(() => {

            })
        },
      
       
    },
    data: () => ({
        data_loader:false,
        profile_images: [],
        user_no_image: require('@/assets/images/user-no-image.jpg'),
        dialog: false,
        contact_dialog: false,
        show_contacts: false,
        loaders: {
            shortlist_loader: false,
            send_interest_loader: false,
            view_contact_loader: false,

        },
        snackbars: {
            error: false,
            success: false,
        },
        message: '',
        user: {
            family_information: {},
            social_information: {},
            employee_information: {
                highest_education:""
            },
            religious_information: {},
            astro_information: {},
            personal_information: {
                health_information:[],
                hobbies:[],
            },
            user_preference:{
                caste_preferences:[],
            },
            mobile_number: '',


        },

    }),
    components: { FooterSection }
}




</script>
<style scoped>
.hero_section {
  
}
.custom_style{
    z-index: -999 !important;
}
.view_user_background {
    height: 30px !important;
    background-image: linear-gradient(-60deg, #ff5858 0%, #f09819 100%);
}
</style>