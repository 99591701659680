<template>
    <v-container fluid class="pa-0 ma-0">
        <section class="pricing_hero">
            <v-row>
                <v-col lg="12" sm="12" md="12" cols="12">
                    <h3 class="text-center">Pricings</h3>
                    <h6 class="text-center">Find Your Soulmate with Our Affordable and Personalized Plans</h6>
                </v-col>
            </v-row>
            <section class="pricing_section">
                <Hooper :settings="hoopersettings" style="height:650px;">
                    <Slide v-for="plan, index in normal_plans" :key="index" style="margin-left: 3px !important;">  
                        <v-card elevation="12" :class="getCardBackground(index)">
                            <v-card-title class="plan_name">
                                <h3 class="ma-auto">{{ plan.title }}</h3>
                            </v-card-title>
                            <v-card-text class="plan_price">
                                <h4 class="text-center"><v-icon class="" color="white" size="20">mdi-currency-inr</v-icon>{{
                                    plan.price }}</h4>
                                <v-divider class="mt-3 white ml-10 mr-10" light></v-divider>
                                <p class="text-center plan_period"> {{ plan.maxduration_in_months }} Months</p>
                            </v-card-text>
                            <v-card-text class="text-center" v-if="isLoggedIn">
                                <v-btn v-if="plan.price == 0" class="rounded-pill  elevation-15 price_button " medium color="white"
                                    @click="createPurchaseOrder(plan)" :loading="loaders.payment_loader"
                                    :disabled="loaders.payment_loader">Subscribe</v-btn>
                                    <v-btn v-else class="rounded-pill  elevation-15 price_button " medium color="white"
                                     :loading="loaders.payment_loader" to="/ContactUs"
                                    :disabled="loaders.payment_loader">Contact Us</v-btn>
                            </v-card-text>
                            <v-card-text class="pricing_features">
                                <v-list dense class="ml-2 transparent ">
                                    <!-- <v-list-item>
                                        <v-list-item-icon class="pr-3 mr-0 ">
                                            <v-icon size="16" color="white">mdi-check-circle</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="price_item text-wrap white--text">Unlimited
                                                Profile Searches</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item> -->
                                    <v-list-item>
                                        <v-list-item-icon class="pr-3 mr-0 ">
                                            <v-icon size="16" color="white">mdi-check-circle</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="price_item text-wrap white--text">{{
                                                "Viewed Upto " + plan.contactview_allowed + " Contacts"
                                            }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-icon class="pr-3 mr-0 ">
                                            <v-icon size="16" color="white">mdi-check-circle</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="price_item text-wrap white--text">{{
                                                "Send Upto " + plan.express_interest_allowed + " Interest"
                                            }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-icon class="pr-3 mr-0 ">
                                            <v-icon size="16" color="white">mdi-check-circle</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title :class="getCss(plan.highlight_profile)">Standout from
                                                other Profiles</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-icon class="pr-3 mr-0 ">
                                            <v-icon size="16" color="white">mdi-check-circle</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title :class="getCss(plan.top_place_on_search)">Place your
                                                Profile on Top of Searchlist</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-icon class="pr-3 mr-0 ">
                                            <v-icon size="16" color="white">mdi-check-circle</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title class="price_item text-wrap white--text">Let Matches
                                                contact you Directly</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </Slide>
                    <hooper-navigation slot="hooper-addons"></hooper-navigation>
                </Hooper>
            </section>
        </section>
        <v-container class="bonus_plans">
            <v-row>
                <v-col lg="12" sm="12" md="12" cols="12">
                    <h3 class="text-center">Bonus Plans</h3>
                    <h6 class="text-center">Get Extra with Regular Plans</h6>
                </v-col>
            </v-row>
            <v-row align-content="center">
                <v-col cols="12" md="6" lg="4" sm="12" v-for="plan, index in bonus_plans" :key="index"
                    class="bonus_plan_card">
                    <v-card min-height="650" :class="getCardBackground(index)">
                        <v-card-text>
                            <v-icon size="80" color="white" class="pa-2">
                                {{ plan.plan_icon }}
                            </v-icon>
                        </v-card-text>
                        <v-card-text>
                            <v-row>
                                <v-col lg="12" md="12" sm="12" cols="12" class="bonus_plan_pricing">
                                    <h2 class="bonus_plan_name">{{ plan.title }}</h2>
                                    <p class="bonus_plan_price"><v-icon color="white">mdi-currency-inr</v-icon>{{
                                        plan.price
                                    }}</p>
                                    <p class="bonus_plan_caption" v-if="!plan.personal_consulting"> {{
                                        plan.maxduration_in_months
                                    }} Months</p>
                                    <p class="bonus_plan_caption" v-else> {{ plan.personal_consulting_duration_hours }}
                                        Hour's</p>
                                    <v-btn color="white" class="" elevation="10" hover @click="createPurchaseOrder(plan)"
                                        v-if="isLoggedIn" :loading="loaders.payment_loader" to="/ContactUs"
                                        :disabled="loaders.payment_loader">Contact Us</v-btn>
                                </v-col>

                                <v-col lg="12" md="12" sm="12" cols="12" class="bonus_plan_pricing">
                                    <div class="bonus_plan_details text-wrap white--text" v-html="plan.features">

                                    </div>

                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>

            </v-row>
        </v-container>
        <FooterSection></FooterSection>
        <v-snackbar v-model="snackbar.display" :color="snackbar.color" centered top absolute class="rounded-pill font-weight-bold">
            {{ snackbar.message }}
        </v-snackbar>
    </v-container>
</template>

<script>

import FooterSection from '@/components/Global/FooterSection.vue';
import axios from 'axios';
// import { cashfreeSandbox } from "cashfree-pg-sdk-javascript";
import { cashfreeProd } from "cashfree-pg-sdk-javascript";
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper';
import 'hooper/dist/hooper.css';
import { mapGetters } from 'vuex';
export default {
    components: { FooterSection, Hooper, Slide, HooperNavigation, },
   
    computed: {
        ...mapGetters({
            isLoggedIn: 'auth/isLoggedIn',
            user: 'auth/user',
            premiumUser: 'membership/premiumUser'
        }),
        isLoggedIn(){
            return this.$store.getters['auth/isLoggedIn'];
        }
    },
    mounted() {
        this.getMembershipPlans();
       
    },
    created() {
        
    },
    methods: {
        getCss(property) {
            return {
                'text-wrap white--text text-decoration-line-through': !property,
                'text-wrap white--text': property,
            };
        },
        getCardBackground(index) {

            return this.cards_css_box[index];
        },
        getMembershipPlans: function () {
            axios.get(`${process.env.VUE_APP_API_URL}/getmembershipplans`).then((response) => {
                if (response.status == 200) {
                    // console.log(response.data);
                    this.bonus_plans = response.data.bonus_plans;
                    this.normal_plans = response.data.plans;
                }
            }).catch((error) => {

            }).finally(() => {

            })
        },
        addFreeMemberShips: function (plan) {
            axios.post(`${process.env.VUE_APP_API_URL}/createfreemembership`, plan).then((response) => {
                if (response.status == 200) {
                  this.$router.push({name:"MySubscriptions",query:{payment:"sucess"}});
                }
            }).catch((error) => {
                console.log(error.response.status);
                if(error.response.status == 422){
                    this.snackbar.color = "red darken-3";
                    this.snackbar.message = error.response.data.message;
                    this.snackbar.display = true;
                }
            }).finally(() => {
                this.loaders.payment_loader = false;
            });
        },
        createPurchaseOrder: function (plan) {

            if (plan.price == 0) {
                this.addFreeMemberShips(plan);

            }
            else {
                axios.post(`${process.env.VUE_APP_API_URL}/createpurchaseorder`, plan).then((response) => {
                    if (response.status == 200) {
                        let cashfree = new cashfreeProd.Cashfree(response.data.session_id);
                        //   let cashfree = new cashfreeSandbox.Cashfree(response.data.session_id);
                        cashfree.redirect();
                    }
                }).catch((error) => {
                    console.log(error);
                }).finally(() => {
                    this.loaders.payment_loader = false;
                });
            }


            this.loaders.payment_loader = true;

        }
    },
    data: () => ({
        bonus_plans: [],
        normal_plans: [],
        loaders: {
            payment_loader: false,
        },
        snackbar: {
            display: false,
            color: "indigo",
            message: false,
        },
        hoopersettings: {
            itemsToShow: 3,
            centerMode: true,
            autoPlay: true,
            playSpeed: 2000,
            infiniteScroll: true,
            wheelControl: false,
            breakpoints: {
                400: {
                    centerMode: true,
                    itemsToShow: 1
                },
                500: {
                    centerMode: false,
                    itemsToShow: 1
                },
                600: {
                    itemsToShow: 2,
                    centerMode: true,

                },
                700: {
                    itemsToShow: 2,
                    centerMode: true,
                },
                800: {
                    itemsToShow: 2,
                    centerMode: false,
                },
                900: {
                    itemsToShow: 3,
                    centerMode: false,
                },
                1000: {
                    itemsToShow: 3,
                    centerMode: false,
                },
                1200: {
                    itemsToShow: 3,
                    centerMode: false,
                }
            }
        },
        cards_css_box: [
            'pricing_card_one rounded-xl',
            'pricing_card_two rounded-xl',
            'pricing_card_three rounded-xl',
            'pricing_card_two rounded-xl',
            'pricing_card_one rounded-xl',
            'pricing_card_two rounded-xl',
            'pricing_card_three rounded-xl',
            'pricing_card_two rounded-xl',
            'pricing_card_one rounded-xl',
            'pricing_card_two rounded-xl',
            'pricing_card_three rounded-xl',
            'pricing_card_two rounded-xl',
            'pricing_card_one rounded-xl',
            'pricing_card_two rounded-xl',
            'pricing_card_three rounded-xl',
            'pricing_card_two rounded-xl',],

    }),
}
</script>
<style scoped>
.bonus_plans {
    background-color: #fff !important;
    padding-top: 4%;
    max-width: 1200px !important;
}

.bonus_plan_card {
    padding-top: 10% !important;
}

.bonu_plan_pricing {
    padding-top: 5% !important;
}

.bonus_plan_pricing .bonus_plan_name {
    padding-top: 2% !important;
    line-height: 2.5rem !important;
    font-size: 2.3rem !important;
    color: #fff;
    font-weight: 400 !important;
    font-family: 'Nunito' !important;
}

.bonus_plan_pricing .bonus_plan_price {
    padding-top: 8% !important;
    font-size: 2.8rem !important;
    color: #fff;
    font-weight: 800 !important;
    font-family: 'Nunito' !important;
}

.bonus_plan_pricing .bonus_plan_caption {
    padding-top: 2% !important;
    font-size: 1.0rem !important;
    color: #fff;
    font-weight: 800 !important;
    font-family: 'Nunito' !important;
}

.bonus_plan_details {
    font-size: 1.0rem !important;
    color: #fff;
    font-weight: 300 !important;
    font-family: 'Nunito' !important;
}

.bonus_plans h3 {
    font-family: 'Great Vibes' !important;
    font-weight: 600 !important;
    color: #6f2836 !important;
    letter-spacing: 0.2rem !important;
    font-size: 3.4rem !important;
}

.bonus_plans h6 {
    padding-top: 0.5%;
    font-family: 'Nunito' !important;
    font-weight: 400 !important;
    color: #6f2836 !important;
    letter-spacing: 0.0rem !important;
    font-size: 1.2rem !important;
}
</style>