<template>
    <section class="howitworks-section d-flex justify-center ma-auto pb-14 mb-14">
        <v-container >
            <v-row>
                <v-col lg="12" cols="12" sm="12" md="12" class="text-center ma-auto">
                    <h2 class="title">Find Someone Special</h2>
                    <p class="subtitle">" How it Works "</p>
                </v-col>
                <v-col lg="12" md="12" sm="12" class="working-process">
                    <v-row
                        :class="{ 'justify-space-between': $vuetify.breakpoint.mdAndUp, 'justify-sm-center': $vuetify.breakpoint.smAndDown }">
                        <div class="card ma-sm-auto mt-sm-14">
                            <div class="testimonial">
                                <div class="cardBody text-center">
                                    <div class="h2">01</div>
                                    <div class="h3 text-capitalize mt-3">Create a <br>Private Profile</div>
                                    <div class="text mb-4 pt-2 ">This improves your profile visibility to matching profiles as per your partner preference.  </div>
                                </div>
                            </div>
                        </div>
                        <div class="card ma-sm-auto mt-sm-14">
                            <div class="testimonial ">
                                <div class="cardBody text-center">
                                    <div class="h2">02</div>
                                    <div class="h3 text-capitalize mt-3">Meet Your<br> MatchMaker</div>
                                    <div class="text mb-4 pt-2 ">Send interest to profiles which could be your special someone for a lifetime </div>
                                </div>
                            </div>
                        </div>
                        <div class="card ma-sm-auto mt-sm-14">
                            <div class="testimonial d-flex">
                                <div class="cardBody text-center">
                                    <div class="h2">03</div>
                                    <div class="h3 text-capitalize mt-3">Find Your<br> Life Partner</div>
                                    <div class="text mb-4 pt-2 ">Make honest and sincere interaction and meaningful discussion for making the most important decision of your life.  </div>
                                </div>
                            </div>
                        </div>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>
<script>
export default {
    mounted() {

    },
    data: () => ({
    }),
}
</script>
<style scoped>
.howitworks-section .title  {
    font-family: 'Great Vibes' !important;
    font-size: 4.5rem !important;
    font-weight: 550 !important;
    margin: auto !important;
    color: #6f2863 !important;
}

@media only screen and (max-width: 1264) and (min-width: 960px){
  
}
@media only screen and (max-width: 960px) and (min-width: 600px){
    .howitworks-section .title  {
    font-size: 3.0rem !important;
    font-weight: 550 !important;
    margin: auto !important;
    color: #6f2863 !important;
}
}
@media only screen and (max-width: 600px){
    .howitworks-section .title  {
    font-size: 2.5rem !important;
    font-weight: 550 !important;
    margin: auto !important;
    color: #6f2863 !important;
}
}
</style>