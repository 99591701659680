<template>
    <v-container>
        <v-row>
            <v-col lg="12" cols="12">
                <v-btn
                    class="text-right float-start orange-gradient white--text text-capitalize font-weight-bold pl-3 pr-3 elevation-4"
                    @click="dialogs.file_input = !dialogs.file_input">Upload Images</v-btn>
            </v-col>
            <v-col lg="12" cols="12">
                <v-row v-if="profile_images">
                    <v-col lg="3" cols="12" sm="6" md="3" v-for="image, index in profile_images" :key="index" class="">
                        <v-card>
                           
                                <v-img class="rounded" :src="image.uri" :lazy-src="user_no_image" height="280" width="280"
                                    contain>
                                    <v-btn color="red" class="text-right float-end elevation-1" small
                                        @click="deleteImage(image.id)"><v-icon color="white"
                                            size="18">mdi-delete</v-icon></v-btn>
                                            <v-avatar color="orange darken-2" size="25" class=" ma-1 font-weight-bold">{{ index + 1 }}</v-avatar>
                                </v-img>
                        </v-card>

                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-dialog v-model="dialogs.file_input" width="600" height="500">
            <v-card>
                <ValidationObserver ref="imageuploadform" v-slot="{ handleSubmit }">
                    <v-form @submit.prevent="handleSubmit(uploadImages)">

                        <v-card-title>
                            <v-col lg="12" cols="12">
                                <ValidationProvider name="Upload Images" rules="required|image" v-slot="{ errors }">
                                    <v-file-input counter multiple show-size @click:clear="clearImages" accept="image/*"
                                        label="Upload Images" @change="onFileChange" clearable :error-messages="errors"
                                        v-model="images"></v-file-input>
                                </ValidationProvider>
                                <span class="text-caption red--text text-wrap">Note: Images should be of maximum size 1024KB
                                    and .jpg,.png,.jpeg format are accepted.</span>
                            </v-col>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col lg="3" sm="6" md="4" cols="12" v-for="imageurl, index in preview_urls" :key="index">
                                    <v-img :src="imageurl" contain max-height="300" max-width="300"></v-img>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="ma-auto">
                            <v-spacer></v-spacer>
                            <v-btn class="rounded-xl" color="primary" type="submit"
                                :loading="loaders.uploade_image_loader">Upload</v-btn>
                        </v-card-actions>
                    </v-form>
                </ValidationObserver>
            </v-card>
        </v-dialog>
        <v-snackbar :timeout="5000" v-model="snackbar" top color="indigo" class="black--text">
            {{ message }}
        </v-snackbar>
        <v-snackbar :timeout="5000" v-model="error_snackbar" top color="red" class="white--text">
            {{ message }}
        </v-snackbar>
    </v-container>
</template>
<script>
import axios from 'axios';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
    data: () => ({
        profile_images: [],
        user_no_image: require('@/assets/images/user-no-image.png'),
        upload_form: new FormData,
        error_snackbar: false,
        profile_image: 0,
        images: [],
        loaders: {
            uploade_image_loader: false,
            delete_loader: false,
        },
        preview_urls: [],
        dialogs: {
            file_input: false,
        },
        snackbar: false,
        message: '',
    }),
    created() {
        this.getUserImages();
    },
    methods: {
        getUserImages() {
            axios.get(`${process.env.VUE_APP_API_URL}/getmyimages`).then((response) => {
                if (response.status == 200) {
                    this.profile_images = response.data.user_images;
                }
            }).catch((error) => {
                console.log(error);
            }).finally(() => {

            })
        },
        deleteImage: function (image_id) {
            this.loaders.delete_loader = true;
            let user_delete = this.profile_images.find(image => image.id === image_id);
            axios.post(`${process.env.VUE_APP_API_URL}/deleteuserimage`, user_delete).then((response) => {
                if (response.status == 200) {
                    this.message = response.data.message;
                    this.snackbar = true;
                    // console.log(this.profile_images.findIndex((image)=> image.id === image_id));
                    this.profile_images.splice(this.profile_images.findIndex((image) => image.id === image_id), 1);
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status == 422) {
                        this.message = error.response.data.message;
                        this.error_snackbar = true;
                    }
                }
            }).finally(() => {
                this.loaders.delete_loader = false;
            })
        },
        onFileChange: function (images) {
            images.forEach(image => {
                this.preview_urls.push(URL.createObjectURL(image));

            });
        },
        clearImages: function () {

            this.images = [],
                this.preview_urls = [];
        },
        uploadImages: function () {
            this.loaders.uploade_image_loader = true;
            this.images.forEach(image => {
                this.upload_form.append('images[]', image);
            });

            this.preview_urls = [];
            axios.post(`${process.env.VUE_APP_API_URL}/uploadimages`, this.upload_form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                if (response.status == 200) {
                    this.message = response.data.message;
                    this.snackbar = true;
                    this.getUserImages();
                }

            }).catch((error) => {
                if (error.response) {
                    if (error.response.status == 422) {
                        this.$refs.imageuploadform.setErrors({
                            "Upload Images": error.response.data.errors.images,
                        });
                    }
                    if (error.response.status == 419) {
                        this.message = error.response.data.message;
                        this.error_snackbar = true;
                        this.images = [];
                        this.preview_urls = [];
                        this.upload_form.delete('images[]');
                        this.dialogs.file_input = false;
                    }
                }

            }).finally(() => {
                this.loaders.uploade_image_loader = false;
                this.images = [];
                this.preview_urls = [];
                this.upload_form.delete('images[]');
                this.dialogs.file_input = false;


            });
        }
    },
    components: { ValidationObserver, ValidationProvider }
}
</script>
<style scoped>
.orange-gradient {
    background: linear-gradient(225deg, rgba(253, 44, 121, 1) 0%, rgba(253, 101, 91, 1) 100%) !important;
    color: #fff !important;
}
</style>