import axios from "axios";
import router from "@/router";

export default {
    namespaced: true,
    state: {
        isLoggedIn: localStorage.getItem('isLoggedIn'),
        user: JSON.parse(localStorage.getItem('user')),
        token: localStorage.getItem('token'),
        open_signup: "register",
    },
    getters: {
        isLoggedIn(state) {
            console.log(state.isLoggedIn);
            return state.isLoggedIn;
            
        },
        user(state) {
            return state.user;
        },
        token(state) {
            return state.token;
        },
        userAction(state) {
            return state.user_action;
        },
    },
    mutations: {
        setAuthentication(state, value) {
            state.isLoggedIn = value;
            localStorage.setItem('isLoggedIn', value);
        },
        setUser(state, value) {
            state.user = value;
            localStorage.setItem('user', JSON.stringify(value));
        },
        setToken(state, value) {
            state.token = value;
            localStorage.setItem('token', value);
        },
        setuserAction(state, value) {
            state.user_action = value;
        }



    },
    actions: {
        login({ commit }, data) {

            if (data.access_token && data.access_token != null) {
                localStorage.removeItem("token");
                localStorage.removeItem("isLoggedIn");
                localStorage.removeItem("user");
                commit('setUser', data.user);
                commit('setAuthentication', true);
                commit('setToken', data.access_token);
                router.push({ name: 'MyProfile' });
            }
        },
        logout({ commit }) {
            commit('setUser', {})
            commit('setAuthentication', false)
            commit('setToken', '');
            localStorage.removeItem("token");
            localStorage.removeItem("isLoggedIn");
            localStorage.removeItem("user");
            axios.defaults.headers.common['Authorization'] = null;
            router.push({ name: "Home" }).catch((error) => { });
        },
        userAction({ commit }, value) {

            commit('setuserAction', value);
        }

    }
}