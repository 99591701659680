<template>
    <v-container>
        <v-card outlined flat>
            <v-card-title>
                <h5>My Shortlist</h5>
                <v-spacer></v-spacer>
                <v-btn class="orange-gradient" small dense link href="/search/users">Search Users</v-btn>
            </v-card-title>
            <v-divider>
            </v-divider>
            <v-data-table :headers="shorlist_headers" :loading="loaders.list_loader" :items="shortlisted_profiles">
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn link text icon v-if="premiumUser == true">
                        <router-link :to="{ name:'viewuserprofile' , params:{ profile_id:item.profile_id }}">
                        <v-icon small color="primary" class="ma-1">
                            mdi-eye
                        </v-icon>
                        </router-link>
                    </v-btn>
                    <v-tooltip bottom v-else>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn link text icon  v-bind="attrs" v-on="on">
                                    <v-icon small color="grey" class="ma-1" disabled>
                                        mdi-eye
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Only Premium User can view Profile</span>
                        </v-tooltip>
                    <v-dialog v-model="dialog" width="300">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn link text icon v-on="on" v-bind="attrs" @click="dialog = true">
                                    <v-icon small color="red" class="ma-1">
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </template>

                            <v-card>
                                <v-card-title class="headline lighten-2" primary-title>
                                    Confirmation
                                </v-card-title>

                                <v-card-text>
                                    Are you sure you want to delete user. Once deleted it cannot be revert.
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="red" text @click="deleteShortlistedUser(item)"
                                        :loading="loaders.reject_loader">
                                        Confirm
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                </template>
            </v-data-table>

        </v-card>
        <v-snackbar :timeout="5000" v-model="snackbar" top color="indigo" class="black--text">
            {{ message }}
        </v-snackbar>
        <v-snackbar :timeout="5000" v-model="error_snackbar" top color="red" class="white--text">
            {{ message }}
        </v-snackbar>
    </v-container>
</template>
<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            isLoggedIn: 'auth/isLoggedIn',
            user: 'auth/user',
            premiumUser: 'membership/premiumUser'
        }),
    },
    created() {
        this.getShortlistedProfiles();
    },
    methods: {
        deleteShortlistedUser:function(user){
            this.loaders.delete_loader = true;
            user = Object.assign({},user);
            axios.post(`${process.env.VUE_APP_API_URL}/deleteshortlisteduser`,user).then((response)=>{
                if (response.status == 200) {
                    this.message = response.data.message;
                    this.snackbar = true;
                    this.getShortlistedProfiles();
                }
            }).catch((error)=>{
                if (error.response) {
                    if (error.response.status == 422) {
                        this.message = error.response.data.message;
                        this.error_snackbar = true;
                    }
                }
            }).finally(()=>{
                this.loaders.delete_loader = false;
                this.dialog = false;
            });



        },
        getShortlistedProfiles: function () {
            this.loaders.list_loader = true;
            axios.get(`${process.env.VUE_APP_API_URL}/getshortlistedprofiles`).then((response) => {
                if (response.status == 200) {
                    this.shortlisted_profiles = response.data.data;
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status == 422) {
                        this.message = error.response.data.message;
                        this.error_snackbar = true;
                    }
                }
            }).finally(() => {
                this.loaders.list_loader = false;
                
            })
        }
    },
    data: () => ({
        dialog:false,
        message:'',
        error_snackbar:false,
        snackbar:false,
        loaders: {
            list_loader: false,
            delete_loader:false,
        },
        shortlisted_profiles: [],
        shorlist_headers: [
            {
                text: '#Member Id',
                align: 'start',
                sortable: true,
                value: 'profile_id',
            },

            {
                text: 'Name',
                align: 'start',
                sortable: false,
                value: 'name',
            },
            {
                text: 'Date Of Birth',
                align: 'start',
                sortable: false,
                value: 'date_of_birth',
            },

            {
                text: 'Actions',
                align: 'center',
                sortable: false,
                value: 'actions',
            },

        ]
    })
}

</script>
<style>
.orange-gradient {
    background: linear-gradient(225deg, rgba(253, 44, 121, 1) 0%, rgba(253, 101, 91, 1) 100%) !important;
    color: #fff !important;
}
</style>