import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index';
/* eslint-disable */
Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: require('../views/static/IndexPage.vue').default,
        meta: {
            auth: false,

        }
    },
    {
        path: '/aboutus',
        name: 'AboutUs',
        component: require('../views/static/AboutUs.vue').default,
        meta: {
            auth: false,
        }
    },
    {
        path: '/contactus',
        name: 'ContactUs',
        component: require('../views/static/ContactUsPage.vue').default,
        meta: {
            auth: false,
        }
    },
    {
        path: '/kesari-elite',
        name: 'KesariElite',
        component: require('../views/static/EliteMatrimonyPage.vue').default,
        meta: {
            auth: false,
        }
    },
    {
        path: '/blogs',
        name: 'Blogs',
        component: require('../views/blogs/BlogIndex.vue').default,
        meta: {
            auth: false,
        }
    },
    {
        path: '/blog/:blog_id',
        name: 'Blog',
        component: require('../views/blogs/BlogDetail.vue').default,
        meta: {
            auth: false,
        }
    },
    {
        path: '/privacy-policies',
        name: 'PrivacyPolicies',
        component: require('../views/static/PrivacyPolicyPage.vue').default,
        meta: {
            auth: false,
        }
    },
    {
        path: '/terms-and-conditions',
        name: 'TermsAndConditions',
        component: require('../views/static/TermsAndConditionPage.vue').default,
        meta: {
            auth: false,
        }
    },


    {
        path: '/404',
        name: '404',
        component: require('../views/static/404Page.vue').default,
        meta: {
            auth: false,
        }
    },
    {
        path: '/500',
        name: '500',
        component: require('../views/static/500Page.vue').default,
        meta: {
            auth: false,
        }
    },
    {
        path: '/429',
        name: '429',
        component: require('../views/static/429Page.vue').default,
        meta: {
            auth: false,
        }
    },


    //session based routes
    {
        path: '/profile/:profile_id',
        name: 'viewuserprofile',
        component: require('../views/member/ViewUserProfilePage.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/preview-profile',
        name: 'previewProfle',
        component: require('../views/member/PreviewProfilePage.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/search/users',
        name: 'searchMember',
        component: require('../views/member/SearchMemberPage.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/pricing',
        name: 'membershipPricing',
        component: require('../views/memberships/PricingPageList.vue').default,
        meta: {
            auth: false,
        },
    },
    {
        path: '/checkout',
        name: 'checkout',
        component: require('../views/memberships/checkoutPage.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/payments/status/',
        name: 'paymentstatuspage',
        component: require('../views/memberships/PaymentStatusPage.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/myaccount',
        name: 'MyAccount',
        component: require('../views/myprofile/IndexPage.vue').default,
        meta: {
            auth: true,
        },
        children: [

            {
                path: 'profile-settings',
                name: 'MyProfile',
                component: require('../views/myprofile/ProfilePage.vue').default
            },
            {
                path: 'profile-gallery',
                name: 'MyImages',
                component: require('../views/myprofile/GalleryPage.vue').default
            },
            {
                path: 'my-documents',
                name: 'MyDocuments',
                component: require('../views/myprofile/MyDocumentsPage.vue').default
            },
            {
                path: 'my-interests',
                name: 'MyInterests',
                component: require('../views/myprofile/MyInterestPage.vue').default
            },
            {
                path: 'my-invitations',
                name: 'MyInvitations',
                component: require('../views/myprofile/MyInvitations.vue').default
            },
            {
                path: 'my-shortlists',
                name: 'MyShortlists',
                component: require('../views/myprofile/MyShortlistedPage.vue').default
            },
            {
                path: 'viewed-profiles',
                name: 'MyViewedProfiles',
                component: require('../views/myprofile/ViewedProfilesPage.vue').default
            },
            {
                path: 'subscriptions',
                name: 'MySubscriptions',
                component: require('../views/myprofile/MySubscriptionPage.vue').default
            },
            {
                path: 'subscriptionplans',
                name: 'SessionSubscriptionPlans',
                component: require('../views/myprofile/SubscriptionPlanPage.vue').default
            },
            // {
            //   path: 'my-support',
            //   name:'MySupport',
            //   component: require('../views/myprofile/SupportRequestPage.vue').default
            // },
            {
                path: 'my-account-settings',
                name: 'MyAccountSetting',
                component: require('../views/myprofile/AccountSettingPage.vue').default
            },
        ]
    },

]

const router = new VueRouter({
    mode: 'history',
    routes,

})



// routing guard
router.beforeEach((to, from, next) => {

    if (to.meta.auth == false) {
        next()
    } else {
        if (store.state.auth.isLoggedIn) {
            next()
        } else {
            next({ name: "Home" })
        }
    }

});

export default router