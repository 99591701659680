<template>
    <v-footer class="pa-0 ma-0 footer-content">
        <v-container>
            <v-row>

                <v-col lg="6" cols="12" class="d-flex justify-center footer-section-2" >
                    <v-container>
                        <v-row>
                            
                            <v-col cols="4" lg="3" sm="4" md="4">
                                <h4>Company</h4>
                                <v-divider width="90" class="mt-1"></v-divider>
                                <ul>
                                    <li class="pt-3 text-decoration-none">
                                    <router-link to="/aboutus">
                                        About Us
                                    </router-link>
                                    </li>
                                    <li class="pt-3 text-decoration-none">
                                    <router-link to="/blogs">
                                        Blogs
                                    </router-link>
                                    </li>
                                   
                                    <li class="pt-3 text-decoration-none">
                                    <router-link to="/kesari-elite">
                                        Elite Membership
                                    </router-link>
                                    </li>
                                    <li class="pt-3 text-decoration-none">
                                    <router-link to="/contactus">
                                        Contact Us
                                    </router-link>
                                    </li>
                                </ul>
                            </v-col>
                            <v-col cols="4" lg="3" sm="4" md="4">
                                <h4>Need Help ?</h4>
                                <v-divider width="90" class="mt-1"></v-divider>
                                <ul>
                                    <li class="pt-3 text-decoration-none">
                                    <router-link to="/pricing">
                                        Premium Memberships
                                    </router-link>
                                    </li>
                                    <li class="pt-3 text-decoration-none">
                                    <router-link to="/contactus">
                                        Customer Support
                                    </router-link>
                                    </li>
                                    
                                  
                                </ul>
                            </v-col>
                            <v-col cols="4" lg="3" sm="4" md="4">
                                <h4>Privacy & You</h4>
                                <v-divider width="105" class="mt-1"></v-divider>
                                <ul>
                                    <li class="pt-3 text-decoration-none">
                                    <router-link to="/terms-and-conditions">
                                        Terms of Use
                                    </router-link>
                                    </li>
                                    <li class="pt-3 text-decoration-none">
                                    <router-link to="/privacy-policies">
                                        Privacy Policies
                                    </router-link>
                                    </li>
                                   

                                </ul>
                            </v-col>

                        </v-row>
                    </v-container>
                </v-col>
                <v-col lg="6" class="text-lg-right text-center pa-5 footer-section-2 ">
                    <h3>Kesari Matrimony</h3>
                    <span class="copyright-content">Copyright - <v-icon size="15" class=""
                            color="#fff">mdi-copyright</v-icon>{{ new Date().getFullYear() }} All Right
                        Reserved<br>This website is developed and maintained by Kesari Matrimony.</span>
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>

<script>
export default {
    mounted() {

    },
    data: () => ({

    }),
}
</script>
<style scoped>
a{
    text-decoration: none !important;
    color: #fff !important;
}

</style>