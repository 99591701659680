<template>
    <v-container fluid class="pa-0 ma-0">
        <CustomAppbar></CustomAppbar>
        <v-container>
            <v-row>
                <v-col lg="12" cols="12" sm="12" md=12>
                    <v-row>
                        <v-col lg="3" cols="12" sm="12" md="4">
                            <v-row>
                                <v-col lg="12" cols="12" sm="12">
                                    <h4 class="">Refine Search</h4>
                                </v-col>
                                <!-- <v-divider></v-divider> -->
                                <!-- <v-col lg="11" cols="12" sm="12">
                                    <v-btn  color="orange" class="elevation-4 font-weight-bold white--text ma-auto"
                                        @click="searchUsers" :disabled="filter_loader" :loading="filter_loader">Apply
                                        Filters</v-btn>
                                        <v-btn  color="red" class="elevation-4 font-weight-bold white--text ma-auto  ml-3 mr-3"
                                        @click="removeFilters" :disabled="filter_loader" :loading="filter_loader">Remove
                                        Filters</v-btn>
                                </v-col> -->
                                <v-col lg="11">
                                    <v-expansion-panels accordin>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                <h5>Profile Number</h5>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-text-field label="Profile Number" outlined dense clearable
                                                    v-model="filters.profile_id"></v-text-field>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>

                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                <h5>Age</h5>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-select label="Age From" :items="getFromAge" outlined dense clearable 
                                                    v-model="filters.age_from"></v-select>
                                                <v-select label="Age UpTo" :items="getToAge" outlined dense v-model="filters.age_upto" clearable></v-select>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                <h5>Castes</h5>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <!-- <v-card flat text max-height="350" class="overflow-auto"> -->
                                                <v-autocomplete v-model="filters.castes" outlined :items="castes"
                                                    single-line multiple small-chips full-width>

                                                </v-autocomplete>
                                                <!-- </v-card> -->
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                <h5>Mother Tongue</h5>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-autocomplete v-model="filters.mother_tongues" outlined clearable
                                                    :items="mother_tongues" single-line multiple small-chips full-width>

                                                </v-autocomplete>


                                            </v-expansion-panel-content>
                                        </v-expansion-panel>

                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                <h5>Educations</h5>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-autocomplete v-model="filters.educations" outlined :items="educations"
                                                    single-line multiple small-chips item-text="Degree" item-value="Degree"
                                                    clearable full-width>
                                                    <template v-slot:item="data">

                                                        <v-list-item-content>
                                                            <v-list-item-title class="text-wrap">{{
                                                                data.item.Degree
                                                            }}</v-list-item-title>
                                                            <v-list-item-subtitle class="text-wrap">{{
                                                                data.item.category
                                                            }}</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </template>
                                                </v-autocomplete>


                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                <h5>Profession</h5>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-autocomplete v-model="filters.occupations" outlined :items="occupations"
                                                    clearable single-line multiple small-chips item-text="occupation"
                                                    item-value="occupation" full-width>
                                                    <template v-slot:item="data">

                                                        <v-list-item-content>
                                                            <v-list-item-title class="text-wrap">{{
                                                                data.item.occupation
                                                            }}</v-list-item-title>
                                                            <v-list-item-subtitle class="text-wrap">{{
                                                                data.item.category
                                                            }}</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </template>
                                                </v-autocomplete>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                <h5>Family Affluence</h5>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>

                                                <v-autocomplete v-model="filters.family_affluence" outlined clearable
                                                    :items="family_affluence" single-line multiple small-chips full-width>

                                                </v-autocomplete>

                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-col>
                                
                                <v-col lg="12" cols="12" sm="12" xs="12" class="ma-auto">
                                    <v-btn  color="orange" class="elevation-4 font-weight-bold white--text ma-auto  ml-3 mr-3"
                                        @click="searchUsers" :disabled="filter_loader" :loading="filter_loader">Apply
                                        Filters</v-btn>
                                        <v-btn  color="red" class="elevation-4 font-weight-bold white--text ma-auto  ml-3 mr-3"
                                        @click="removeFilters" :disabled="filter_loader" :loading="filter_loader">Remove
                                        Filters</v-btn>
                                </v-col>

                            </v-row>
                        </v-col>
                        <v-col lg="7" cols="12" sm="12" md="8">
                            <v-row>
                                <v-col lg="12" cols="12" sm="12">
                                    <h4 class="">Search Members</h4>
                                    <v-divider></v-divider>
                                </v-col>

                                <v-col lg="12" cols="12" sm="12" class="pa-0 ma-0">
                                    <v-skeleton-loader class="mx-auto" type="card-heading,image,paragraph,actions" v-if="filter_loader"></v-skeleton-loader>
                                    <ProfileCard v-for="user in users" :key="user.profile_id" :user="user" v-else></ProfileCard>
                                </v-col>
                                <v-col lg="12" cols="12" sm="12">
                                    <div class="text-center">
                                        <v-pagination :length="total_pages" circle color="red" v-model="current_page"
                                            @input="updatePage"></v-pagination>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import CustomAppbar from '@/components/Global/CustomAppbar.vue';
import ProfileCard from '@/components/widgets/ProfileCard.vue';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
export default {
    watch: {
        'this.filters.age_from': function (oldvalue) {
            this.getToAge();
        }
    },
    computed: {
        getFromAge: function () {
            let age_list = [];
            for (var i = 21; i < 80; i++) {
                age_list.push(i);
            }
            return age_list;
        },
        getToAge: function () {
            let to_age_list = [];
            let start_point = 21;
            if(this.filters.age_from !== null){
                start_point = this.filters.age_from
            }
            for (var i = start_point; i < 60; i++) {
                to_age_list.push(i);
            }
            return to_age_list;
        },
        premiumUser() {

            return this.$store.getters['membership/premiumUser'];
        }
    },
    mounted() {

        this.getMembershipStatus();
        this.searchUsers();
    },
    created() {
        this.getAllListData();
        this.getUserBasedCastes();

    },
    methods: {
        getMembershipStatus() {

            if (this.$store.state.auth.isLoggedIn) {

                axios.get(`${process.env.VUE_APP_API_URL}/getmembershipdetails`).then((response) => {
                    if (response.status == 200) {

                        this.$store.dispatch('membership/setMembership', response.data.current_subscription);
                    }
                }).catch((error) => {

                }).finally(() => {

                });
            } else {
                this.$router.push({ name: "Home" })
            }

        },

        getAllListData() {

            axios.get(`${process.env.VUE_APP_API_URL}/getalllistdata`).then((response) => {
                if (response.status == 200) {

                    this.educations = response.data.educations;
                    this.mother_tongues = response.data.mother_tongues;
                    this.occupations = response.data.occupations;
                }
            }).catch((error) => {
            }).finally(() => {

            });
        },
        getUserBasedCastes() {
            axios.get(`${process.env.VUE_APP_API_URL}/getuserbasedcastes`).then((response) => {
                if (response.status == 200) {
                    this.castes = response.data.castes;
                }
            }).catch((error) => {
            }).finally(() => {

            });
        },
        searchUsers() {
            this.filter_loader = true;
            this.pagination.filters = Object.assign({}, this.filters);
            axios.get(`${process.env.VUE_APP_API_URL}/searchusers`, { params: this.pagination }).then((response) => {
                if (response.status == 200) {
                    this.current_page = response.data.meta.current_page;
                    this.total_pages = response.data.meta.last_page;
                    this.users = response.data.data;
                    this.page_size = response.data.meta.per_page;

                }
            }).catch((error) => {
            }).finally(() => {

                this.filter_loader = false;
            });
        },
        removeFilters:function(){
            this.filters.age_from = null;
            this.filters.age_upto = null;
            this.filters.castes = [];
            this.filters.educations = [];
            this.filters.occupations = [];
            this.filters.mother_tongues = [];
            this.filters.family_affluence = null;
            this.filters.profile_id = null;

            this.searchUsers();

        },  
        updatePage: function (pageIndex) {
            if (pageIndex == 0) {
                pageIndex = 1;
            }
            this.pagination.filters = Object.assign({}, this.filters);
            axios.get(`${process.env.VUE_APP_API_URL}/searchusers?page=` + pageIndex, { params: this.pagination }).then((response) => {
                if (response.status == 200) {
                    this.current_page = response.data.meta.current_page;
                    this.total_pages = response.data.meta.last_page;
                    this.users = response.data.data;
                    this.page_size = response.data.meta.per_page;
                }
            }).catch((error) => {
            }).finally(() => {

            });
            this.current_page = pageIndex;

        }
    },
    data: () => ({
        filters: {
            age_from: null,
            age_upto: null,
            castes: [],
            educations: [],
            occupations: [],
            mother_tongues: [],
            family_affluence: null,
            profile_id: '',
        },
        filter_loader: false,
        users: [],
        pagination: {},
        current_page: 1,
        page_size: 10,
        total_pages: 1,
        history_links: [],
        body_type: ["Slim", "Medium", "Athlete", "Heavy"],
        family_value: ["Traditional", "Moderate", "Liberal"],
        religions: [],
        castes: [],
        gotras: [],
        educations: [],
        occupations: [],
        mother_tongues: [],
        annual_income: ["Upto INR 1 Lakh", "INR 1 Lakh to 2 Lakh", "INR 2 Lakh to 4 Lakh", "INR 4 Lakh to 7 Lakh", "INR 7 Lakh to 10 Lakh", "INR 10 Lakh to 15 Lakh", "INR 15 Lakh to 20 Lakh", "INR 20 Lakh to 30 Lakh", "INR 30 Lakh to 50 Lakh", "INR 50 Lakh to 75 Lakh", "INR 75 Lakh to 1 Crore", "INR 1 Crore above", "Not applicable",
        ],
        family_affluence: ["Affluent", "Rich", "Upper Middle Class", "Middle Class"],

    }),
    components: { CustomAppbar, ProfileCard }
}
</script>
<style scoped></style>