<template>
    <div>
        <v-progress-circular indeterminate color="red" v-if="data_loader"></v-progress-circular>
        <div v-else>
            <v-container class="letter-from-ceo">
                <v-row>
                    <v-col lg="5" cols="12" md="6" sm="12">
                        <v-img :src="kesari_logo" height="100" width="150" contain> </v-img>
                        <div class="letter-content" v-html="ceo_letter.body">

                        </div>
                    </v-col>
                    <v-col lg="6" class="pl-md-13 d-non d-sm-flex" cols="12" md="6" sm="12">
                        <v-row>
                            <v-col lg="6"
                                :class="{ 'pt-14': $vuetify.breakpoint.mdAndUp, 'pt-5': $vuetify.breakpoint.mdAndDown }"
                                cols="6" md="3" sm="6">
                                <v-img :src="ceo_images[0]['uri']" :height="imageHeight" width="400"
                                    class="rounded-lg elevation-20"></v-img>
                            </v-col>
                            <v-col lg="6" cols="6">
                                <v-row>
                                    <v-col lg="12" class="" cols="12">
                                        <v-img class="rounded-lg elevation-15" :height="imageHeightOne" width="500"
                                            :src="ceo_images[1]['uri']"></v-img>
                                    </v-col>
                                    <v-col lg="12" class="" cols="12">
                                        <v-img class="rounded-lg elevation-15" :height="imageHeightOne" width="500"
                                        :src="ceo_images[2]['uri']"></v-img>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
            <v-container fluid class="about-us-section mb-10 mt-14">
                <v-container>
                    <v-row>
                        <v-col lg="6" class="pl-md-13 d-none d-md-block" cols="12" md="6" sm="12">
                            <v-row>
                                <v-col lg="6"
                                    :class="{ 'pt-14': $vuetify.breakpoint.mdAndUp, 'pt-5': $vuetify.breakpoint.mdAndDown }"
                                    cols="6" md="3" sm="6">
                                    <v-img :src="kesari_images[0]['uri']" :height="imageHeight" width="400"
                                        class="rounded-lg elevation-20"></v-img>
                                </v-col>
                                <v-col lg="6" cols="6">
                                    <v-row>
                                        <v-col lg="12" class="" cols="12">
                                            <v-img class="rounded-lg elevation-15" :height="imageHeightOne" width="500"
                                            :src="kesari_images[1]['uri']"></v-img>
                                        </v-col>
                                        <v-col lg="12" class="" cols="12">
                                            <v-img class="rounded-lg elevation-15" :height="imageHeightOne" width="500"
                                            :src="kesari_images[2]['uri']"></v-img>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col lg="5" class="pl-lg-14">
                            <div>
                                <h3>About <span class="brand-title">Kesari</span>&nbsp;&nbsp;Matrimony</h3>
                               
                            </div>
                            <div class="brand-content" v-html="about_kesari.body">

                            </div>

                        </v-col>

                    </v-row>
                </v-container>
            </v-container>
        </div>
        <FooterSectionVue></FooterSectionVue>
    </div>
</template>
<script>
import FooterSectionVue from '@/components/Global/FooterSection.vue'
import axios from 'axios';
export default {
    computed: {
        imageHeight() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs": return "400px";
                case "sm": return "400px";
                case "md": return "600px";
                case "lg": return "800px";
                case "xl": return "800px";
                default: return "800px";
            }
        },
        imageHeightOne() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs": return "200px";
                case "sm": return "200px";
                case "md": return "300px";
                case "lg": return "400px";
                case "xl": return "400px";
                default: return "400px";
            }
        },
    },
    mounted() {
        this.getAboutUsPageContent();
    },
    methods: {
        getAboutUsPageContent: function () {
            this.data_loader = true;
            axios.get(`${process.env.VUE_APP_API_URL}/getaboutuspagecontent`).then((response) => {
                if (response.status == 200) {
                    this.ceo_letter = response.data.ceo_letter;
                    this.about_kesari = response.data.about_kesari;
                    this.ceo_images = response.data.ceo_images;
                    this.kesari_images = response.data.kesari_images;
                }
            }).catch((error) => {
                if (error.response.status == 422) {

                    this.message = "Error Occur While Getting About Us Page Content"
                    this.display_snackbar = true
                }
            }).finally(() => {
                this.data_loader = false;
            });
        },
    },
    data: () => ({
        data_loader: false,
        about_us_close_up: require("@/assets/images/about-us-close-up.jpg"),
        kesari_logo: require("@/assets/images/kesari_logo.png"),
        ceo_images:[],
        kesari_images:[],
        ceo_letter: {
            id: '',
            page: 'about_us',
            module: '',
            title: '',
            body: '',
            is_publish: false,
        },
        about_kesari: {
            id: '',
            page: 'about_us',
            module: '',
            title: '',
            body: '',
            is_publish: false,
        }
    }),
    components: { FooterSectionVue }
}
</script>
<style scoped></style>