<template>
  <div class="indexer">
    <v-app-bar class="c-header pa-0 ma-0" elevation="3" elevate-on-scroll app extension-height="60" height="75">
      <v-app-bar-nav-icon @click.stop="nav_drawer = !nav_drawer"
        v-if="$vuetify.breakpoint.smAndDown"></v-app-bar-nav-icon>
        <router-link :to="{ name: 'Home' }">
          <div  v-if="$vuetify.breakpoint.mdAndUp">
            <v-img :src="kesari_logo" height="40" contain max-height="35" max-width="200" class="mt-2" >
            </v-img>
          </div>
          <div v-else>
            <v-img :src="kesari_logo" height="40" contain max-height="35" max-width="200" class=" ma-auto mt-4 pa-0 "
        >  </v-img>
          </div>  
        </router-link>
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
      <div class="c-nav-items text-right" v-if="$vuetify.breakpoint.mdAndUp">
        <router-link :to="{ name: 'Home' }">
          <v-btn text link dense class="c-nav-item">
            Home
          </v-btn>
        </router-link>
        <router-link :to="{ name: 'AboutUs' }">
          <v-btn text link dense class="c-nav-item">
            About
          </v-btn>
        </router-link>
        <router-link :to="{ name: 'KesariElite' }">
          <v-btn text link dense class="c-nav-item">
            Go Elite
          </v-btn>
        </router-link>
        <router-link :to="{ name: 'Blogs' }">
          <v-btn text link dense class="c-nav-item">
            Blog
          </v-btn>
        </router-link>
        <router-link :to="{ name: 'ContactUs' }">
          <v-btn text link dense class="c-nav-item">
            Contact Us
          </v-btn>
        </router-link>
        <v-btn text link dense class="c-nav-item" v-if="isLoggedIn" @click="$emit('signOutUser')">
          Sign Out
        </v-btn>
        <v-btn   class="pa-4 text-capitalize transparent font-weight-bold red--text text--darken-4"  text link v-if="isLoggedIn">
          {{ authUser.first_name + " " + authUser.last_name }}<br>
          {{ authUser.profile_id }}
        </v-btn>
        <v-btn text link dense class="c-nav-item" v-else @click="$emit('signInUser')">
          Sign In
        </v-btn>
      </div>
      <template #extension v-if="isLoggedIn && $vuetify.breakpoint.mdAndUp">
        <v-toolbar class="pa-0 elevation-0">
          <div class="c-nav-items-session text-center">
            <router-link v-for="link in session_routes" :key="link.session_routes" :to="{ name: link.route_name }">
              <v-btn text link dense class="c-nav-item">
                <v-icon small color="#6f2863" class="pr-1">{{ link.icon }}</v-icon>
                {{ link.title }}
              </v-btn>
            </router-link>
          </div>
        </v-toolbar>
      </template>
    </v-app-bar>
    <v-navigation-drawer v-model="nav_drawer" absolute temporary>
      <v-img :src="kesari_logo" height="40" contain max-height="80" max-width="200" class=" ma-auto mt-5 mb-1"
        ></v-img>
     
      <v-divider></v-divider>

      <v-list dense v-if="isLoggedIn">
        <v-list-item v-for="link in session_routes" :key="link.title" link
          @click="$router.push({ name: link.route_name }).catch(() => { })">
          <v-list-item-icon>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ link.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list dense class="mt-10">
        <v-list-item v-for="link in mobile_routes" :key="link.title" link
          @click="$router.push({ name: link.route_name }).catch(() => { })">
          <v-list-item-icon>

          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ link.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      
      <v-btn color="red" tile  block class="white--text" v-if="isLoggedIn" @click="$emit('signOutUser')">
          Sign Out
        </v-btn>
        <v-btn   class="pa-4 text-capitalize red white--text font-weight-bold" v-if="isLoggedIn">
          {{ authUser.first_name + " " + authUser.last_name }}<br>
          {{ authUser.profile_id }}
        </v-btn>
        <v-btn color="red" tile block class="white--text" v-else @click="$emit('signInUser')">
          Sign In
        </v-btn>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {

  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
      user:'auth/user',
            premiumUser:'membership/premiumUser',
            planPrice:'membership/price',
    }),
        authUser(){
          if(this.$store.getters['auth/isLoggedIn']){
            return this.$store.getters['auth/user'];
          }
           else{
            return { first_name:"",last_name:"",profile_id:""};
           }
        },
  },
  props: ['sign_out', 'sign_in'],
  data: () => ({
    kesari_logo: require('@/assets/images/kesari_logo.png'),
    session_routes: [
    { title: "Search Users", icon: "mdi-account-search", route_name: 'searchMember' },
      { title: "My Profile", icon: "mdi-account-circle", route_name: 'MyProfile' },
      { title: "Profile Images", icon: "mdi-image", route_name: 'MyImages' },
      { title: "Upload Documents", icon: "mdi-file", route_name: 'MyDocuments' },
      { title: "Viewed Profiles", icon: "mdi-account-clock-outline", route_name: 'MyViewedProfiles' },
      { title: "Shortlist Profiles", icon: "mdi-format-list-checks", route_name: 'MyShortlists' },
      { title: "My Interest", icon: "mdi-hand-heart-outline", route_name: 'MyInterests' },
      { title: "Invitation Request", icon: "mdi-tag-heart", route_name: 'MyInvitations' },
      { title: "Subscription", icon: "mdi-wallet-membership", route_name: 'MySubscriptions' },
      { title: "Subscription Plans", icon: "mdi-wallet-membership", route_name: 'SessionSubscriptionPlans' },
      { title: "Account Settings", icon: "mdi-cog-outline", route_name: 'MyAccountSetting' },
    ],
    mobile_routes: [
      { title: "Home", route_name: 'Home' },
      { title: "About Us", route_name: 'AboutUs' },
      { title: "Go Elite", route_name: 'KesariElite' },
      { title: "Blogs", route_name: 'Blogs' },
      { title: "Contact Us", route_name: 'ContactUs' },

    ],
    nav_drawer: false,
  }),

}
</script>
<style>
.indexer{
  z-index: 998 !important;
}
a {
  text-decoration: none !important;
  
}
</style>
