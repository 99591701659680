<template>
    <section class="member-search-section">
        <v-container fluid class="pa-0 ma-0">
            <Hooper :settings="hoopersettings" style="height:400px;">

                <Slide v-for="i in 15" :key="i" :index="i">
                    <v-img :src="couple_images+i+'.jpg'"
                        class="elevation-10" contain></v-img>
                </Slide>
            </Hooper>
        </v-container>
        <v-container>
            <h2 class="text-center section-title">Start your search for a perfect match at Kesari Matrimony</h2>
            <v-row class="search-form">
                <v-col>
                    <v-select outlined label="I'm Looking For a" :items=select_gender></v-select>
                </v-col>
                <v-col>
                    <v-range-slider v-model="age_range" :max="max_age" :min="min_age" hide-details class="align-center mt-n1" color="#6f2836">
                        <template v-slot:prepend>
                            <v-text-field :value="age_range[0]" class="" hide-details single-line type="number"
                                style="width: 80px" @change="$set(age_range, 0, $event)" outlined></v-text-field>
                        </template>
                        <template v-slot:append>
                            <v-text-field :value="age_range[1]" class="" hide-details single-line type="number"
                                style="width: 80px" @change="$set(age_range, 1, $event)" outlined></v-text-field>
                        </template>
                    </v-range-slider>
                </v-col>
                <v-col>
                    <v-select outlined label="Mother Tongue" :items=mother_tongue></v-select>
                </v-col>
                <v-col>
                    <v-select outlined label="Caste" :items=caste></v-select>
                </v-col>
                <v-col>
                    <v-select outlined label="Subcaste" :items=subcaste></v-select>
                </v-col>
                <v-col>
                    <v-btn class="my-1 white--text rounded-lg elevation-4"  large color="#6f2836">Search</v-btn>
                </v-col>
            </v-row>


        </v-container>

    </section>
</template>
<script>
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
export default {


    mounted() {
        console.log("Search Member Section landed")
    },
    data: () => ({
        select_gender: ['Man', 'Woman'],
        age_range: [18, 80],
        min_age: 18,
        max_age: 80,
        mother_tongue: [],
        caste: [],
        subcaste: [],
        hoopersettings: {
            itemsToShow: 7,
            autoPlay: true,
            playSpeed: 2000,
            infiniteScroll: true,
        },
        couple_images:"https://www.k4fashion.com/wp-content/uploads/2021/12/nauvari-marathi-wedding-dress-for-couple-",
    }),
    components: {
        Hooper,
        Slide
    }

}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Playfair+Display:wght@400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

.member-search-section {
    height: 60vh !important;
    background-color: #fdf5e8;
    padding: 0% !important;
    margin: 0% !important;
}

.section-title {
    padding-top: 3.5%;
    font-family: 'Poppins', roboto !important;
    color: #6f2836 !important;
    font-weight: 600 !important;
    font-size: 1.5rem !important;
}

.member-search-section .search-form {
    padding-top: 5.4% !important;
}
</style>