<template>
    <section class="happy-couples-sections">
        <v-container>
            <v-row>
                <v-col lg="12" xs="12" sm="12" md="12" cols="12" class="text-center ">
                    <h1 class="title">{{ marriage_by_kesari.title }}</h1>
                    <h4 class="sub-title">{{ marriage_by_kesari.caption }}</h4>
                </v-col>
                <v-col lg="12" xs="12" sm="12" md="12" class="couples-cards">
                    <!-- <img class="mandala-three  rotating" :src="mandala_img" /> -->
                    
                        <Hooper :settings="hoopersettings" style="height:400px;">

                            <Slide v-for="(couple,index) in couples" :key="index" :index="index">
                                <v-col>
                                    <v-card class="couple-card elevation-4">
                                        <v-card-text class="text-center">
                                            <v-img
                                                :src="couple.uri" contain
                                                class="couple-image elevation-0"></v-img>
                                        </v-card-text>
                                        <v-card-text class="text-center">
                                            <span class="couple-name">{{ couple.alternative_text }}</span>
                                        </v-card-text>
                                        <!-- <v-card-text class="text-center couple-short-description justify-content">
                                            <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis,
                                                molestiae!
                                                Commodi <a>Read More....</a></span>
                                        </v-card-text> -->
                                    </v-card>
                                </v-col>
                            </Slide>
                        </Hooper>
                    
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';

export default {
    props:{
        couples:Array,
        marriage_by_kesari:Object,
    },
    mounted() {
       
    },
    data: () => ({
        mandala_img: require('@/assets/images/mandala.png'),
        hoopersettings: {
            itemsToShow: 5,
            autoPlay:true,
            playSpeed:2000,
            infiniteScroll:true,
            breakpoints: {
                400: {
                    centerMode: true,
                    itemsToShow: 1
                },
                500: {
                    centerMode: true,
                    itemsToShow: 1
                },
                600: {
                    itemsToShow: 2,
                    centerMode: true,

                },
                700: {
                    itemsToShow: 2,
                    centerMode: true,
                },
                800: {
                    itemsToShow: 2,
                    centerMode: false,
                },
                900: {
                    itemsToShow: 3,
                    centerMode: false,
                },
                1000: {
                    itemsToShow: 3,
                    centerMode: false,
                },
                1200: {
                    itemsToShow: 4,
                    centerMode: false,
                }
            }
        }

    }),
    components: {
        Hooper,
        Slide
    }

}

</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Playfair+Display:wght@400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&family=Great+Vibes&family=Playfair+Display:ital,wght@0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.happy-couples-sections {
    position: 100% !important;
    padding-top: 3.4%;
    overflow: hidden !important;
    display: block !important;
    /* background-image: linear-gradient(-180deg, #f1b092 0%, #fdf5e8 100%); */
}

.happy-couples-sections .title {
    padding-top: 5%;
    
    /* font-family: 'Great Vibes', cursive !important; */
    font-size: 3.5rem !important;
    font-weight: 600 !important;
    margin: auto !important;
    text-align: center !important;
    text-shadow: 3px 3px 4px #ffafbd !important;
    color: #DC0000;
}

.happy-couples-sections .sub-title {
    padding-top: 2.5%;
    padding-left: 0.5%;
    font-family: 'Poppins', roboto !important;
    font-size: 1.2rem !important;
    font-weight: 400 !important;
    margin: auto !important;
    text-shadow: 3px 3px 4px #ffafbd !important;
    color: #DC0000;
}


.couples-cards {
    padding-top: 5% !important;
    z-index: 99 !important;
}

.couple-card {
    width: 300px !important;
    min-height: 350px !important;
    border-radius: 20px !important;
    padding: 10px !important;
    background-color: #fdf5e8 !important;
    z-index: 999 !important ;
    box-shadow: #f1b092 0px 1px 15px !important;
}

.couple-image {
    border-radius: 5% !important;
    /* background-position: center center !important; */
    max-height: 250px !important;
    /* background-color: #fff !important; */
}

.couple-name {

    padding-top: 1% !important;
    font-family: 'Comfortaa', roboto !important;
    font-size: 1.0rem !important;
    font-weight: 600 !important;
    margin: auto !important;
    line-height: 1.0em !important;
    color: #6f2836 !important;
}

.couple-short-description {
    font-family: 'Comfortaa', roboto !important;
    color: #6f2836 !important;
    font-weight: 400 !important;
    font-size: 0.8rem !important;

}

.couple-short-description a {
    color: #6f2836 !important;
    font-weight: 800 !important;
    font-size: 0.8rem !important;
    text-decoration: underline !important;
}

@media only screen and (max-width: 960px) and (min-width: 600px) {

    
.happy-couples-sections .title {
    padding-top: 10%;
    /* font-family: 'Great Vibes', cursive !important; */
    font-size: 2.0rem !important;
    font-weight: 600 !important;
    line-height: 2.0rem !important;
    margin: auto !important;
    text-align: center !important;
    display: block !important;
    text-shadow: 3px 3px 4px #ffafbd !important;
    color: #DC0000;
}
.happy-couples-sections .sub-title {
    padding-top: 2.5%;
    padding-left: 0.5%;
    font-family: 'Poppins', roboto !important;
    font-size: 1.2rem !important;
    font-weight: 400 !important;
    margin: auto !important;
    text-shadow: 3px 3px 4px #ffafbd !important;
    color: #DC0000;
}
}
@media only screen and (max-width: 1264px) and (min-width: 960px) {   
.happy-couples-sections .title {
    text-align: center !important;
    padding-top: 10%;
    /* font-family: 'Great Vibes', cursive !important; */
    font-size: 2.5rem !important;
    font-weight: 600 !important;
    line-height: 2.0rem !important;
    margin: auto !important;
    text-shadow: 3px 3px 4px #ffafbd !important;
    color: #DC0000;
}

.happy-couples-sections .sub-title {
    padding-top: 2.5%;
    padding-left: 0.5%;
    font-family: 'Poppins', roboto !important;
    font-size: 1.4rem !important;
    font-weight: 400 !important;
    margin: auto !important;
    text-shadow: 3px 3px 4px #ffafbd !important;
    color: #DC0000;
}
}
@media only screen and (max-width: 600px) {

.happy-couples-sections {
}    
.happy-couples-sections .title {
    padding-top: 10%;
    font-size: 2.0rem !important;
    font-weight: 600 !important;
    line-height: 2.0rem !important;
    text-shadow: 3px 3px 4px #ffafbd !important;
    color: #DC0000;
    margin: auto !important;

}

.happy-couples-sections .sub-title {
    padding-top: 1.5%;
    padding-left: 0.5%;
    font-family: 'Poppins', roboto !important;
    font-size: 1.0rem !important;
    font-weight: 400 !important;
    
    text-shadow: 3px 3px 4px #ffafbd !important;
    color: #DC0000;
    margin: auto !important;
  
}
}
</style>