<template>
    <div>
        <section>
            <v-card class="pa-5 pl-1 pr-1 pb-5 rounded-lg ma-auto search_form hero_section elevation-0 " min-width="400">
                <v-card-text class="text-center">
                    <h4>{{ hero_section.title  }}</h4>
                    <h4><span class="hero_section_caption">{{ hero_section.caption  }}</span></h4>
                    
                </v-card-text>
                <v-card-text class="mt-0 mb-0 pb-0 elevation-0">
                    <v-row>
                        <v-col lg="2" sm="12" md="4" cols="12" class="pa-lg-2 pt-xs-0 pb-0 mb-0" >
                            I'm Looking for
                            <v-select outlined dense :items="genders" item-text="text" item-value="id"
                                v-model="search_user.gender"></v-select>
                        </v-col>
                        <v-col lg="3" sm="12" md="4" class="pa-3 pt-xs-0 pb-0 mb-0" cols="12">
                            <v-row>
                                <v-col class="pl-2 pr-2" cols="6" lg="6">
                                    Age From
                                    <v-select :items="ageFrom" v-model="search_user.age_from" outlined dense></v-select>
                                </v-col>
                                <v-col class="pl-2 pr-2 pb-0 mb-0" cols="6" lg="6">
                                    Age Upto
                                    <v-select :items="ageUpto" v-model="search_user.age_upto" outlined dense></v-select>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col lg="3" class="pa-lg-3 pt-xs-0 pb-0 mb-0" sm="6" md="2" cols="6">
                            of Religion
                            <v-select :items="religions" outlined dense :loading="loaders.religion_loader"
                                v-model="search_user.religion"></v-select>
                        </v-col>
                        <v-col lg="2" class="pa-lg-3 pt-xs-0 pb-0 mb-0" sm="6" md="2" cols="6">
                            and Mother Tongue
                            <v-select outlined dense :items="mother_tongues" :loading="loaders.mother_tongue_loader"
                                v-model="search_user.mother_tongue"></v-select>
                        </v-col>
                        <v-col lg="2" :class="{ 'pt-8': $vuetify.breakpoint.mdAndUp } " sm="12" md="12" cols="12">
                            <v-btn block color="orange darken-2 pt-1" elevation="4" @click="memberSearch">Let's
                                Begin</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
           
        </section>
       <section  class="banner_sliders">
        <vueper-slides>
                <!-- eslint-disable -->
                <vueper-slide v-for="(banner, i) in current_banners" :key="i" :image="banner.uri" v-if="banner.is_active" 
                    class="">

                </vueper-slide>
            </vueper-slides>
        </section>
        <section class="kesari_intro_section" v-if="kesari_intro.is_published">
            <v-container>
                <v-row>
                    <v-col lg="6" md="6" sm="12" cols="12" class="kesari_intro_content">
                       
                            <h3 class="pb-0 mb-0" style="color: #6f2863 !important;">{{ kesari_intro.title }}</h3>
                            <!-- <h1 class="pt-0 mt-0" style="color: #6f2863 !important;">-</h1> -->
                        <p v-html="kesari_intro.body"></p>

                    </v-col>
                    <v-col lg="6" md="6" sm="12" cols="12">
                        <v-img :src="why_kesari_banner.uri" class="kesari_intro_img"></v-img>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <section>
          <HappyCouples :couples="happy_couples" :marriage_by_kesari="marriage_by_kesari" v-if="marriage_by_kesari.is_published"></HappyCouples>
        </section>
        <v-container fluid class="mb-14 pb-14">
            <HowItWorks></HowItWorks>
        </v-container>
       
        <section class="why-choose-us">
            <div class="banner">
                <v-card max-width="1500" flat tile class="elevation-0 ma-auto">
                    <v-divider></v-divider>
                    <v-card-text class="pt-4 pb-4">
                        <v-row>
                            <v-col cols="12" sm="12" md="5" lg="4">
                                <v-list-item>
                                    <v-list-item-icon>
                                        <v-img :src="best_matches" height="60" width="60" class="ma-2 pa-3"></v-img>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="pl-3 wcu-header">Best Matches</v-list-item-title>
                                        <v-list-item-subtitle class="pl-3 wcu-sub-header">Elevate your tradition with
                                            compatible match</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="12" md="5" lg="4">
                                <v-list-item>
                                    <v-list-item-icon>
                                        <v-img :src="verified_profile" height="60" width="60" class="ma-2 pa-3"></v-img>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="pl-3 wcu-header">Verified Profiles</v-list-item-title>
                                        <v-list-item-subtitle class="pl-3 wcu-sub-header">Trustworthy, verified profiles
                                            for secure matchmaking</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="12" md="5" lg="4" class="ma-md-auto">
                                <v-list-item>
                                    <v-list-item-icon>
                                        <v-img :src="privacy_img" height="60" width="60" class="ma-2 pa-3"></v-img>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="pl-3 wcu-header">100% Privacy</v-list-item-title>
                                        <v-list-item-subtitle class="pl-3 wcu-sub-header">Safe and secure matchmaking
                                            with privacy</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider></v-divider>
                </v-card>
            </div>
        </section>
        <FooterSection></FooterSection>
        <!--- dialogs--->
        <!--- User onboarding signup form-->
        <v-dialog v-model="models.sign_up" max-width="500">

            <v-window v-model="models.user_onboarding" touchless>
                <ValidationObserver v-slot="{ handleSubmit }">
                    <v-form @submit.prevent="handleSubmit(signUpUser)">
                        <v-window-item :value="1">
                            <v-card class="pa-10 pt-3">
                                <v-card-title>
                                    <v-img :src="user_profile" class="d-flex justify-center" height="60" contain
                                        width="60"></v-img>
                                    <v-icon @click="models.sign_up = false" small class="">mdi-close</v-icon>
                                </v-card-title>
                                <v-card-text class="mt-5">
                                    <h3>This Profile is for </h3>
                                    <v-chip-group v-model="new_user.created_for" max="1" column class="pt-2 ma-auto mr-5"
                                        :change="validateProfileContinuation()">
                                        <v-chip filter class="elevation-2" color="primary"
                                            v-for="(item, i) in profile_creation_for" :value="item.text" :key="i">
                                            <span>{{ item.text }}</span>
                                        </v-chip>
                                    </v-chip-group>
                                </v-card-text>
                                <v-expand-transition>
                                    <v-card-text v-if="new_user.created_for == 'My Self'" class="mt-4">
                                        <h3>Gender</h3>
                                        <v-chip-group v-model="new_user.user_gender" max="1" column class="pt-2"
                                            :change="validateProfileContinuation()">
                                            <v-chip filter class="elevation-3" color="primary" value="Male">
                                                <span>Male</span>
                                            </v-chip>
                                            <v-chip filter class="elevation-3" color="primary" value="Female">
                                                <span>Female</span>
                                            </v-chip>
                                        </v-chip-group>
                                    </v-card-text>
                                </v-expand-transition>

                                <v-card-actions class="text-center">
                                    <v-btn class="pa-4 pl-10 pr-10 ma-auto" color="success" rounded
                                        @click="models.user_onboarding = 2" :disabled="disabled_continue_button">
                                        Continue
                                    </v-btn>

                                </v-card-actions>
                                <v-card-text class="text-center">
                                    <v-btn class="pa-4 mb-5 text-center text-capitalize text-caption" plain text
                                        color="primary" link @click="models.sign_in = true; models.sign_up = false; ">
                                        Sign In Instead ?
                                    </v-btn>
                                </v-card-text>

                            </v-card>
                        </v-window-item>

                        <v-window-item :value="2">
                            <ValidationObserver v-if="models.user_onboarding === 2">
                                <v-card class="pa-10 pt-3">
                                    <v-card-title>
                                        <v-icon @click="models.user_onboarding = 1" small class="">mdi-arrow-left</v-icon>
                                        <v-img :src="user_name" class="d-flex justify-center" height="60" contain
                                            width="60"></v-img>
                                        <v-icon @click="models.sign_up = false" small class="">mdi-close</v-icon>
                                    </v-card-title>
                                    <v-card-text class="mt-5">
                                        <h3 class="mb-3">Your Details </h3>
                                        <v-row>

                                            <v-col lg="12">
                                                <ValidationProvider
                                                    :name="new_user.user_gender === 'Male' ? 'Groom First Name' : 'Bride First Name'"
                                                    v-slot="{ errors }" persist rules="required|max:50|alpha">
                                                    <v-text-field outlined
                                                        :label="new_user.user_gender == 'Male' ? 'Groom First Name' : 'Bride First Name'"
                                                        v-model="new_user.first_name"
                                                        :error-messages="errors"></v-text-field>
                                                </ValidationProvider>
                                                <ValidationProvider
                                                    :name="new_user.user_gender === 'Male' ? 'Groom Last Name' : 'Bride Last Name'"
                                                    v-slot="{ errors }" persist rules="required|max:50|alpha">
                                                    <v-text-field outlined
                                                        :label="new_user.user_gender == 'Male' ? 'Groom Last Name' : 'Bride Last Name'"
                                                        v-model="new_user.last_name"
                                                        :error-messages="errors"></v-text-field>
                                                </ValidationProvider>
                                                <v-dialog ref="dialog" v-model="models.date_picker"
                                                    :return-value.sync="new_user.date_of_birth" persistent width="290px">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <ValidationProvider name="Date of Birth" v-slot="{ errors }" persist
                                                            rules="required">
                                                            <v-text-field outlined v-model="new_user.date_of_birth"
                                                                label="Date of Birth" readonly v-bind="attrs" v-on="on"
                                                                :error-messages="errors"></v-text-field>
                                                        </ValidationProvider>
                                                    </template>
                                                    <v-date-picker color="orange" v-model="new_user.date_of_birth"
                                                        :min="getMinDate" :max="getMaxDate" scrollable>
                                                        <v-spacer></v-spacer>
                                                        <v-btn text color="orange" @click="models.date_picker = false">
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn text color="orange"
                                                            @click="$refs.dialog.save(new_user.date_of_birth)">
                                                            OK
                                                        </v-btn>
                                                    </v-date-picker>
                                                </v-dialog>

                                            </v-col>

                                        </v-row>
                                    </v-card-text>
                                    <v-card-actions class="text-center">
                                        <v-btn class="pa-4 pl-10 pr-10 ma-auto" color="success" rounded type="submit">
                                            Continue
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </ValidationObserver>
                        </v-window-item>


                        <v-window-item :value="3">
                            <ValidationObserver v-if="models.user_onboarding === 3">
                                <v-card class="pa-10 pt-3">
                                    <v-card-title>
                                        <v-icon @click="models.user_onboarding = 2" small class="">mdi-arrow-left</v-icon>
                                        <v-img :src="user_email" class="d-flex justify-center" height="60" contain
                                            width="60"></v-img>
                                        <v-icon @click="models.sign_up = false" small class="">mdi-close</v-icon>
                                    </v-card-title>
                                    <v-card-text class="text-center">
                                        <span class="caption ma-auto">An active email address & phone no.<br>are
                                            required to secure
                                            your Profile</span>
                                    </v-card-text>
                                    <v-card-text class="mt-5">
                                        <v-row>

                                            <v-col lg="12" cols="12">
                                                <ValidationProvider name="Religion" v-slot="{ errors }" persist
                                                    rules="required">
                                                    <v-select outlined label="Religion" v-model="new_user.religion"
                                                        :items="religions" :error-messages="errors" item-value="text">
                                                    </v-select>
                                                </ValidationProvider>
                                                <ValidationProvider name="Email Address" v-slot="{ errors }" persist
                                                    rules="required|email">
                                                    <v-text-field outlined label="Email Address"
                                                        v-model="new_user.email_address"
                                                        :error-messages="errors"></v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col lg="4" cols="12">
                                                <ValidationProvider name="Country" v-slot="{ errors }" persist
                                                    rules="required">
                                                    <v-select outlined label="Country" v-model="new_user.country_code"
                                                        :items="country_codes" :error-messages="errors"
                                                        item-value="dial_code">
                                                        <template slot="item" slot-scope="data">
                                                            {{ data.item.dial_code }} - {{ data.item.name }}
                                                        </template>
                                                        <template slot="selection" slot-scope="data">
                                                            {{ data.item.dial_code }}
                                                        </template>

                                                    </v-select>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col lg="8" cols="12">
                                                <ValidationProvider name="Country" v-slot="{ errors }" persist
                                                    rules="required|numeric|digits:10">
                                                    <v-text-field outlined label="Phone Number"
                                                        v-model="new_user.mobile_number"
                                                        :error-messages="errors"></v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col lg="12" cols="12">
                                                <v-row>
                                                    <v-col cols="2">
                                                        <v-checkbox label="" hide-details class="pa-0 ma-0"
                                                            v-model="tnc_accepted"></v-checkbox>
                                                    </v-col>
                                                    <v-col cols="9">
                                                        <p> I agree To Kesari Matrimony <a href="/terms-and-conditions"
                                                                target="_blank">Terms and
                                                                Condition</a>
                                                            &<span>&nbsp; <a href="/privacy-policies"
                                                                    target="_blank">Privacy
                                                                    Policy</a></span></p>
                                                    </v-col>
                                                </v-row>
                                            </v-col>

                                        </v-row>
                                    </v-card-text>
                                    <v-card-text>
                                        <v-alert type="error" v-if="user_exists_mesg">
                                            {{ user_exists_mesg }}
                                        </v-alert>
                                    </v-card-text>
                                    <v-card-actions class="text-center">
                                        <v-btn class="pa-4 pl-10 pr-10 ma-auto" color="success" rounded
                                            :loading="loaders.send_otp_loader" :disabled="!tnc_accepted" type="submit">
                                            Continue
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </ValidationObserver>
                        </v-window-item>
                        <v-window-item :value="4">
                            <ValidationObserver v-if="models.user_onboarding === 4">
                                <v-card class="pa-10 pt-3">
                                    <v-card-title>
                                        <v-icon @click="models.user_onboarding = 3" small class="">mdi-arrow-left</v-icon>
                                        <v-img :src="user_otp" class="d-flex justify-center" height="60" contain
                                            width="60"></v-img>
                                        <v-icon @click="models.sign_up = false" small class="">mdi-close</v-icon>
                                    </v-card-title>
                                    <v-card-text class="text-center">
                                        <span class="caption ma-auto">Please enter OTP recieved on Phone no. or
                                            Email<br>to complete
                                            your profile</span>
                                    </v-card-text>
                                    <v-card-text class="mt-5">
                                        <v-row>
                                            <v-col lg="12" cols="12">
                                                <ValidationProvider name="OTP" rules="required|numeric|digits:6"
                                                    v-slot="{ errors }">
                                                    <v-otp-input length="6" v-model="new_user.otp" label="OTP"
                                                        :error-messages="errors"></v-otp-input>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col>
                                                <span class="text-center"></span>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-text>
                                        <v-alert type="error" v-if="sign_up_otp_error_msg">
                                            {{ sign_up_otp_error_msg }}
                                        </v-alert>
                                    </v-card-text>
                                    <v-card-actions class="text-center">
                                        <v-btn class="pa-4 pl-10 pr-10 ma-auto" color="success" rounded type="submit">
                                            Submit
                                        </v-btn>
                                    </v-card-actions>
                                    <!-- snackbar-->
                                    <v-alert dismissible :value="models.otp_snackbar" color="green accent-4" elevation="2"
                                        outlined class="mt-10">
                                        OTP Send Sucessfully
                                    </v-alert>
                                </v-card>
                            </ValidationObserver>
                        </v-window-item>

                    </v-form>
                </ValidationObserver>
            </v-window>
        </v-dialog>

        <!-- user sign in form -->
        <v-dialog v-model="models.sign_in" max-width="400">
            <v-window v-model="models.user_sing_in" touchless>
                <v-form @submit.prevent="login">
                    <v-window-item :value="1">
                        <v-card>
                            <ValidationObserver ref="verifyuserform" v-slot="{ handleSubmit }">
                                <v-form @submit.prevent="handleSubmit(verifyUser)">
                                    <v-card-title>
                                        <v-img :src="user_otp" class="d-flex justify-center mt-3" height="60" contain
                                            width="60"></v-img>
                                        <v-icon @click="models.sign_in = false" small class="">mdi-close</v-icon>
                                    </v-card-title>
                                    <v-card-text class="text-center">
                                        <span class="caption ma-auto">Please enter registered Mobile Number without
                                            country code<br>to
                                            Login</span>
                                    </v-card-text>
                                    <v-card-text>
                                        <v-col lg="12">
                                            <ValidationProvider name="Mobile Number" rules="required|numeric"
                                                v-slot="{ errors }">
                                                <v-text-field outlined label="Mobile Number" v-model="user_auth.username"
                                                    :error-messages="errors">
                                                </v-text-field>
                                            </ValidationProvider>

                                        </v-col>
                                    </v-card-text>
                                    <v-card-text>
                                        <v-alert type="error" v-if="otp_response">
                                            {{ otp_response }}
                                        </v-alert>
                                    </v-card-text>
                                    <v-card-actions class="">
                                        <v-btn class="pa-4 ma-auto mb-0" rounded color="primary" type="submit"
                                            :loading="loaders.verify_user_loader">
                                            Send OTP
                                        </v-btn>
                                    </v-card-actions>
                                    <v-card-actions class="mt-0 pt-0">
                                        <v-btn class="pa-4 ma-auto mb-5 mt-0  text-capitalize text-caption" plain text
                                            color="primary" link @click="models.sign_in = false; models.sign_up = true; ">
                                            Sign Up Instead ?
                                        </v-btn>
                                    </v-card-actions>
                                </v-form>
                            </ValidationObserver>
                        </v-card>
                    </v-window-item>
                    <v-window-item :value="2">
                        <v-card>
                            <ValidationObserver ref="submitsignotp" v-slot="{ handleSubmit }">
                                <v-form @submit.prevent="handleSubmit(login)"></v-form>
                                <v-card-title>
                                    <v-icon @click="models.user_sing_in = 1" small class="">mdi-arrow-left</v-icon>
                                    <v-img :src="user_password" class="d-flex justify-center" height="60" contain
                                        width="60"></v-img>
                                    <v-icon @click="models.sign_in = false" small class="">mdi-close</v-icon>
                                </v-card-title>
                                <v-card-text class="text-center">
                                    <span class="caption ma-auto">Please enter OTP recieved on registered Phone no. or
                                        Email
                                        Address<br>to Login</span>
                                </v-card-text>
                                <v-card-text>
                                    <v-col lg="12">
                                        <v-col lg="12">
                                            <ValidationProvider name="OTP" rules="required|numeric" v-slot="{ errors }">
                                                <v-otp-input length="6" v-model="user_auth.otp" label="OTP"
                                                    :error-messages="errors"></v-otp-input>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-col>
                                </v-card-text>
                                <v-card-text>
                                    <v-alert type="error" v-if="otp_error_msg">
                                        {{ otp_error_msg }}
                                    </v-alert>
                                </v-card-text>
                                <v-card-actions class="">
                                    <v-btn class="pa-4 ma-auto mb-5" rounded color="primary" type="submit">
                                        Login
                                    </v-btn>
                                </v-card-actions>
                            </ValidationObserver>
                        </v-card>
                    </v-window-item>
                </v-form>
            </v-window>
        </v-dialog>
    </div>
</template>
<script>
import CustomAppbar from '@/components/Global/CustomAppbar.vue';
import FooterSection from '@/components/Global/FooterSection.vue';
import HowItWorks from '@/components/Global/HowItWorks.vue';
import axios from 'axios';
import moment from 'moment';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapGetters, mapActions } from 'vuex';
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper';
import 'hooper/dist/hooper.css';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import HappyCouples from '@/components/Global/HappyCouples.vue';
/* eslint-disable */
export default {
    components: {
    CustomAppbar,
    Hooper,
    Slide,
    HooperNavigation,
    HowItWorks,
    FooterSection,
    ValidationObserver,
    ValidationProvider,
    VueperSlides,
    VueperSlide,
    HappyCouples
},
    created() {
        this.getHomePageContent()
        this.getAllListData();

    },
    watch: {
        'new_user.created_for'(newValue) {
            if (this.profile_creators.includes(newValue)) {
                this.new_user.user_gender = "Male";
            }
            if (this.profile_creators_female.includes(newValue)) {
                this.new_user.user_gender = "Female";
            }
        },
        '$route.query.action'(nv) {
            if (nv == "signIn") {
                this.models.sign_in = true;
            }
        }

    },
    computed: {
        ageFrom: function () {
            let list = [];
            for (let i = 21; i < 80; i++) {
                list.push(i);
            }
            return list;
        },
        ageUpto: function () {
            let list = [];
            let j = 21;

            if (this.search_user.age_from != null) {
                j = this.search_user.age_from;
            }
            for (let i = j; i < 80; i++) {
                list.push(i);
            }
            return list;
        },
        getMinDate() {

            return moment().subtract(60, 'years').toISOString();
        },
        getMaxDate() {
            return moment().subtract(18, 'years').toISOString();

        },
        ...mapGetters({
            userAction: 'auth/userAction',
            isLoggedIn: 'auth/isLoggedIn',
        }),
        getNumericList() {
            let list = [];
            for (let i = 18; i < 60; i++) {
                list.push(i);
            }
            return list;
        }
    },
    data: () => ({
        hero_section: {
            id: '',
            title: '',
            caption: '',
            body: '',
            is_published: false,
            default_image: '',
        },
        kesari_intro: {
            id: '',
            title: '',
            caption: '',
            body: '',
            is_published: false,
            default_image: '',
        },
        marriage_by_kesari: {
            id: '',
            title: '',
            caption: '',
            body: '',
            is_published: false,
            default_image: '',
        },
        current_banners: [],
        why_kesari_banner: {
            id: '',
            uri: '',
            is_active: false,
        },
        happy_couples: [],


        why_choose_us_img: require("@/assets/images/1.jpg"),
        happy_couple: require('@/assets/images/wedding_rings.png'),
        couple_images: "https://www.k4fashion.com/wp-content/uploads/2021/12/nauvari-marathi-wedding-dress-for-couple-",
        best_matches: require('@/assets/images/match.png'),
        verified_profile: require('@/assets/images/selection.png'),
        privacy_img: require('@/assets/images/privacy.png'),
        mandala_one: require('@/assets/images/yellow_mandala.png'),
        mandala_two: require('@/assets/images/yellow_mandala.png'),
        genders: [{
            id: "Female",
            text: "Bride (Female)"
        },
        {
            id: "Male",
            text: "Groom (Male)"
        }],
        tnc_accepted: false,
        search_user: {
            religion: '',
            gender: '',
            age_from: 18,
            age_upto: '',
            mother_tongue: '',
        },
        hoopersettings: {
            itemsToShow: 7,
            centerMode: true,
            autoPlay: true,
            playSpeed: 2000,
            infiniteScroll: true,
            wheelControl: false,
            breakpoints: {
                400: {
                    centerMode: true,
                    itemsToShow: 1
                },
                500: {
                    centerMode: true,
                    itemsToShow: 1
                },
                600: {
                    itemsToShow: 1,
                    centerMode: false,

                },
                700: {
                    itemsToShow: 2,
                    centerMode: false,
                },
                800: {
                    itemsToShow: 2,
                    centerMode: false,
                },
                900: {
                    itemsToShow: 3,
                    centerMode: false,
                },
                1000: {
                    itemsToShow: 4,
                    centerMode: false,
                },
                1200: {
                    itemsToShow: 5,
                    centerMode: false,
                }
            }
        },
        is_loggedIn: false,

        models: {

            gender_selection: false,
            dob_date_picker: false,
        }, user_exists_mesg: '',
        sign_up_otp_error_msg: '',
        models: {
            sign_in: false,
            sign_out: false,
            sign_up: false,
            user_onboarding: 1,
            user_sing_in: 1,
            otp_snackbar: false,
            verify_user_loader: false,
            date_picker: false,
            religion: '',
        },
        religions: [],
        mother_tongues: [],
        otp_response: '',
        otp_error_msg: '',
        user_profile: require('@/assets/images/user_profile.png'),
        user_email: require('@/assets/images/user_email.png'),
        user_otp: require('@/assets/images/user_otp.png'),
        user_name: require('@/assets/images/user_name.png'),
        user_password: require('@/assets/images/user_password.png'),
        disabled_continue_button: true,
        user_auth: {
            username: '',
            otp: ''
        },
        loaders: {
            send_otp_loader: false,
            mother_tongue_loader: false,
            religion_loader: false,
        },
        profile_creators: ["My Son", "My Brother"],
        profile_creators_female: ["My Daughter", "My Sister"],
        profile_creation_for: [
            {
                "id": "Self",
                "text": "My Self"
            },
            {
                "id": "Parent/Guardian",
                "text": "My Son"
            },
            {
                "id": "Parent/Guardian",
                "text": "My Daughter"
            },
            {
                "id": "Sibling",
                "text": "My Brother"
            },
            {
                "id": "Sibling",
                "text": "My Sister"
            },

        ],
        new_user: {
            created_for: "",
            user_gender: "",
            first_name: "",
            last_name: "",
            date_of_birth: "",
            email_address: "",
            mobile_number: "",
            country_code: "",
            otp: '',
        },
        country_codes: [

            {
                "name": "India",
                "dial_code": "+91",
                "code": "IN"
            },

        ]

    }),


    props: ['sign_out', 'sign_in'],
    methods: {
        getHomePageContent: function () {
            this.data_loader = true;
            axios.get(`${process.env.VUE_APP_API_URL}/gethomepagecontent`).then((response) => {
                if (response.status == 200) {
                    this.hero_section = response.data.hero_section;
                    this.kesari_intro = response.data.kesari_intro;
                    this.current_banners = response.data.current_banners;
                    this.why_kesari_banner = response.data.why_kesari_banner;
                    this.marriage_by_kesari = response.data.marriage_by_kesari;
                    this.happy_couples = response.data.happy_couples;
                    
                }
            }).catch((error) => {
                if (error.response.status == 422) {

                    this.message = "Error Occur While Getting Home Page Content"
                    this.display_snackbar = true
                }
            }).finally(() => {
                this.data_loader = false;
            });
        },
        // sign in methods
        signInUser() {
            this.models.sign_in = true;
        },
        ...mapActions('auth', [
            'login',
            "logout"]
        ),
        memberSearch() {
            if (this.isLoggedIn == 'true') {
                this.$router.push({ name: 'searchMember' })
            } else {
                this.models.sign_up = true;
            }
        },
        verifyUser: function () {
            this.otp_response = '';
            this.loaders.verify_user_loader = true
            axios.post(`${process.env.VUE_APP_API_URL}/verifyuser`, this.user_auth).then((response) => {
                if (response.status == 200) {
                    this.loaders.verify_user_loader = false;
                    this.models.user_sing_in = 2;

                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status == 422) {
                        this.otp_response = error.response.data.message;
                        this.loaders.verify_user_loader = false;
                    }

                }
            }).finally(() => {
                this.loaders.verify_user_loader = false;
            });
        },
        async login() {
            await axios.get(`${process.env.VUE_APP_URL}/sanctum/csrf-cookie`);
            await axios.post(`${process.env.VUE_APP_API_URL}/login`, this.user_auth).then((response) => {
                if (response.status == 200) {
                    this.models.sign_in = false;
                    this.models.user_sing_in = 1;
                    this.user_auth.otp = "";
                    this.user_auth.username = "";
                    this.loaders.verify_user_loader = false;
                    this.$store.dispatch('auth/login', response.data);
                    this.models.sign_in = false;

                    this.models.user_sing_in = 1;
                    if (this.$store.getters.auth.isLoggedIn == "true") {
                        this.$router.push({ name: "MyProfile" })
                    }
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status == 422) {
                        this.otp_error_msg = error.response.data.message;
                    }
                    if (error.response.status == 500) {
                        this.models.sign_in = false;
                    }
                    this.signOut();
                }
            }).finally(() => {
                this.loaders.send_otp_loader = false;
            })
        },
        validateProfileContinuation() {

            if (this.new_user.created_for) {
                if (this.new_user.created_for != 'My Self') {
                    this.disabled_continue_button = false;
                } else if (this.new_user.created_for == 'My Self') {
                    if (this.new_user.user_gender) {
                        if (this.new_user.user_gender.length > 0) {
                            this.disabled_continue_button = false;
                        } else {
                            this.disabled_continue_button = true;
                        }
                    } else {
                        this.disabled_continue_button = true;

                    }
                }
            } else {
                this.disabled_continue_button = true;

            }
        },
        getAllListData() {
            this.loaders.religion_loader = true;
            this.loaders.mother_tongue_loader = true;
            axios.get(`${process.env.VUE_APP_API_URL}/getalllistdata`).then((response) => {
                if (response.status == 200) {
                    this.religions = response.data.religions;
                    this.mother_tongues = response.data.mother_tongues;
                    this.loaders.religion_loader = false;
                    this.loaders.mother_tongue_loader = false;
                }
            }).catch((error) => {
            }).finally(() => {

            });
        },
        signUpUser() {

            if (this.models.user_onboarding == 3) {
                axios.post(`${process.env.VUE_APP_API_URL}/sendsignupotp`, this.new_user).then((response) => {
                    if (response.status == 200) {
                        this.models.user_onboarding = 4;
                    }
                }).catch((error) => {
                    if (error.response) {
                        if (error.response.status == 422) {
                            this.user_exists_mesg = error.response.data.message;
                        }
                    }
                }).finally(() => {

                });
            } else if (this.models.user_onboarding == 4) {
                axios.post(`${process.env.VUE_APP_API_URL}/signUpNewUser`, this.new_user).then((response) => {
                    if (response.status == 200) {
                        this.$store.dispatch('auth/login', response.data);
                        if (this.$store.getters.auth.isLoggedIn == "true") {
                            this.models.sign_up = false;
                            this.models.user_onboarding = 1;
                            this.$router.push({ name: "MyProfile" })
                        }
                    }
                }).catch((error) => {
                    if (error.response) {
                        if (error.response.status == 422) {
                            this.sign_up_otp_error_msg = error.response.data.message;
                        }
                        if (error.response.status == 500) {
                            this.models.user_onboarding = 1;
                        }
                    }
                }).finally(() => {
                    this.models.sign_up = false;
                    this.models.sign_in = false;
                    this.models.user_onboarding = 1;

                });
            } else {
                this.models.user_onboarding++;
            }
        },
        async logout() {

            await axios.post(`${process.env.VUE_APP_API_URL}/logOut`).then(({ data }) => {
                this.$store.dispatch('auth/logout');

            })
        },
        getMembershipStatus() {
            if (this.$store.state.auth.isLoggedIn == 'true') {
                axios.get(`${process.env.VUE_APP_API_URL}/getmembershipdetails`).then((response) => {
                    if (response.status == 200) {

                        this.$store.dispatch('membership/setMembership', response.data.current_subscription);
                    }
                }).catch((error) => {

                }).finally(() => {

                });
            }
        }
    },
}
</script>
<style>
.vueperslide--visible  {
    /* background-size: contain !important; */
    
 }
.vueperslide {
    background-size: 100% 100% !important;
  width: 100% !important;

  /* min-height: fit-content !important; */
 
}


</style>