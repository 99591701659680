<template>
    <v-container>
        <v-card outlined flat>
            <v-card-title>
                <h5>My Invitations</h5>
                <v-spacer></v-spacer>
                <v-btn class="orange-gradient" small dense href="/search/users">Search Users</v-btn>
            </v-card-title>
            <v-divider>
            </v-divider>
            <v-col cols="12">
                <v-data-table :headers="interested_headers" :items="interested_profiles" :loading="loaders.list_loader">
                    <template v-slot:[`item.status`]="{ item }">

                        <v-chip small v-if="item.status === 'pending'" color="primary"
                            class="white--text elevation-2">Pending</v-chip>
                        <v-chip small v-if="item.status === 'accepted'" color="green"
                            class="white--text elevation-2">Accepted</v-chip>
                        <v-chip small v-if="item.status === 'rejected'" color="red"
                            class="white--text elevation-2">Rejected</v-chip>

                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <router-link :to="{ name: 'viewuserprofile', params: { profile_id: item.profile_id } }"
                            v-if="premiumUser == true">
                            <v-btn link text icon>
                                <v-icon small color="primary" class="ma-1">
                                    mdi-eye
                                </v-icon>
                            </v-btn>
                        </router-link>

                        <v-tooltip bottom v-else>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn link text icon v-bind="attrs" v-on="on">
                                    <v-icon small color="grey" class="ma-1" disabled>
                                        mdi-eye
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Only Premium User can view Profile</span>
                        </v-tooltip>

                        <v-btn link text icon v-if="premiumUser == true" @click="acceptRequestConfirm(item)"
                            :disabled="item.status != 'pending' || item.status === 'rejected'">
                            <v-icon small color="green" class="ma-1">
                                mdi-check
                            </v-icon>
                        </v-btn>
                        <v-tooltip bottom v-else>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn link text icon v-bind="attrs" v-on="on">
                                    <v-icon small color="green" class="ma-1" disabled>
                                        mdi-check
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Only Premium User can Accept Profile</span>
                        </v-tooltip>
                        <v-btn link text icon @click="rejectRequestConfirm(item)" v-if="premiumUser == true"
                            :disabled="item.status != 'pending' || item.status === 'accepted'">
                            <v-icon small color="red" class="ma-1">
                                mdi-cancel
                            </v-icon>
                        </v-btn>
                        <v-tooltip bottom v-else>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn link text icon v-bind="attrs" v-on="on">
                                    <v-icon small color="red" class="ma-1" disabled>
                                        mdi-cancel
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Only Premium User can Reject Profile</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-col>
        </v-card>
        <v-snackbar :timeout="5000" v-model="snackbar" top color="indigo" class="black--text">
            {{ message }}
        </v-snackbar>
        <v-snackbar :timeout="5000" v-model="error_snackbar" top color="red" class="white--text">
            {{ message }}
        </v-snackbar>
        <v-dialog v-model="dialog_accept_request" width="300" v-if="premiumUser == true" :retain-focus="false">
            <v-card>
                <v-card-title class="headline lighten-2" primary-title>
                    Confirmation
                </v-card-title>
                <v-card-text>
                    Are you sure you want to accept user. Once accepted it cannot be revert.
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text :loading="loaders.reject_loader" @click="acceptRequest()">
                        Confirm
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_reject_request" width="300" :retain-focus="false">

            <v-card>
                <v-card-title class="headline lighten-2" primary-title>
                    Confirmation
                </v-card-title>

                <v-card-text>
                    Are you sure you want to reject user. Once rejected it cannot be revert.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="rejectRequest()" :loading="loaders.reject_loader">
                        Confirm
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
/* eslint-disable */
export default {
    computed: {
        ...mapGetters({
            isLoggedIn: 'auth/isLoggedIn',
            user: 'auth/user',
            premiumUser: 'membership/premiumUser'
        }),
    },
    created() {
        this.getInterestedProfiles();
    },
    methods: {
        rejectRequestConfirm: function (user) {
            this.dialog_reject_request = true;
            this.temp_request_object = user;
        },
        rejectRequest: function () {
            this.loaders.reject_loader = true;
            // user = Object.assign({}, user);
            axios.post(`${process.env.VUE_APP_API_URL}/rejectrequest`, this.temp_request_object).then((response) => {
                if (response.status == 200) {
                    this.message = response.data.message;
                    this.snackbar = true;
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status == 422) {
                        this.message = error.response.data.message;
                        this.error_snackbar = true;
                    }
                }
            }).finally(() => {
                this.loaders.reject_loader = false;
                this.dialog = false;
                this.dialog_reject_request = false;
                this.temp_request_object = {};
                this.getInterestedProfiles();
            });
        },
        acceptRequestConfirm: function (user) {
            this.dialog_accept_request = true;
            this.temp_request_object = user;
        },
        acceptRequest: function () {

            this.loaders.accept_loader = true;
            axios.post(`${process.env.VUE_APP_API_URL}/acceptrequest`, this.temp_request_object).then((response) => {
                if (response.status == 200) {
                    this.message = response.data.message;
                    this.snackbar = true;
                    this.temp_request_object = {};
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status == 422) {
                        this.message = error.response.data.message;
                        this.error_snackbar = true;
                    }
                }
            }).finally(() => {
                this.loaders.accept_loader = false;
                this.dialog_accept_request = false;
                this.temp_request_object = {};
                this.getInterestedProfiles();
            });
        },
        getInterestedProfiles: function () {
            this.loaders.list_loader = true;
            axios.get(`${process.env.VUE_APP_API_URL}/getmyinvitations`).then((response) => {
                if (response.status == 200) {

                    this.interested_profiles = response.data.data;
                }
            }).catch((error) => {

            }).finally(() => {
                this.loaders.list_loader = false;
            })
        }
    },
    data: () => ({
        dialog: false,
        temp_request_object: {},

        dialog_reject_request: false,
        dialog_accept_request: false,
        loaders: {
            list_loader: false,
            reject_loader: false,
            accept_loader: false,
        },
        error_snackbar: false,
        snackbar: false,
        message: '',
        interested_profiles: [],
        interested_headers: [
            {
                text: '#Member Id',
                align: 'start',
                sortable: true,
                value: 'profile_id',
            },

            {
                text: 'Name',
                align: 'start',
                sortable: false,
                value: 'name',
            },
            {
                text: 'Date Of Birth',
                align: 'start',
                sortable: false,
                value: 'date_of_birth',
            },
            {
                text: 'Request Status',
                align: 'start',
                sortable: false,
                value: 'status',
            },

            {
                text: 'Actions',
                align: 'center',
                sortable: false,
                value: 'actions',
            },

        ]
    })
}

</script>
<style>
.orange-gradient {
    background: linear-gradient(225deg, rgba(253, 44, 121, 1) 0%, rgba(253, 101, 91, 1) 100%) !important;
    color: #fff !important;
}
</style>