<template>
    <v-container>
        <v-row>
            <v-col lg="12" cols="12">
                <v-card flat outlined>
                    <v-card-title>
                        <h5>Change Password</h5>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-col lg="6" cols="12">
                            <v-text-field outlined dense placeholder="Current Password">
                                
                            </v-text-field>
                        </v-col>
                        <v-col lg="6" cols="12">
                            <v-text-field outlined dense placeholder="New Password">
                                
                            </v-text-field>
                        </v-col>
                        <v-col lg="6" cols="12">
                            <v-text-field outlined dense placeholder="Confirm New Password">
                                
                            </v-text-field>
                        </v-col>
                        <v-col lg="6" cols="12">
                            <v-btn type="submit" class="orange-gradient mt-n2">Verify</v-btn>
                        </v-col>
                    </v-card-text>
                    
                                  
                               
                </v-card>
            </v-col>

        </v-row>

        <!-- dialogs-->
        <v-dialog max-width="400" v-model="models.verification_model">
            <v-card>
                <v-card-title>Verify Yourself</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col lg="12">
                            Please enter OPT send to your Email Address or Mobile Number.Please check spam folder once it not
                                recieved.
                        </v-col>
                        <v-col lg="12">
                            <v-otp-input length="6" class="mt-10"></v-otp-input>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="mt-10">
                    <v-spacer></v-spacer>
                    <v-btn class="orange-gradient pl-3 pr-3 mb-3">Submit OTP</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </v-container>
</template>
<script>
export default {
    mounted() {
    
    },
    data: () => ({
        input_content: "Email Address",
        models: {
            verification_model: false,
        }
    }),
    methods: {
        verify: function (input_content) {
            this.input_content = input_content;
            this.models.verification_model = true;
        }
    }
}
</script>