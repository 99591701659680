import Vue from 'vue';
import CustomAppbar from './CustomAppbar.vue';
import LandingBanner from './LandingBanner.vue';
import HowItWorks from './HowItWorks.vue';
import HappyCouples from './HappyCouples.vue';
import TrustBadge from './TrustBadge.vue';
import FooterSection from './FooterSection';
import MemberSearchBar from './MemberSearchBar.vue';
import BrandPartner from './BrandPartner.vue';
import SessionAppBar from './SessionAppBar.vue';


Vue.component('custom-appbar',CustomAppbar);
Vue.component('landing-banner',LandingBanner);
Vue.component('HowItWorks',HowItWorks);
Vue.component('HappyCouples',HappyCouples);
Vue.component('TrustBadge',TrustBadge);
Vue.component('FooterSection',FooterSection);
Vue.component('MemberSearchBar',MemberSearchBar);
Vue.component('BrandPartner',BrandPartner);
Vue.component('SessionAppBar',SessionAppBar);



