<template>
    <section class="">
        <div class="view_user_background">

        </div>
        <v-skeleton-loader elevation="10" class="ma-5 " type="card-heading,article, actions"
            v-if="data_loader"></v-skeleton-loader>
        <v-container v-else>
            <v-row>
                <v-col lg="3" cols="12" sm="12" md="12" class="">
                    <v-card class="mt-lg-14 mt-md-14 md-sm-14">
                        <v-card-text>
                            <v-carousel continuous show-arrows hide-delimiter-background height="300" hide-delimiters cycle
                                v-if="profile_images.length > 0" interval="2000" show-arrows-on-hover
                                class="pa-0 ma-0 elevation-5">
                                <v-carousel-item class="" v-for="profile_image in profile_images" :key="profile_image.uri">
                                    <v-img class="" height="300" width="" aspect-ratio="1.7778" :src="profile_image.uri">
                                        <v-btn elevation="5" tile class="white--text mt-3 text-capitalize font-weight-bold"
                                            dense x-small color="pink">
                                            <v-icon left>
                                                mdi-crown-outline
                                            </v-icon>
                                            Premium
                                        </v-btn>

                                    </v-img>
                                </v-carousel-item>
                            </v-carousel>
                            <v-img class="elevation-5" height="300" width="" aspect-ratio="1.7778" v-else
                                :src="user_no_image">
                                <!-- <v-btn elevation="5" tile class="white--text mt-3 text-capitalize font-weight-bold"
                                    v-if="this.$store.getters['membership/premiumUser']" dense x-small color="pink">
                                    <v-icon left>
                                        mdi-crown-outline
                                    </v-icon>
                                    Premium
                                </v-btn> -->

                            </v-img>
                        </v-card-text>
                        <v-col xl="11" lg="12" sm="12" cols="12" v-if="user.is_verified" class="ma-auto">
                            <v-alert color="green" dismissible elevation="16" type="success">
                                This Profile Has been Verified
                            </v-alert>
                        </v-col>
                        <v-card-text>
                            <h2>{{ user.first_name + " " + user.last_name }}</h2>
                            <h5 class="pt-3">{{ "Member ID: #" + user.profile_id }}</h5>
                            <v-spacer></v-spacer>
                        </v-card-text>
                        <v-card-text>
                            <v-row class="mt-2">
                                <v-col cols="12" sm="4" md="4" lg="12">
                                    <v-btn elevation="10" block large class="white--text  text-capitalize" color="red"
                                        :loading="loaders.shortlist_loader" @click="shortListUser"
                                        :disabled="loaders.shortlist_loader">
                                        <v-icon left>
                                            mdi-format-list-checks
                                        </v-icon>
                                        Add To ShortList
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" sm="4" md="4" lg="12">
                                    <v-dialog v-model="dialog" width="300">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn elevation="10" v-on="on" v-bind="attrs" block large
                                                class="white--text  text-capitalize" color="red">
                                                <v-icon left @click="dialog = true" :loading="loaders.send_interest_loader"
                                                    :disabled="loaders.send_interest_loader">
                                                    mdi-account-heart-outline
                                                </v-icon>
                                                Express Interest
                                            </v-btn>
                                        </template>

                                        <v-card>
                                            <v-card-title class="headline lighten-2" primary-title>
                                                Confirmation
                                            </v-card-title>

                                            <v-card-text>
                                                Are you sure you want to send Interest Request.Once done it cannot be
                                                revert.
                                            </v-card-text>

                                            <v-divider></v-divider>

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="red" text @click="sendExpressInterest"
                                                    :loading="loaders.send_interest_loader"
                                                    :disabled="loaders.send_interest_loader">
                                                    Confirm
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>

                                </v-col>
                                <v-col cols="12" sm="4" md="4" lg="12">

                                    <v-dialog v-model="contact_dialog" width="300">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn elevation="10" block large v-on="on" v-bind="attrs"
                                                class="white--text  text-capitalize" color="red"
                                                @click="contact_dialog = true" :loading="loaders.view_contact_loader"
                                                :disabled="loaders.view_contact_loader">
                                                <v-icon left>
                                                    mdi-phone-classic
                                                </v-icon>
                                                View Contact
                                            </v-btn>
                                        </template>

                                        <v-card>
                                            <v-card-title class="headline lighten-2" primary-title>
                                                Confirmation
                                            </v-card-title>
                                            <v-card-text>
                                                Are you sure you want to view Contact.Once done it cannot be
                                                revert.
                                            </v-card-text>
                                            <v-divider></v-divider>

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="red" text @click="viewUserContacts"
                                                    :loading="loaders.view_contact_loader"
                                                    :disabled="loaders.view_contact_loader">
                                                    Confirm
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                </v-col>
                <v-col lg="9" cols="12" sm="12" md="12" class="mt-lg-14 mt-md-14 md-sm-14">
                    <v-timeline dense align-top>
                        <v-timeline-item fill-dot color="pink" icon="mdi-phone-classic" left large v-if="show_contacts">
                            <v-expansion-panels class="mb-6" flat hide-arrow inset accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header hide-actions>
                                        <h3>Contact Information</h3>

                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card>
                                            <v-card-text class="text-justify">
                                                <v-list-item dense class="pr-3">
                                                    <v-list-item-subtitle>
                                                        <v-row>
                                                            <v-col lg="5" md="5" sm="5" cols="5" class="text-body-2">
                                                                Mobile Number
                                                            </v-col>
                                                            <v-col lg="1" md="1" sm="1" cols="1"
                                                                class="text-body-2 pr-0 mr-0">
                                                                :
                                                            </v-col>
                                                            <v-col lg="5" md="5" sm="5" cols="5"
                                                                class="text-body-2 pl-0 ml-0">
                                                                {{ user.mobile_number }}
                                                            </v-col>
                                                        </v-row>
                                                    </v-list-item-subtitle>
                                                </v-list-item>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-timeline-item>
                        <v-timeline-item fill-dot color="pink" icon="mdi-format-quote-open" left large>
                            <v-expansion-panels class="mb-6" flat hide-arrow inset accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header hide-actions>
                                        <h3>About {{ user.first_name + " " + user.last_name }}</h3>

                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card>
                                            <v-card-text class="text-justify">
                                                <span v-html="user.about_prospect"
                                                    v-if="user != undefined && user.about_prospect != null"></span>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-timeline-item>
                        <v-timeline-item fill-dot color="pink" icon="mdi-tooltip-account" left large>
                            <v-expansion-panels class="mb-6" flat hide-arrow inset accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header hide-actions>
                                        <h3>Basic Information</h3>

                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col lg="12" md="12" sm="12" cols="12">
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        First Name
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        {{ user.first_name }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Middle Name
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user != undefined && user.middle_name != null">
                                                                        {{ user.middle_name }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Last Name
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user != undefined && user.last_name != null">
                                                                        {{ user.last_name }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Mother Name
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user != undefined && user.mother_name != null">
                                                                        {{ user.mother_name }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>

                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Gender
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user != undefined && user.gender != null">
                                                                        {{ user.gender }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Date of Birth
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user != undefined && user.date_of_birth != null">
                                                                        {{ dateOfBirth }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Maritial Status
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user != undefined && user.maritial_status != null">
                                                                        {{ user.maritial_status }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Profile Created For
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user != undefined && user.profile_created_by != null">
                                                                        {{ user.profile_created_by }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-timeline-item>
                        <v-timeline-item fill-dot color="pink" icon="mdi-school" left large>
                            <v-expansion-panels class="mb-6" flat hide-arrow inset accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header hide-actions>
                                        <h3>Education Information</h3>

                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col lg="12" md="12" sm="12" cols="12">
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Highest Qualification
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.employee_information != undefined && user.employee_information.highest_education != null">
                                                                        {{
                                                                            user.employee_information.highest_education
                                                                        }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Specialized In
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.employee_information != undefined && user.employee_information.specialized_in != null">
                                                                        {{ user.employee_information.specialized_in }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Institue Name
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.employee_information != undefined && user.employee_information.institue != null">
                                                                        {{ user.employee_information.institue }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>

                                                    </v-col>

                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-timeline-item>
                        <v-timeline-item fill-dot color="pink" icon="mdi-briefcase" left large>
                            <v-expansion-panels class="mb-6" flat hide-arrow inset accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header hide-actions>
                                        <h3>Career Information</h3>

                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col lg="12" md="12" sm="12" cols="12">
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Occupation
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.employee_information != undefined && user.employee_information.occupation != null">
                                                                        {{ user.employee_information.occupation }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Employee With
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.employee_information != undefined && user.employee_information.employee_with != null">
                                                                        {{ user.employee_information.employee_with }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Employee Location
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.employee_information != undefined && user.employee_information.employee_location != null">
                                                                        {{
                                                                            user.employee_information.employee_location
                                                                        }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Annual Income
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="!user.employee_information.keep_income_private">
                                                                        {{ user.employee_information.annual_income }}
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0" v-else>
                                                                        Cannot Disclosed
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                    </v-col>

                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-timeline-item>


                        <v-timeline-item fill-dot color="pink" icon="mdi-gymnastics" left large>
                            <v-expansion-panels class="mb-6" flat hide-arrow inset accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header hide-actions>
                                        <h3>Physical Attributes </h3>

                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col lg="12" md="12" sm="12" cols="12">
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Height
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.personal_information != undefined && user.personal_information.height != null">
                                                                        {{ user.personal_information.height }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Blood Group
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.personal_information != undefined && user.personal_information.blood_group != null">
                                                                        {{ user.personal_information.blood_group }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Body Type
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.personal_information != undefined && user.personal_information.body_type != null">
                                                                        {{ user.personal_information.body_type }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>



                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Physically Disabled
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        <span
                                                                            v-if="user.personal_information != undefined && user.personal_information.physically_challanged != null">Yes</span><span
                                                                            v-else>No</span>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Skin Complexity
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.personal_information != undefined && user.personal_information.skin_complexity != null">
                                                                        {{ user.personal_information.skin_complexity }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Health Information
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap pl-0 ml-0"
                                                                        v-if="user.personal_information.health_information != null">
                                                                        {{
                                                                            user.personal_information.health_information.toString()
                                                                        }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-timeline-item>
                        <v-timeline-item fill-dot color="pink" icon="mdi-headphones" left large>
                            <v-expansion-panels class="mb-6" flat hide-arrow inset accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header hide-actions>
                                        <h3>Hobbies & Interest </h3>

                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col lg="12" md="12" sm="12" cols="12">
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Hobbies
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.personal_information.hobbies != null">
                                                                        {{ user.personal_information.hobbies.toString() }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>

                                                    </v-col>


                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-timeline-item>
                        <v-timeline-item fill-dot color="pink" icon="mdi-map-marker" left large>
                            <v-expansion-panels class="mb-6" flat hide-arrow inset accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header hide-actions>
                                        <h3>Social Information</h3>

                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col lg="12" md="12" sm="12" cols="12">
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Eating Habbit
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap"
                                                                        v-if="user.social_information != undefined && user.social_information.eating_habbit != null">
                                                                        {{ user.social_information.eating_habbit }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Drinking Habbit
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap"
                                                                        v-if="user.social_information != undefined && user.social_information.drinking_habbit != null">
                                                                        {{ user.social_information.drinking_habbit }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Smoking Habbit
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap"
                                                                        v-if="user.social_information != undefined && user.social_information.smoking_habbit != null">
                                                                        {{ user.social_information.smoking_habbit }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-timeline-item>
                        <v-timeline-item fill-dot color="pink" icon="mdi-hands-pray" left large>
                            <v-expansion-panels class="mb-6" flat hide-arrow inset accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header hide-actions>
                                        <h3>Religious Information </h3>

                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col lg="12" md="12" sm="12" cols="12">
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Religion
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.religious_information != undefined && user.religious_information.religion != null">
                                                                        {{ user.religious_information.religion }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Caste
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.religious_information != undefined && user.religious_information.caste != null">
                                                                        {{ user.religious_information.caste }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Mother Tongue
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.religious_information != undefined && user.religious_information.mother_tongue != null">
                                                                        {{ user.religious_information.mother_tongue }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>

                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Gotra
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.religious_information != undefined && user.religious_information.gotra != null">
                                                                        {{ user.religious_information.gotra }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Caste Preference For Partner
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        <span
                                                                            v-if="user.religious_information.is_caste_nobar">Yes</span><span
                                                                            v-else>No</span>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>

                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-timeline-item>
                        <v-timeline-item fill-dot color="pink" icon="mdi-star-david" left large>
                            <v-expansion-panels class="mb-6" flat hide-arrow inset accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header hide-actions>
                                        <h3>Astrological Information </h3>

                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col lg="12" md="12" sm="12" cols="12">
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Place of Birth
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.astro_information != undefined && user.astro_information.birth_place != null">
                                                                        {{ user.astro_information.birth_place }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Birth Time
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.astro_information != undefined && user.astro_information.birth_time != null">
                                                                        {{ user.astro_information.birth_time }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Nakshatra
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.astro_information != undefined && user.astro_information.nakshatra != null">
                                                                        {{ user.astro_information.nakshatra }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>

                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2">
                                                                        Rashi
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.astro_information != undefined && user.astro_information.rashi != null">
                                                                        {{ user.astro_information.rashi }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Manglik ?
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        <span
                                                                            v-if="user.astro_information.manglik">Yes</span><span
                                                                            v-else>No</span>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Patrika Dosh ?
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0">
                                                                        <span
                                                                            v-if="user.astro_information.patrika_dosh">Yes</span><span
                                                                            v-else>No</span>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>

                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-timeline-item>
                        <v-timeline-item fill-dot color="pink" icon="mdi-account-group" left large>
                            <v-expansion-panels class="mb-6" flat hide-arrow inset accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header hide-actions>
                                        <h3>Family Information </h3>

                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col lg="12" md="12" sm="12" cols="12">
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Father Living Status
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap"
                                                                        v-if="user.family_information != undefined && user.family_information.father_living_status != null">
                                                                        {{
                                                                            user.family_information.father_living_status
                                                                        }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3"
                                                            v-if="user.family_information.father_living_status === 'Employed'">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Father Employeed With
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap"
                                                                        v-if="user.family_information != undefined && user.family_information.father_employed_with != null">
                                                                        {{
                                                                            user.family_information.father_employed_with
                                                                        }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3"
                                                            v-if="user.family_information.father_living_status === 'Employed'">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Father Employeed As
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap"
                                                                        v-if="user.family_information != undefined && user.family_information.father_employed_as != null">
                                                                        {{ user.family_information.father_employed_as }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3"
                                                            v-if="user.family_information.father_living_status === 'Business'">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Father Nature of Business
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap"
                                                                        v-if="user.family_information != undefined && user.family_information.father_nature_of_business != null">
                                                                        {{
                                                                            user.family_information.father_nature_of_business
                                                                        }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3"
                                                            v-if="user.family_information.father_living_status === 'Retired'">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Father Retired From
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap"
                                                                        v-if="user.family_information != undefined && user.family_information.father_retired_from != null">
                                                                        {{
                                                                            user.family_information.father_retired_from
                                                                        }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3"
                                                            v-if="user.family_information.father_living_status === 'Retired'">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Father Retired As
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap"
                                                                        v-if="user.family_information != undefined && user.family_information.father_retired_as != null">
                                                                        {{ user.family_information.father_retired_as }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Mother Living Status
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap"
                                                                        v-if="user.family_information != undefined && user.family_information.mother_living_status != null">
                                                                        {{
                                                                            user.family_information.mother_living_status
                                                                        }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3"
                                                            v-if="user.family_information.mother_living_status === 'Employed'">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Mother Employeed With
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap"
                                                                        v-if="user.family_information != undefined && user.family_information.mother_employed_with != null">
                                                                        {{
                                                                            user.family_information.mother_employed_with
                                                                        }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3"
                                                            v-if="user.family_information.mother_living_status === 'Employed'">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Mother Employeed As
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap"
                                                                        v-if="user.family_information != undefined && user.family_information.mother_employed_as != null">
                                                                        {{ user.family_information.mother_employed_as }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3"
                                                            v-if="user.family_information.mother_living_status === 'Business'">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Mother Nature of Business
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap"
                                                                        v-if="user.family_information != undefined && user.family_information.mother_nature_of_business != null">
                                                                        {{
                                                                            user.family_information.mother_nature_of_business
                                                                        }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3"
                                                            v-if="user.family_information.mother_living_status === 'Retired'">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Mother Retired From
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap"
                                                                        v-if="user.family_information != undefined && user.family_information.mother_retired_from != null">
                                                                        {{
                                                                            user.family_information.mother_retired_from
                                                                        }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3"
                                                            v-if="user.family_information.mother_living_status === 'Retired'">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Mother Retired As
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap"
                                                                        v-if="user.family_information != undefined && user.family_information.mother_retired_as != null">
                                                                        {{ user.family_information.mother_retired_as }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>

                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Married Sisters
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap"
                                                                        v-if="user.family_information != undefined && user.family_information.married_sisters != null">
                                                                        {{ user.family_information.married_sisters }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        UnMarried Sisters
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap"
                                                                        v-if="user.family_information != undefined && user.family_information.unmarried_sisters != null">
                                                                        {{ user.family_information.unmarried_sisters }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Married Brothers
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap"
                                                                        v-if="user.family_information != undefined && user.family_information.married_brothers != null">
                                                                        {{ user.family_information.married_brothers }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        UnMarried Brothers

                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap"
                                                                        v-if="user.family_information != undefined && user.family_information.unmarried_brothers != null">
                                                                        {{ user.family_information.unmarried_brothers }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>

                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Family Type
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap"
                                                                        v-if="user.family_information != undefined && user.family_information.type != null">
                                                                        {{ user.family_information.type }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Family Value
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap"
                                                                        v-if="user.family_information != undefined && user.family_information.family_value != null">
                                                                        {{ user.family_information.family_value }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Family Affluence
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap"
                                                                        v-if="user.family_information != undefined && user.family_information.affluence != null">
                                                                        {{ user.family_information.affluence }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-timeline-item>
                        <v-timeline-item fill-dot color="pink" icon="mdi-map-marker" left large>
                            <v-expansion-panels class="mb-6" flat hide-arrow inset accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header hide-actions>
                                        <h3>Residence Information</h3>

                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col lg="12" md="12" sm="12" cols="12">
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Family Location
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap"
                                                                        v-if="user.family_information != undefined && user.family_information.family_location != null">
                                                                        {{ user.family_information.family_location }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Native Place
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap"
                                                                        v-if="user.family_information != undefined && user.family_information.native_place != null">
                                                                        {{ user.family_information.native_place }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Living With Famliy
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap"
                                                                        v-if="user.family_information != undefined && user.family_information.living_with_family != null">
                                                                        <span
                                                                            v-if="user.family_information.living_with_family">Yes</span><span
                                                                            v-else>No</span>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-timeline-item>
                        <v-timeline-item fill-dot color="pink" icon="mdi-food" left large>
                            <v-expansion-panels class="mb-6" flat hide-arrow inset accordion>
                                <v-expansion-panel>
                                    <v-expansion-panel-header hide-actions>
                                        <h3>Partner Preference </h3>

                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-card>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col lg="12" md="12" sm="12" cols="12">
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Age From
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.user_preference != undefined && user.user_preference.age_from != null">
                                                                        {{ user.user_preference.age_from }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Age Upto
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.user_preference != undefined && user.user_preference.age_upto != null">
                                                                        {{ user.user_preference.age_upto }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Expected Height
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.user_preference != undefined && user.user_preference.expected_height != null">
                                                                        {{ user.user_preference.expected_height }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Eating Habbit
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.user_preference != undefined && user.user_preference.eating_habbit != null">
                                                                        {{ user.user_preference.eating_habbit }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Smoking Habbit
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.user_preference != undefined && user.user_preference.smoking_habbit != null">
                                                                        {{ user.user_preference.smoking_habbit }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Drinking Habbit
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.user_preference != undefined && user.user_preference.drinking_habbit != null">
                                                                        {{ user.user_preference.drinking_habbit }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Expected Income
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.user_preference != undefined && user.user_preference.annual_income != null">
                                                                        {{ user.user_preference.annual_income }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>



                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Manglik ?
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.user_preference != undefined && user.user_preference.is_manglik != null">
                                                                        <span
                                                                            v-if="user.user_preference.is_manglik">Yes</span><span
                                                                            v-else>No</span>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Patrika Dosh ?
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.user_preference != undefined && user.user_preference.is_dosh_present != null">
                                                                        <span
                                                                            v-if="user.user_preference.is_dosh_present">Yes</span><span
                                                                            v-else>No</span>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Family Value
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.user_preference != undefined && user.user_preference.family_value != null">
                                                                        {{ user.user_preference.family_value }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Family Affluence
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.user_preference != undefined && user.user_preference.family_affluence != null">
                                                                        {{ user.user_preference.family_affluence }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Family Type
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.user_preference != undefined && user.user_preference.family_type != null">
                                                                        {{ user.user_preference.family_type }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Highest Qualification
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0"
                                                                        v-if="user.user_preference != undefined && user.user_preference.highest_education != null">
                                                                        {{ user.user_preference.highest_education }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>
                                                        <v-list-item dense class="pr-3">
                                                            <v-list-item-subtitle>
                                                                <v-row>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 text-wrap">
                                                                        Caste Preferences
                                                                    </v-col>
                                                                    <v-col lg="1" md="1" sm="1" cols="1"
                                                                        class="text-body-2 pr-0 mr-0">
                                                                        :
                                                                    </v-col>
                                                                    <v-col lg="5" md="5" sm="5" cols="5"
                                                                        class="text-body-2 pl-0 ml-0 text-wrap"
                                                                        v-if="user.user_preference != undefined && user.user_preference.caste_preferences != null">
                                                                        {{ user.user_preference.caste_preferences.toString()
                                                                        }}
                                                                    </v-col>
                                                                </v-row>
                                                            </v-list-item-subtitle>
                                                        </v-list-item>

                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-timeline-item>


                    </v-timeline>
                </v-col>
            </v-row>
        </v-container>
        <FooterSection></FooterSection>
        <v-snackbar :timeout="6000" v-model="snackbars.error" top color="red" class="white--text font-weight-bold">
            {{ message }}
        </v-snackbar>
        <v-snackbar :timeout="6000" v-model="snackbars.success" top color="indigo" class="white--text font-weight-bold">
            {{ message }}
        </v-snackbar>
    </section>
</template>
<script>
import FooterSection from '@/components/Global/FooterSection.vue';
import axios from 'axios';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
    created() {
        this.viewUserProfile();

    },
    computed: {
        dateOfBirth() {

            return moment(this.user.date_of_birth).format("DD/MM/YYYY");
        },
        ...mapGetters({
            premiumUser: 'membership/premiumUser'
        }),
        premiumUser() {
            return this.$store.getters['membership/premiumUser'];
        }
    },
    methods: {
        viewUserProfile: function () {
            this.data_loader = true;
            axios.get(`${process.env.VUE_APP_API_URL}/viewuserdetails/` + this.$route.params.profile_id).then((response) => {
                if (response.status == 200) {

                    this.user = response.data.data;
                    this.getUserImages();



                    if (response.data.data.user_contact != null) {
                        this.user.contact_information = response.data.user_contact;
                        this.show_contacts = true;

                    }
                }
            }).catch((error) => {
                if (error.response) {
                    console.log(error.response);
                    if (error.response.status == 422) {
                        this.message = error.response.data.message;
                        this.snackbars.error = true;
                    }
                }
            }).finally(() => {
                this.data_loader = false;
            });
        },
        shortListUser: function () {
            this.loaders.shortlist_loader = true;
            let user = {};
            user.profile_id = this.user.profile_id;
            axios.post(`${process.env.VUE_APP_API_URL}/shortlistuser`, user).then((response) => {
                if (response.status == 200) {
                    this.message = "User Added to Shortlist";
                    this.snackbars.success = true;
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status == 422) {
                        this.message = error.response.data.message;
                        this.snackbars.error = true;
                    }
                }
            }).finally(() => {
                this.loaders.shortlist_loader = false;
            });
        },
        sendExpressInterest: function () {
            this.loaders.send_interest_loader = true;
            let user = {};
            user.profile_id = this.user.profile_id;
            axios.post(`${process.env.VUE_APP_API_URL}/sendexpressinterest`, user).then((response) => {
                if (response.status == 200) {
                    this.message = "Interest Send Sucessfully.";
                    this.snackbars.success = true;
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status == 422) {
                        this.message = error.response.data.message;
                        this.snackbars.error = true;
                    }
                }
            }).finally(() => {
                this.loaders.send_interest_loader = false;
                this.dialog = false;
            });
        },
        getUserImages: function () {

            axios.get(`${process.env.VUE_APP_API_URL}/getuserimages/` + this.$route.params.profile_id).then((response) => {

                if (response.status == 200) {

                    this.profile_images = response.data.user_images;

                }
            }).catch((error) => {
                console.log(error);
            }).finally(() => {

            })
        },
        viewProfile: function () {
            if (this.premiumUser) {
                this.$router.push({ name: 'viewuserprofile', params: { profile_id: this.user.profile_id } });
            } else {
                this.$router.push({ name: 'membershipPricing' });
            }
        },
        viewUserContacts: function () {
            this.loaders.view_contact_loader = true;
            let user = {};
            user.profile_id = this.user.profile_id;
            axios.post(`${process.env.VUE_APP_API_URL}/viewusercontacts`, user).then((response) => {
                if (response.status == 200) {
                    this.message = "Contacts Retrieved Sucessfully.";
                    this.snackbars.success = true;
                    this.show_contacts = true;
                    this.user.mobile_number = response.data.user_contact;

                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status == 422) {
                        this.message = error.response.data.message;
                        this.snackbars.error = true;
                    }
                }
            }).finally(() => {
                this.loaders.view_contact_loader = false;
                this.contact_dialog = false;
            });
        }
    },
    data: () => ({
        data_loader: false,
        profile_images: [],
        user_no_image: require('@/assets/images/user-no-image.jpg'),
        dialog: false,
        contact_dialog: false,
        show_contacts: false,

        loaders: {
            shortlist_loader: false,
            send_interest_loader: false,
            view_contact_loader: false,

        },
        snackbars: {
            error: false,
            success: false,
        },
        message: '',
        user: {
            family_information: {},
            social_information: {},
            employee_information: {},
            religious_information: {},
            astro_information: {},
            personal_information: {},
            user_preference: {
                caste_preferences: [],
            },
            mobile_number: '',
        },

    }),
    components: { FooterSection }
}




</script>
<style scoped>.hero_section {
    position: relative !important;
}

.view_user_background {
    height: 30px !important;
    background-image: linear-gradient(-60deg, #ff5858 0%, #f09819 100%);
}</style>