<template>
    <v-container fluid class="pa-0 ma-0">
        <section class="elite-hero-section">
            <v-row class="justify-center">
                <v-col lg="6" cols="12" sm="12" md="6" class="elite-hero-content">
                    <div>
                        <h3 class="">Kesari Elite</h3>
                        <h4>Exclusive Matchmaking Service for Elites</h4>
                    </div>
                </v-col>
                <v-col lg="4" cols="12" sm="12" md="6" class="elite-form">
                    <v-card width="500" class="elite-enquiry-card elevation-10">
                        <v-card-title class="elite-card-title ma-auto">
                            <h1 class=" pt-3 ma-auto">Elite Matrimony Service <br>by Kesari</h1>
                        </v-card-title>
                        <ValidationObserver ref="eliteform" v-slot="{ invalid, validated, handleSubmit }">
                            <v-form @submit.prevent="handleSubmit(submitEliteEnquiry)">
                                <v-card-text class="pa-5">
                                    <v-row>
                                        <v-col lg="6" cols="12" sm="12" md="6">
                                            <ValidationProvider name="First Name" rules="max:20|required|alpha"
                                                v-slot="{ errors }">
                                                <v-text-field label="First Name" :error-messages="errors"
                                                    v-model="elite_enquiry_form.first_name"></v-text-field>

                                            </ValidationProvider>

                                        </v-col>
                                        <v-col lg="6" cols="12" sm="12" md="6">
                                            <ValidationProvider name="Last Name" rules="max:20|required|alpha"
                                                v-slot="{ errors }">
                                                <v-text-field label="Last Name" :error-messages="errors"
                                                    v-model="elite_enquiry_form.last_name"></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col lg="12" cols="12" sm="12" md="6">
                                            <ValidationProvider name="Seeking Alliance For" rules="required|alpha"
                                                v-slot="{ errors }">
                                                <v-select :items="seeking_alliance_for" item-text="text" item-value="text"
                                                    label="Seeking Alliance For" :error-messages="errors"
                                                    v-model="elite_enquiry_form.seeking_alliance_for"></v-select>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col lg="12" cols="12" sm="12" md="6">
                                            <ValidationProvider name="Mobile Number" rules="required|numeric|min:7|max:14"
                                                v-slot="{ errors }">
                                                <v-text-field label="Mobile Number" :error-messages="errors" 
                                                    v-model="elite_enquiry_form.mobile_number"></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col lg="12" cols="12" sm="12" md="6">
                                            <ValidationProvider name="Email Address" rules="required|email"
                                                v-slot="{ errors }">
                                                <v-text-field label="Email Address" :error-messages="errors"
                                                    v-model="elite_enquiry_form.email_address"></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions class="pa-5">
                                    <v-btn class="ma-auto rounded-pill white--text font-weight-bold elevation-4 pl-10 pr-10"
                                        large color="purple darken-3" type="submit" :loading="loaders.submit_loader"
                                        :disabled="invalid || !validated">Get Invited</v-btn>
                                </v-card-actions>
                            </v-form>
                        </ValidationObserver>
                    </v-card>
                </v-col>
            </v-row>
        </section>
        <section class="why-choose-elite">
            <v-container>
                <v-row>
                    <v-col lg="12" md="12" sm="12" cols="12" class="text-center ma-auto">
                        <h3 class="pb-0 mb-0">{{ why_elite.title }}</h3>
                       
                    </v-col>
                    <v-col lg="6" md="6" sm="12" cols="12">
                        <v-img :src="why_kesari_banner.uri" class="why_choose_elite_img"></v-img>
                    </v-col>
                    <v-col lg="6" md="6" sm="12" cols="12" class="why_choose_elite_content">
                        <p v-html="why_elite.body"></p>

                    </v-col>
                </v-row>
            </v-container>
        </section>

        <section class="mt-14">
            <v-container class="elite-process mt-14">
                <h3 class="text-center pb-10">How We Work </h3>
                <div class="elite-process-timeline">
                    <v-timeline class="" align-top :dense="$vuetify.breakpoint.smAndDown">
                        <v-timeline-item large>
                            <template v-slot:icon>
                                <v-avatar color="red" class="process-avatar">
                                    <v-icon color="white" class="process-icon">mdi-information-variant</v-icon>
                                </v-avatar>
                            </template>
                            <template v-slot:opposite>
                                <span class="process-number"></span>
                            </template>
                            <v-card class="elevation-2">
                                <v-card-title class="process-title">
                                    Understanding Requirements
                                </v-card-title>
                                <v-card-text class="process-text">Our relationship manager will understand your profile
                                    details, family details and your partner preferences.</v-card-text>
                            </v-card>
                        </v-timeline-item>
                        <v-timeline-item large>
                            <template v-slot:icon>
                                <v-avatar color="red" class="process-avatar">
                                    <v-icon color="white" class="process-icon">mdi-account-plus-outline</v-icon>
                                </v-avatar>
                            </template>
                            <template v-slot:opposite>
                                <span class="process-number"></span>
                            </template>
                            <v-card class="elevation-2">
                                <v-card-title class="process-title">
                                    Profile Creation
                                </v-card-title>
                                <v-card-text class="process-text">Our expert team creates your profile such that other
                                    prospects get complete and precise information about you. </v-card-text>
                            </v-card>
                        </v-timeline-item>
                        <v-timeline-item large>
                            <template v-slot:icon>
                                <v-avatar color="red" class="process-avatar">
                                    <v-icon color="white" class="process-icon">mdi-account-heart</v-icon>
                                </v-avatar>
                            </template>
                            <template v-slot:opposite>
                                <span class="process-number"></span>
                            </template>
                            <v-card class="elevation-2">
                                <v-card-title class="process-title">
                                    Finding Matches
                                </v-card-title>
                                <v-card-text class="process-text">Based on your partner preferences, our relationship
                                    manager finds matches for you.</v-card-text>
                            </v-card>
                        </v-timeline-item>
                        <v-timeline-item large>
                            <template v-slot:icon>
                                <v-avatar color="red" class="process-avatar">
                                    <v-icon color="white" class="process-icon">mdi-account-multiple-outline</v-icon>
                                </v-avatar>
                            </template>
                            <template v-slot:opposite>
                                <span class="process-number"></span>
                            </template>
                            <v-card class="elevation-2">
                                <v-card-title class="process-title">
                                    Connecting with Prospects
                                </v-card-title>
                                <v-card-text class="process-text">Our experienced relationship manager will connect with
                                    profiles who match with your preferences. </v-card-text>
                            </v-card>
                        </v-timeline-item>
                        <v-timeline-item large>
                            <template v-slot:icon>
                                <v-avatar color="red" class="process-avatar">
                                    <v-icon color="white" class="process-icon">mdi-charity</v-icon>
                                </v-avatar>
                            </template>
                            <template v-slot:opposite>
                                <span class="process-number"></span>
                            </template>
                            <v-card class="elevation-2">
                                <v-card-title class="process-title">
                                    Schedule meetings
                                </v-card-title>
                                <v-card-text class="process-text">We schedule meetings for mutually accepted profiles so
                                    that their individual and family interactions will take place. </v-card-text>
                            </v-card>
                        </v-timeline-item>
                        <v-timeline-item large>
                            <template v-slot:icon>
                                <v-avatar color="red" class="process-avatar">
                                    <v-icon color="white" class="process-icon">mdi-emoticon-happy-outline</v-icon>
                                </v-avatar>
                            </template>
                            <template v-slot:opposite>
                                <span class="process-number"></span>
                            </template>
                            <v-card class="elevation-2">
                                <v-card-title class="process-title">
                                    Happy marriage
                                </v-card-title>
                                <v-card-text class="process-text">Based on personal and family meetings, we communicate with
                                    respective family. </v-card-text>
                            </v-card>
                        </v-timeline-item>

                    </v-timeline>
                </div>

            </v-container>
        </section>
        <section class="elite-review" v-if="kesari_feedback_banners.length > 0">

            <div class="elite-feedback-content pt-14">

                <v-carousel continuous cycle hide-delimiters hide-delimiter-background show-arrows-on-hover vertical 
                    class="rounded-lg d-flex align-center ma-2 mt-10">
                    <template v-slot:prev="{ on, attrs }">
                        <v-icon color="white" class=" black rounded-circle" v-bind="attrs" v-on="on">mdi-arrow-down</v-icon>
                    </template>
                    <template v-slot:next="{ on, attrs }">
                        <v-icon color="white" class="black rounded-circle" v-bind="attrs" v-on="on">mdi-arrow-up</v-icon>
                    </template>
                    <v-carousel-item v-for="(feedback, i) in kesari_feedback_banners" class="elevation-0 rounded-lg" :key="i">
                        <v-card max-width="1144px" class="rounded-lg">
                            <v-row>
                                <v-col lg="6" cols="12" sm="6" md="6" class="pa-4 pt-14">
                                    <!-- <h2>Title For Feedback</h2> -->
                                    <p v-html="feedback.alternative_text" class="pt-10"></p>
                                </v-col>
                                <v-col lg="6" cols="12" sm="6" md="6" class="pa-0 ma-0">
                                    <v-img class="pa-0 ma-0 rounded-lg " :src="feedback.uri" height="100%" max-height="400"
                                        width="100%"></v-img>
                                </v-col>
                            </v-row>
                        </v-card>

                    </v-carousel-item>

                </v-carousel>
            </div>
        </section>
        <section class="elite-packages">
            <v-container>
                <h3>{{kesari_package.title}}</h3>
               
                <div>
                    <v-row class="justify-center">
                        <v-col lg="12" cols="12">
                            <p v-html="kesari_package.body" class="pt-4 elite-package-body">
                              
                            </p>
                            <!-- <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, repellendus
                                consequatur. Natus, incidunt placeat praesentium porro, facilis nostrum dolor quidem
                                nulla fugiat sint libero, obcaecati quis voluptates soluta saepe reiciendis?
                            </p> -->
                            <v-row class="ma-12">
                                <v-col cols="12" sm="12" lg="4" md="4">
                                    <v-card class="package-feature-card">
                                        <v-card-text class="text-center">
                                            <v-img :src="feature_1" width="60" height="60" contain
                                                class="ma-auto pt-3"></v-img>
                                            <h3 class="pa-5">20+</h3>
                                            <p>Dedicated <br>Relationship Managers</p>

                                        </v-card-text>
                                    </v-card>

                                </v-col>
                                <v-col cols="12" sm="12" lg="4" md="4">
                                    <v-card class="package-feature-card">
                                        <v-card-text class="text-center">
                                            <v-img :src="feature_2" width="60" height="60" contain
                                                class="ma-auto pt-3"></v-img>
                                            <h3 class="pa-5">100%</h3>
                                            <p>Sucess<br>Efforts</p>

                                        </v-card-text>
                                    </v-card>

                                </v-col>
                                <v-col cols="12" sm="12" lg="4" md="4">
                                    <v-card class="package-feature-card">
                                        <v-card-text class="text-center">
                                            <v-img :src="feature_3" width="60" height="60" contain
                                                class="ma-auto pt-3"></v-img>
                                            <h3 class="pa-5">5+</h3>
                                            <p>Years<br>of Elite Service</p>

                                        </v-card-text>
                                    </v-card>

                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col lg="12" cols="12">
                            <section class="pricing_section ma-auto" style="display: block !;margin-bottom: 1% !important;">
                                <Hooper :settings="hoopersettings" style="height:500px">
                                    <Slide v-for="item, index in elite_plans" :key="index">
                                        <v-card elevation="12" class="pricing_card_three rounded-xl ma-auto">
                                            <v-card-title class="plan_name">
                                                <h3 class="ma-auto">{{ item.title }}</h3>
                                            </v-card-title>
                                            <v-card-text class="plan_price">
                                                <h4 class="text-center"><v-icon class="" color="white"
                                                        size="20">mdi-currency-inr</v-icon>{{ item.price }}</h4>
                                                <v-divider class="mt-3 white ml-10 mr-10" light></v-divider>
                                                <p class="text-center plan_period"> {{ item.maxduration_in_months }}
                                                    Months</p>
                                            </v-card-text>
                                            <v-card-text class="text-center">
                                                <v-btn class="rounded-pill  elevation-15 price_button " medium color="white"
                                                    :loading="loaders.payment_loader" :disabled="loaders.payment_loader"
                                                    href="/contactus">Contact Us</v-btn>
                                            </v-card-text>
                                            <v-card-text class="pricing_features">
                                                <v-list dense class="ml-2 transparent ">
                                                    <v-list-item v-if="item.features != null">
                                                        <v-list-item-icon class="pr-3 mr-0 ">
                                                            <v-icon size="16" color="white">mdi-check-circle</v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-content>
                                                            <v-list-item-title class="price_item text-wrap white--text">{{
                                                                item.features }}</v-list-item-title>
                                                        </v-list-item-content>

                                                    </v-list-item>
                                                    <v-list-item>
                                                        <v-list-item-icon class="pr-3 mr-0 ">
                                                            <v-icon size="16" color="white">mdi-check-circle</v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-content>
                                                            <v-list-item-title
                                                                class="price_item text-wrap white--text">Contact us to go
                                                                Elite</v-list-item-title>
                                                        </v-list-item-content>

                                                    </v-list-item>
                                                </v-list>
                                            </v-card-text>
                                        </v-card>
                                    </Slide>
                                    <hooper-navigation slot="hooper-addons"></hooper-navigation>
                                </Hooper>
                            </section>
                        </v-col>
                    </v-row>

                </div>
            </v-container>
        </section>
        <FooterSection style="margin-top: 150px !important;"></FooterSection>
        <!-- message box -->
        <v-dialog v-model="dialogs.form_submit_success" width="300" height="300">
            <v-card width="600" height="300">
                <v-card-text class="text-right pa-2">

                    <v-icon @click="dialogs.form_submit_success = !dialogs.form_submit_success"
                        color="red">mdi-close</v-icon>
                </v-card-text>
                <v-card-title>
                    <v-icon color="green" size="50" class="ma-auto pt-5">mdi-tooltip-check-outline</v-icon>
                </v-card-title>
                <v-card-text class="ma-auto text-center">
                    <p clsss="text-justify">Thank you for contacting us, our representive will be in touch within next
                        24 Hours.</p>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import FooterSection from '@/components/Global/FooterSection.vue';
import axios from 'axios';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
// import { cashfreeSandbox } from "cashfree-pg-sdk-javascript";
import { cashfreeProd } from "cashfree-pg-sdk-javascript";
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper';
import 'hooper/dist/hooper.css';
export default {
    created() {
        this.getEliteMatrimonyPlans();
        this.getKesariEliteContent();
    },
    components: { FooterSection, ValidationObserver, ValidationProvider, Hooper, Slide, HooperNavigation, },
    methods: {
        submitEliteEnquiry: function () {

            this.loaders.submit_loader = true;
            axios.post(`${process.env.VUE_APP_API_URL}/submiteliteenquiry`, this.elite_enquiry_form).then((response) => {
                if (response.status == 200) {
                    this.dialogs.form_submit_success = true;
                }
            }).catch((error) => {

                if (error.response) {

                    if (error.response.status == 422) {
                        this.$refs.eliteform.setErrors({
                            'First Name': error.response.data.errors.first_name,
                            'Last Name': error.response.data.errors.last_name,
                            'Seeking Alliance For': error.response.data.errors.seeking_alliance_for,
                            'Email address': error.response.data.errors.email_address,
                            'Mobile Number': error.response.data.errors.mobile_number,
                        });
                    }
                }

            }).finally(() => {
                this.loaders.submit_loader = false;
            })
        },
        getCss(property) {
            return {
                'text-wrap white--text text-decoration-line-through': !property,
                'text-wrap white--text': property,

            };
        },
        getCardBackground(index) {

            return this.cards_css_box[index];
        },
        getEliteMatrimonyPlans: function () {
            axios.get(`${process.env.VUE_APP_API_URL}/getelitematrimonyplans`).then((response) => {
                if (response.status == 200) {

                    this.elite_plans = response.data.plans;
                }
            }).catch((error) => {

                if (error.response) {

                    if (error.response.status == 422) {
                        console.log(error);
                    }
                }

            }).finally(() => {
                this.loaders.submit_loader = false;
            })
        },
        getKesariEliteContent: function () {

            axios.get(`${process.env.VUE_APP_API_URL}/getelitepagecontent`).then((response) => {
                if (response.status == 200) {
                    this.why_elite = response.data.why_elite
                    this.why_kesari_banner = response.data.why_kesari_banner
                    this.kesari_feedbacks = response.data.kesari_feedbacks
                    this.kesari_package = response.data.kesari_package
                    this.kesari_feedback_banners = response.data.kesari_feedback_banners
                }
            }).catch((error) => {
                if (error.response.status == 422) {

                    this.message = "Error Occur While Getting Home Page Content"
                    this.display_snackbar = true
                }
            }).finally(() => {
                this.data_loader = false;
            });
        },
    },
    data: () => ({
        why_choose_us_img: require("@/assets/images/1.jpg"),
        feature_1: require("@/assets/images/customersupport.png"),
        feature_2: require("@/assets/images/sucessfactor.png"),
        feature_3: require("@/assets/images/eliteservice.png"),
        cycle: false,
        elite_plans: [],
        why_elite: {
            id: '',
            title: '',
            caption: '',
            body: '',
        },
        kesari_package: {
            id: '',
            title: '',
            body: '',
            caption: '',
            is_published: '',
        },
        new_feedback: {
            feedback: '',
            is_active: false,
            image: {},
        },
        kesari_feedbacks: [],
        new_why_kesari_banner: {

        },
        kesari_feedback_banners: [],
        why_kesari_banner: {
            id: '',
            uri: '',

        },
        hoopersettings: {
            itemsToShow: 2,
            centerMode: true,
            autoPlay: false,
            playSpeed: 2000,
            infiniteScroll: false,
            wheelControl: false,
            breakpoints: {
                400: {
                    centerMode: true,
                    itemsToShow: 1
                },
                500: {
                    centerMode: false,
                    itemsToShow: 1
                },
                600: {
                    itemsToShow: 2,
                    centerMode: true,

                },
                700: {
                    itemsToShow: 2,
                    centerMode: true,
                },
                800: {
                    itemsToShow: 2,
                    centerMode: false,
                },
                900: {
                    itemsToShow: 2,
                    centerMode: false,
                },
                1000: {
                    itemsToShow: 2,
                    centerMode: false,
                },
                1200: {
                    itemsToShow: 2,
                    centerMode: false,
                }
            }
        },
        seeking_alliance_for: [
            {
                "id": "Self",
                "text": "Self"
            },
            {
                "id": "Parent / Guardian",
                "text": "Son"
            },
            {
                "id": "Parent / Guardian",
                "text": "Daughter"
            },
            {
                "id": "Sibling",
                "text": "Brother"
            },
            {
                "id": "Sibling",
                "text": "Sister"
            },
            {
                "id": "Friend",
                "text": "Friend"
            },
            {
                "id": "Other",
                "text": "Relative"
            }
        ],
        cards_css_box: [
            'pricing_card_one rounded-xl',
            'pricing_card_two rounded-xl',
            'pricing_card_three rounded-xl',
            'pricing_card_two rounded-xl',
            'pricing_card_one rounded-xl',
            'pricing_card_two rounded-xl',
            'pricing_card_three rounded-xl',
            'pricing_card_two rounded-xl',
            'pricing_card_one rounded-xl',
            'pricing_card_two rounded-xl',
            'pricing_card_three rounded-xl',
            'pricing_card_two rounded-xl',
            'pricing_card_one rounded-xl',
            'pricing_card_two rounded-xl',
            'pricing_card_three rounded-xl',
            'pricing_card_two rounded-xl',],
        elite_enquiry_form: {
            first_name: null,
            last_name: null,
            seeking_alliance_for: null,
            email_address: null,
            mobile_number: null,
        },
        loaders: {
            submit_loader: false,
        },
        dialogs: {
            form_submit_success: false,
        },
        slides: [
            "https://images.unsplash.com/photo-1587271449604-04bb40332709?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MTR8NzQ3OTU5NzJ8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60",
            "https://images.unsplash.com/photo-1611106211090-8f3c79eb8552?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
            "https://images.unsplash.com/photo-1610173826608-bd1f53a52db1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDJ8fHxlbnwwfHx8fA==&auto=format&fit=crop&w=500&q=60",
            "https://images.unsplash.com/photo-1616165415772-f5b95c3ae135?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDEzfHx8ZW58MHx8fHw=&auto=format&fit=crop&w=500&q=60",
        ],
    }),

}
</script>
<style>
@media only screen and (max-width: 960px) and (min-width: 600px) {

    .elite-price-card {
        margin: auto !important;
        height: 110% !important;
    }

    .elite-packages {
        height: 100% !important;
    }


}

@media only screen and (max-width: 1264px) and (min-width: 960px) {

    .elite-price-card {
        margin: auto !important;
        height: 110% !important;
    }

    .elite-packages {
        height: 100% !important;
    }


}

.bonus_plans {
    background-color: #fff !important;
    padding-top: 5%;
}

.bonu_plan_pricing {
    padding-top: 5% !important;
}

.bonus_plan_pricing .bonus_plan_name {
    padding-top: 2% !important;
    line-height: 2.5rem !important;
    font-size: 2.3rem !important;
    color: #fff;
    font-weight: 400 !important;
    font-family: 'Nunito' !important;
}

.bonus_plan_pricing .bonus_plan_price {
    padding-top: 8% !important;
    font-size: 2.8rem !important;
    color: #fff;
    font-weight: 800 !important;
    font-family: 'Nunito' !important;
}

.bonus_plan_pricing .bonus_plan_caption {
    padding-top: 2% !important;
    font-size: 1.0rem !important;
    color: #fff;
    font-weight: 800 !important;
    font-family: 'Nunito' !important;
}

.bonus_plan_details {
    font-size: 1.0rem !important;
    color: #fff;
    font-weight: 300 !important;
    font-family: 'Nunito' !important;
}
</style>