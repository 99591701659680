<template>
    <v-container class="progress_display">
        <v-row class="fill-height" align-content="center" justify="center">
            <v-col cols="12" lg="12">
                <v-img :src="kesari_logo" height="400" contain max-height="100" max-width="200" class="ma-auto"></v-img>
            </v-col>
            
            <v-col cols="12" lg="4">
                <v-progress-linear color="orange accent-4" indeterminate rounded height="6"
                    v-if="!payment_sucess_full"></v-progress-linear>
            </v-col>
            
            <v-col class="text-subtitle-1 text-center"  cols="12">
                <h5 class="text-h5 red--text"> 
                    {{ status_message }}</h5>
            </v-col>

        </v-row>
    </v-container>
</template>
<script>
import axios from 'axios';

export default {
    created() {
  
    },
    mounted() {
    },
  
    
    data: () => ({
        kesari_logo: require('@/assets/images/kesari_logo.png'),
        status_message: "Thank you for choosing Kesari Matrimony, Your subscriptions will be confirm and activated once we received payment confirmation.",
        order_id: '',
        payment_sucess_full: false,
    })


}
</script>
<style>
.progress_display {
    padding-top: 15% !important;
}
</style>