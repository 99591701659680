import axios from "axios";
import router from "@/router";

export default {
    namespaced: true,
    state: {
        premium_user: false,
        profile_view_count: 0,
        express_interest_count: 0,
        view_contacts_count: 0,
        plan_id: localStorage.getItem('plan_id'),
        price: localStorage.getItem('price'),
        voucher_code: '',
    },
    getters: {
        premiumUser(state) {
            return state.premium_user;
        },
        profileView(state) {
            return state.profile_view_count;
        },
        expressInterest(state) {
            return state.express_interest_count;
        },
        viewContacts(state) {
            return state.view_contacts_count;
        },
        viewPlanId(state) {
            return state.plan_id;
        },
        viewVoucherCode(state) {
            return state.voucher_code;
        },
        viewPlanPrice(state) {
            return state.price;
        }
    },
    mutations: {
        setPremiumUser(state, value) {
            state.premium_user = value;
        },
        setProfileView(state, value) {
            state.profile_view_count = value;
        },
        setExpressInterest(state, value) {
            state.express_interest_count = value;
        },
        setContactView(state, value) {
            state.view_contacts_count = value;
        },
        setPlanId(state, value) {
            state.plan_id = value;
            localStorage.setItem('plan_id', value);
        },
        setPrice(state, value) {
            state.price = value;
            localStorage.setItem('price', value);
        },
        setVoucherCode(state, value) {
            state.voucher_code = value;
        }


    },
    actions: {
        setMembership({ commit }, subscription) {
            if (subscription.is_active) {
                commit('setPremiumUser', true);
                commit('setProfileView', subscription.pending_profileview_allowed);
                commit('setExpressInterest', subscription.pending_express_interest_allowed);
                commit('setContactView', subscription.pending_contactview_allowed);
                commit('setPrice', subscription.membership_plan.price);
            }
        },

        updateProfileView({ commit }, value) {
            commit('setProfileView', value);
        },
        updateExpressInterest({ commit }, value) {
            commit('setExpressInterest', value);
        },
        updateContactView({ commit }, value) {
            commit('setContactView', value);
        },
        updatePlanId({ commit }, value) {
            commit('setPlanId', value);
        },
        updateVoucherCode({ commit }, value) {
            commit('setVoucherCode', value);
        }

    }
}