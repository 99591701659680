<template>
    <v-container>
        <v-card outlined flat>
            <v-card-title>
                <h5>My Interest</h5>
                <v-spacer></v-spacer>
                <v-btn class="orange-gradient" small dense href="/search/users">Search Users</v-btn>
            </v-card-title>
            <v-divider>
            </v-divider>
            <v-col cols="12">
                <v-data-table :headers="interested_headers" :items="interested_profiles" :loading="loaders.list_loader">
                    <template v-slot:[`item.status`]="{ item }">
                    <v-chip small v-if="item.status === 'pending'" color="primary" class="white--text elevation-2">Pending</v-chip>
                    <v-chip small v-if="item.status === 'accepted'" color="green" class="white--text elevation-2">Accepted</v-chip>
                    <v-chip small v-if="item.status === 'rejected'" color="red" class="white--text elevation-2">Rejected</v-chip>
                   
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn link text icon v-if="premiumUser == true">
                        <router-link :to="{ name:'viewuserprofile' , params:{ profile_id:item.profile_id }}" >
                        <v-icon small color="primary" class="ma-1">
                            mdi-eye
                        </v-icon>
                        </router-link>
                    </v-btn>
                    <v-tooltip bottom v-else>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn link text icon  v-bind="attrs" v-on="on">
                                    <v-icon small color="grey" class="ma-1" disabled>
                                        mdi-eye
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Only Premium User can view Profile</span>
                        </v-tooltip>
                    </template>
                    <!-- <template v-slot:[`item.actions`]="{  }">
                    <v-btn link text icon>
                        <v-icon small color="primary" class="ma-1">
                            mdi-eye
                        </v-icon>
                    </v-btn>
                    <v-btn link text icon>
                        <v-icon small color="red" class="ma-1">
                            mdi-delete
                        </v-icon>
                    </v-btn>
                </template> -->
                </v-data-table>
            </v-col>
        </v-card>
    </v-container>
</template>
<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            isLoggedIn: 'auth/isLoggedIn',
            user: 'auth/user',
            premiumUser: 'membership/premiumUser'
        }),
    },
    created() {
        this.getmyinvitations();
    },
    methods: {
        getmyinvitations: function () {
            this.loaders.list_loader = true;
            axios.get(`${process.env.VUE_APP_API_URL}/getinterestedprofiles`).then((response) => {
                if (response.status == 200) {
                    this.interested_profiles = response.data.data;
                }
            }).catch((error) => {

            }).finally(() => {
                this.loaders.list_loader = false;
            })
        }
    },
    data: () => ({
        loaders: {
            list_loader: false,
        },
        interested_profiles: [],
        interested_headers: [
            {
                text: '#Member Id',
                align: 'start',
                sortable: true,
                value: 'profile_id',
            },

            {
                text: 'Name',
                align: 'start',
                sortable: false,
                value: 'name',
            },
            {
                text: 'Date Of Birth',
                align: 'start',
                sortable: false,
                value: 'date_of_birth',
            },
            {
                text: 'User Response',
                align: 'start',
                sortable: false,
                value: 'status',
            },

            {
                text: 'Actions',
                align: 'center',
                sortable: false,
                value: 'actions',
            },

        ]
    })
}

</script>
<style>
.orange-gradient {
    background: linear-gradient(225deg, rgba(253, 44, 121, 1) 0%, rgba(253, 101, 91, 1) 100%) !important;
    color: #fff !important;
}
</style>