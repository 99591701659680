<template>
    <v-container fluid>
        <v-row>
            <v-col lg="12" md="12" xs="12">
                <v-img :src="kesari_logo" height="100" width="250" class="pl-14"></v-img>

            </v-col>
            <v-col lg="12" md="12" cols="12" class="pl-14">
                <v-row>
                    <v-col lg="8" md="8" sm="12" cols="12">
                        <p>Plan Name : {{ plan.title }} Plan</p>
                        <p v-if="plan.features != null">Features : {{ plan.features }}</p>
                        <p>Validity : {{ plan.maxduration_in_months }} (Months)</p>
                    </v-col>
                    <v-col lg="3" md="3" sm="12" cols="12">
                        <v-text-field outlined disabled dense v-model="plan.price">
                            <template v-slot:prepend>
                                <p>Amount</p>
                            </template>
                        </v-text-field>
                        <v-text-field outlined disabled dense v-model="plan.discount_amount">
                            <template v-slot:prepend>
                                <p>Discount</p>
                            </template>
                        </v-text-field>
                        <v-text-field outlined disabled dense v-model="plan.final_amount">
                            <template v-slot:prepend>
                                <p>Total</p>
                            </template>
                        </v-text-field>
                        <v-text-field outlined dense prefix="Discount Voucher" v-model="voucher_code">

                            <template v-slot:append-outer>
                                <v-btn class="mt-n2 primary elevation-5" :loading="discount_calculation_loader"
                                    :disabled="voucher_code.length == 0" @click="applyDiscountCode">
                                    Apply
                                </v-btn>
                            </template>
                        </v-text-field>
                        <v-btn block class="primary elevation-5 rounded-lg" @click="createPurchaseOrder"
                            :loading="checkout_loader">Checkout</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-snackbar v-model="display_snackbar" color="primary"> {{ message }}</v-snackbar>
    </v-container>
</template>
<script>
import axios from 'axios';
export default {

    mounted() {

        this.getMembershipDetails();
    },
    methods: {
        getMembershipDetails: function () {
            axios.get(`${process.env.VUE_APP_API_URL}/getmembershipplan/` + this.$store.getters['membership/viewPlanId']).then((response) => {

                if (response.status == 200) {
                    this.plan = response.data.plan;

                }

            }).catch((error) => {
                console.log(error);
            }).finally(() => {

            })
        },
        applyDiscountCode: function () {
            this.discount_calculation_loader = true;
            axios.post(`${process.env.VUE_APP_API_URL}/applydiscount/` + this.$store.getters['membership/viewPlanId'] + '/' + this.voucher_code).then((response) => {
                if (response.status == 200) {
                    this.plan = response.data.plan;

                    this.message = "Discount Applied Successfully";
                    this.display_snackbar = true;
                }

            }).catch((error) => {
                if (error) {
                    this.message = "Invalid Discount or Discount code has expired";
                    this.display_snackbar = true;
                }
            }).finally(() => {
                this.discount_calculation_loader = false;
            });
        },
        createPurchaseOrder: function () {
            this.checkout_loader = true;
            axios.post(`${process.env.VUE_APP_API_URL}/createpurchaseorder`, this.plan).then((response) => {
                if (response.status == 200) {

                    // initiate payment gateway call
                  
                    const pg_form = document.createElement('form');
                    pg_form.method = 'post';
                    pg_form.action = "https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction";

                    //creating encrypted data field in hidden
                    const payment_data = document.createElement('input');
                    payment_data.type = 'hidden';
                    payment_data.name = 'encRequest';
                    payment_data.value = response.data.payment_data;
                    pg_form.appendChild(payment_data);

                    const session_id = document.createElement('input');
                    session_id.type = 'hidden';
                    session_id.name = 'access_code';
                    session_id.value = response.data.session_id;
                    pg_form.appendChild(session_id);
                    console.log(pg_form);
                document.body.append(pg_form);
                pg_form.submit();

                }

            }).catch((error) => {
                if (error) {
                    console.log(error);
                    this.message = "Failed to Initiate Payment. Please contact Support for more information";
                    this.display_snackbar = true;
                }
            }).finally(() => {
                this.checkout_loader = false;
            });
        }
    },
    data: () => ({
        kesari_logo: require('@/assets/kesari_logo.png'),
        discount_calculation_loader: false,
        checkout_loader: false,
        plan: {
            voucher_code: '',
            id: '',
            price: '',
            final_amount: '',
            discount_amount: 0,
            voucher_id: '',
        },
        voucher_code: '',
        display_snackbar: false,
        message: '',

    })
}
</script>