<template>
    <section class="brand-partner-section">
        <v-container>
            <h2 class="text-center section-title">Our Brand Partners</h2>

        <v-row class="text-center d-flex justify-center pt-4 mt-6">
            <v-col lg="3" >
                <v-card min-height="220" class="rounded-lg">
                    <v-card-title class="text-center ">
                        <h4 class="pt-8">Our Personalized Match Making Services</h4>
                    </v-card-title>
                    <v-card-text class="text-center d-flex justify-center pt-4 mt-6">
                        <v-img src="https://lh3.googleusercontent.com/BqcGbxGKIiL3RyRM4OAFReWXXgV7YmgEUobVx-bhxoRTHMHaNXndyI8aIFqJHQdivQ" height="100" contain width="100"></v-img>
                        <v-img src="https://www.oneservice.gov.sg/-/media/mso/homepage/logo/oneservice-logo.ashx" height="100" contain width="150"></v-img>
                        
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col lg="3" class="offset-lg-1">
                <v-card min-height="220" class="rounded-lg">
                    <v-card-title class="text-center ">
                        <h4 class="pt-8">Our Personalized Match Making Services</h4>
                    </v-card-title>
                    <v-card-text class="text-center d-flex justify-center pt-4 mt-6">
                        <v-img src="https://lh3.googleusercontent.com/BqcGbxGKIiL3RyRM4OAFReWXXgV7YmgEUobVx-bhxoRTHMHaNXndyI8aIFqJHQdivQ" height="100" contain width="100"></v-img>
                        <v-img src="https://www.oneservice.gov.sg/-/media/mso/homepage/logo/oneservice-logo.ashx" height="100" contain width="150"></v-img>
                        
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>





        </v-container>
    </section>
</template>
<script>
export default {


    mounted() {
        console.log("Search Member Section landed")
    },
    data: () => ({
        hoopersettings: {
            itemsToShow: 7,
            autoPlay: true,
            playSpeed: 2000,
            infiniteScroll: true,
        },
    }),
    

}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Playfair+Display:wght@400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

.brand-partner-section {
    height: 40vh !important;
    background-color: #fff;
    padding: 0% !important;
    margin: 0% !important;
}

.section-title {
    padding-top: 2.0%;
    font-family: 'Great Vibes', cursive !important;
    color: #6f2836 !important;
    font-weight: 600 !important;
    font-size: 3.0rem !important;
}

</style>