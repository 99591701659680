<template>
    <div class="pa-0 ma-0">
        <v-container fluid class="pa-0 ma-0 contact-us-hero">
            <v-row class="justify-center">
                <v-col lg="3" cols="12" md="6" sm="12">
                    <v-card class="contact-form elevation-10 rounded-lg" max-width="450">
                        <v-card-text>
                            <h2 class="text-capitalize pt-4 pb-4">Your Query Can help us make better.</h2>
                        </v-card-text>
                        <v-divider class=""></v-divider>
                        <v-card-text class="">
                            <ValidationObserver ref="contactform" v-slot="{ invalid, validated, handleSubmit }">
                                <v-form @submit.prevent="handleSubmit(submitquery)">
                                    <v-row>
                                        <v-col lg="6" cols="6" sm="6" md="6" class="pb-0 mb-0">
                                            <ValidationProvider name="First Name" rules="max:20|required|alpha"
                                                v-slot="{ errors }">
                                                <v-text-field outlined label="First Name" :error-messages="errors"
                                                    v-model="contact_us_form.first_name"></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col lg="6" cols="6" sm="6" md="6" class="pb-0 mb-0">
                                            <ValidationProvider name="Last Name" rules="max:20|required|alpha"
                                                v-slot="{ errors }">
                                                <v-text-field outlined label="Last Name" :error-messages="errors"
                                                    v-model="contact_us_form.last_name"></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col lg="12" class="pt-0 mt-0">
                                            <ValidationProvider name="Email Address" rules="required|email"
                                                v-slot="{ errors }">
                                                <v-text-field outlined label="Email Address" :error-messages="errors"
                                                    v-model="contact_us_form.email_address"></v-text-field>
                                            </ValidationProvider>
                                            <ValidationProvider name="Mobile Number"
                                                rules="required|numeric|min:7|max:14" v-slot="{ errors }">
                                                <v-text-field outlined label="Mobile Number" :error-messages="errors"
                                                    v-model="contact_us_form.mobile_number"></v-text-field>
                                            </ValidationProvider>
                                            <ValidationProvider name="Query" rules="required|max:500"
                                                v-slot="{ errors }">
                                                <v-textarea outlined label="Query" v-model="contact_us_form.query_data"
                                                    :error-messages="errors" counter="500"></v-textarea>
                                            </ValidationProvider>
                                            <v-btn block class="orange-gradient rounded-lg" type="submit"
                                                :loading="loaders.form_submit"
                                                :disabled="invalid || !validated">Submit</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </ValidationObserver>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col lg="6" cols="12" md="6" sm="12">
                    <div class="contact-hero-content">
                        <h3 class="text-capitalize">We would Love to hear from you</h3>
                        <h3 class="text-capitalize">Our friendly team is always happy to help</h3>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <v-progress-circular indeterminate color="red" v-if="data_loader"></v-progress-circular>
        <v-container class="contact-section" v-else>
            <v-row class="justify-space-between">
                <v-col lg="3" cols="12" md="3" sm="12">
                    <v-card outline flat height="180">
                        <v-card-text>
                            <h3>{{ email_details.title}}</h3>
                            <p class="pt-3">{{ email_details.caption}}</p>
                            <p class="font-weight-bold" v-html="email_details.body"></p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col lg="3" cols="12" md="3" sm="12">
                    <v-card outline flat height="180">
                        <v-card-text>
                            <h3>{{office_details.title}}</h3>
                            <p class="pt-2">{{office_details.caption}}</p>
                            <p class="font-weight-bold" v-html="office_details.body"></p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col lg="3" cols="12" md="3" sm="12">
                    <v-card outline flat height="180">
                        <v-card-text>
                            <h3>{{phone_details.title}}</h3>
                            <p class="pt-2">{{ phone_details.caption}}</p>
                            <p class="font-weight-bold" v-html="phone_details.body"></p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <!-- message box -->
        <v-dialog v-model="dialogs.form_submit_success" width="300" height="300">
            <v-card width="600" height="300">
                <v-card-text class="text-right pa-2">

                    <v-icon @click="dialogs.form_submit_success = !dialogs.form_submit_success"
                        color="red">mdi-close</v-icon>
                </v-card-text>
                <v-card-title>
                    <v-icon color="green" size="50" class="ma-auto pt-5">mdi-tooltip-check-outline</v-icon>
                </v-card-title>
                <v-card-text class="ma-auto text-center">
                    <p clsss="text-justify">Thank you for contacting us, our representive will be in touch within next
                        48 Hours.</p>
                </v-card-text>
            </v-card>
        </v-dialog>
        <FooterSection></FooterSection>
    </div>

</template>
<script>
import FooterSection from '@/components/Global/FooterSection.vue';
import axios from 'axios';
import { ValidationObserver,ValidationProvider } from 'vee-validate';
import { email } from 'vee-validate/dist/rules';

export default {
    mounted() {
    this.getContactUsPageDetails();
  },
    methods: {
        submitquery: function () {
            this.loaders.form_submit = true;
            axios.post(`${process.env.VUE_APP_API_URL}/submitquery`, this.contact_us_form).then((response) => {
                if (response.status == 200) {
                    this.dialogs.form_submit_success = true;
                }
            }).catch((error) => {
                if (error.response) {

                    if (error.response.status == 422) {
                        this.$refs.contactform.setErrors({
                            'First Name': error.response.data.errors.first_name,
                            'Last Name': error.response.data.errors.last_name,
                            'Email address': error.response.data.errors.email_address,
                            'Mobile Number': error.response.data.errors.mobile_number,
                            'Query':error.response.data.errors.query_data,
                        });
                    }
                }
            }).finally(() => {
                this.loaders.form_submit = false;
            });
        },
        getContactUsPageDetails: function () {
            this.data_loader = true;
      axios.get(`${process.env.VUE_APP_API_URL}/getcontactuspagedetails`).then((response) => {
        if (response.status == 200) {
          this.phone_details = response.data.phone_details;
          this.office_details = response.data.office_details;
          this.email_details = response.data.email_details;
        }
      }).catch((error) => {
        if (error.response.status == 422) {

          this.message = "Error Occur While Getting About Us Page Content"
          this.display_snackbar = true
        }
      }).finally(() => {
        this.data_loader = false;
      });
    },
    },
    data: () => ({
        data_loader:false,
        dialogs: {
            form_submit_success: false,
        },
        loaders: {
            form_submit: false,
        },
        contact_us_form: {
            first_name: "",
            last_name: "",
            email_address: "",
            mobile_number: "",
            query_data: "",
        },
        phone_details: {
      id: '',
      body: '',
      title: '',
      caption: ''
    },
    office_details: {
      id: '',
      body: '',
      title: '',
      caption: ''
    },
    email_details: {
      id: '',
      body: '',
      title: '',
      caption: ''
    }
    }),
    components: { ValidationObserver, ValidationProvider, FooterSection }
}
</script>
<style>

</style>