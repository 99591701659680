<template>
    <v-container>
        <v-row>
            <v-col lg="12" cols="12" md="12" sm="12">
                <v-row>
                    <v-card class="ma-2 orange-gradient naruto_font elevation-15" min-width="250">
                        <v-card-text class="text-center white--text font-weight-bold naruto_font">
                            <v-icon color="white" size="30">mdi-phone-classic</v-icon>
                            <h4 class="pt-2 pb-3 text-h5">{{ active_normal_plan.pending_contactview_allowed }}</h4>
                            Remaining Contact View
                        </v-card-text>
                    </v-card>
                    <v-card class="ma-2 orange-gradient naruto_font elevation-15 " min-width="250">
                        <v-card-text class="text-center white--text font-weight-bold naruto_font">
                            <v-icon color="white" size="30">mdi-hand-heart-outline</v-icon>
                            <h4 class="pt-2 pb-3 text-h5">{{ active_normal_plan.pending_express_interest_allowed }}</h4>
                            Remaining Interest
                        </v-card-text>
                    </v-card>
                    <v-card class="ma-2 orange-gradient naruto_font elevation-15 " min-width="250">
                        <v-card-text class="text-center white--text font-weight-bold naruto_font">
                            <v-icon color="white" size="30">mdi-update</v-icon>
                            <h4 class="pt-2 pb-3 text-h5" v-if="active_normal_plan.expire_on">{{
                                moment(active_normal_plan.expire_on).format("DD-MMM-YYYY") }}</h4>
                            <h4 class="pt-2 pb-3 text-h5" v-else>NA</h4>
                            Membership Expire On
                        </v-card-text>
                    </v-card>
                    <v-card class="ma-2 orange-gradient naruto_font elevation-15" min-width="250"
                        v-if="highlight_profile_plan != null">
                        <v-card-text class="text-center white--text font-weight-bold naruto_font">
                            <v-icon color="white" size="30">mdi-account-star</v-icon>
                            <h4 class="pt-2 pb-3 text-h5" v-if="highlight_profile_plan.expire_on">{{
                                moment(highlight_profile_plan.expire_on).format("DD-MMM-YYYY") }}</h4>
                            <h4 class="pt-2 pb-3 text-h5" v-else>NA</h4>

                            Highlight Profile Expire On
                        </v-card-text>
                    </v-card>

                    <v-card class="ma-2 orange-gradient naruto_font elevation-15" min-width="250"
                        v-if="assisted_service_plan != null">
                        <v-card-text class="text-center white--text font-weight-bold naruto_font">
                            <v-icon color="white" size="30">mdi-assistant</v-icon>
                            <h4 class="pt-2 pb-3 text-h5" v-if="assisted_service_plan.expire_on">{{
                                moment(assisted_service_plan.expire_on).format("DD-MMM-YYYY") }}</h4>
                            <h4 class="pt-2 pb-3 text-h5" v-else>NA</h4>
                            Assissted Service Duration
                        </v-card-text>
                    </v-card>

                    <v-card class="ma-2 orange-gradient naruto_font elevation-15" min-width="250"
                        v-if="personal_consulting_plan != null">
                        <v-card-text class="text-center white--text font-weight-bold naruto_font">
                            <v-icon color="white" size="30">mdi-assistant</v-icon>
                            <h4 class="pt-2 pb-3 text-h5"
                                v-if="personal_consulting_plan.personal_consulting_duration_hours > 0">{{
                                    personal_consulting_plan.personal_consulting_duration_hours }}</h4>
                            <h4 class="pt-2 pb-3 text-h5" v-else>NA</h4>
                            Personal Consulting Pending Duration
                        </v-card-text>
                    </v-card>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-card class="elevation-4">
                    <v-card-text>
                        <h3>My Subscriptions</h3>
                    </v-card-text>
                    <v-card-text>
                        <v-data-table :headers="subscription_header" :items="subscriptions" :loading="loaders.list_loader">
                            <template v-slot:[`item.plan_name`]="{ item }">
                                {{ item.membership_plan.title }}
                            </template>

                            <template v-slot:[`item.plan_type`]="{ item }">
                                <span v-if="item.bonus_plan">Bonus Plan</span>
                                <span v-if="item.is_normal_plan">Normal Plan</span>
                                <span v-if="item.is_elite_plan">Elite Plan</span>
                            </template>
                            <template v-slot:[`item.is_active`]="{ item }">
                                <v-chip small v-if="item.is_active" color="green"
                                    class="white--text elevation-2">Active</v-chip>
                                <v-chip small v-else color="red" class="white--text elevation-2">Expired</v-chip>
                            </template>
                            <template v-slot:[`item.payment_status`]="{ item }">
                                <span v-if="item.transaction == null">Not Available</span>
                                <span v-else>{{ item.transaction.pg_order_status }}</span>
                            </template>
                            <template v-slot:[`item.expire_on`]="{ item }">
                                {{ moment(item.expire_on).format("DD-MMM-YYYY") }}
                            </template>
                            <!-- <template v-slot:[`item.actions`]="{  }">
                    <v-btn link text icon>
                        <v-icon small color="primary" class="ma-1">
                            mdi-eye
                        </v-icon>
                    </v-btn>
                    <v-btn link text icon>
                        <v-icon small color="red" class="ma-1">
                            mdi-delete
                        </v-icon>
                    </v-btn>
                </template> -->
                        </v-data-table>
                    </v-card-text>

                </v-card>

            </v-col>
        </v-row>


        <v-snackbar v-model="display_snackbar" :color="snackbar_color" absolute centered elevation="23"
            class="font-weight-bold">{{ snackbar_message }}</v-snackbar>
    </v-container>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
export default {

    mounted() {
        if (this.$route.query.payment === "sucess") {
            this.snackbar_color = "deep-purple accent-4";
            this.snackbar_message = "Payment Sucessful";
            this.display_snackbar = true;
        }
        this.getMySubscriptions();

    },
    methods: {
        getMySubscriptions: function () {
            this.loaders.list_loader = true;
            axios.get(`${process.env.VUE_APP_API_URL}/getmysubscriptions`).then((response) => {
                if (response.status == 200) {

                    if (response.data.all_plans !== null) {
                        this.subscriptions = response.data.all_plans;
                    }
                    if (response.data.active_normal_plan !== null) {
                        this.active_normal_plan = response.data.active_normal_plan;
                    }
                    if (response.data.active_bonus_plan !== null) {
                        this.active_bonus_plan = response.data.active_bonus_plan;
                    }
                    if (response.data.active_elite_plan !== null) {
                        this.active_elite_plan = response.data.active_elite_plan;
                    }
                    if (response.data.active_highlight_profile_plan !== null) {
                        this.highlight_profile_plan = response.data.active_highlight_profile_plan;
                    }
                    if (response.data.active_assistedservice_profile_serach_plan !== null) {
 
                        this.assisted_service_plan = response.data.active_assistedservice_profile_serach_plan;
                    }
                    if (response.data.active_personal_consulting_plan !== null) {
                        this.personal_consulting_plan = response.data.active_personal_consulting_plan;
                    }


                }
            }).catch((error) => {

            }).finally(() => {
                this.loaders.list_loader = false;
            })
        }
    },
    data: () => ({
        loaders: {
            list_loader: false,
        },
        snackbar_message: "",
        display_snackbar: false,
        snackbar_color: "deep-purple accent-4",
        subscriptions: [],
        active_bonus_plan: {

        },
        personal_consulting_plan: {
            max_duration_hours: 0,
        },
        active_elite_plan: {},
        active_normal_plan: {
            pending_contactview_allowed: 0,
            pending_express_interest_allowed: 0,
            expire_on: ""
        },
        highlight_profile_plan: {
            expire_on: ""
        },
        assisted_service_plan: {

        },
        subscription_header: [


            {
                text: 'Plan Name',
                align: 'start',
                sortable: false,
                value: 'plan_name',
            },
            {
                text: 'Plan Type',
                align: 'start',
                sortable: false,
                value: 'plan_type',
            },
            {
                text: 'Status',
                align: 'start',
                sortable: false,
                value: 'is_active',
            },
            {
                text: 'Payment Status',
                align: 'start',
                sortable: false,
                value: 'payment_status',
            },

            {
                text: 'Expire On',
                align: 'center',
                sortable: false,
                value: 'expire_on',
            },
        ],
    })

}
</script>
<style>.naruto_font {
    font-family: 'Nunito' !important;
}</style>
