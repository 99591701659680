<template>
    <v-container fluid class="pa-0 ma-0 new-landing-banner">
        <div class="banner-contentextual">
            <h3 class="find-the">Find The</h3>
            <h2 class="perfect-one">Perfect &nbsp;<span class="one-word">One</span></h2>
            <h4 class="mfu">Who is made for you</h4>
            <p class="banner-caption">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque placeat error dolores fuga autem
                consequuntur.</p>
            <div class="banner-buttons">
                <v-btn large color="#6f2836" class="rounded-md elevation-5 white--text my-4 mr-5" raised ripple depressed>
                    Register
                </v-btn>
                
                <v-btn large color="#6f2836" class="rounded-md elevation-5 white--text my-4 mr-5">
                    Learn More
                </v-btn>
            </div>
        </div>
        <img class="happy-couple-pic" :src="happy_couple_img">
        <img class="mandala-one  rotating" :src="mandala_img" />
        <img class="mandala-two rotating" :src="mandala_img" />
    </v-container>
</template>

<script>
export default {

    mounted() {
        console.log("Banner Added")
    },
    data: () => ({
        mandala_img: require('@/assets/images/mandala.png'),
        happy_couple_img:require('@/assets/images/happy_couple.png')
    }),

}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Playfair+Display:wght@400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;800&display=swap');

.new-landing-banner {
    background-color: #FFF7E8 !important;
    height: 90vh !important;
    opacity: 1 !important;
}
.banner-contentextual{
    padding-top: 8% !important;
    max-width: 755px;
    margin-left: 8%;
}
.find-the{
    font-family: 'Raleway' !important;
    font-size: 4.0rem  !important;
    font-weight: 200 !important;
    color: #6f2836 !important;
    text-shadow: 2px 2px 10px #6f2836 !important;
    padding-bottom: 0px !important;
    margin-bottom:  0px !important;
    
}
.perfect-one{
    font-family: 'Raleway' !important;
    font-size: 5.5rem  !important;
    font-weight: 800 !important;
    color: #6f2836 !important;
    padding-bottom: 0px !important;
    margin-bottom:  0px !important;
    padding-top: 0px !important;
    margin-top: -7% !important;
}
.one-word{
    font-family: 'Great Vibes' !important;
    font-size: 7.5rem  !important;
    font-weight: 900 !important;
    color: #6f2836 !important;
    padding-top: 0px !important;
    margin-top: -7% !important;
}
.mfu{
    font-family: 'Raleway' !important;
    font-size: 2.5rem  !important;
    font-weight: 200 !important;
    color: #6f2836 !important;
    text-shadow: 2px 2px 5px #6f2836 !important;
    padding-bottom: 0px !important;
    margin-bottom:  6% !important;
    padding-top: 0px !important;
    margin-top: -7% !important;
}
.banner-caption{
    font-family: 'Raleway' !important;
    font-size: 1.3rem  !important;
    font-weight: 400 !important;
    color: #6f2836 !important;
}
.banner-buttons{
    padding-top: 10% !important;
}
.mandala-one {
    display: block;
    position: relative !important;
    z-index: 0 !important;
    top: -81% !important;
    opacity: 0.2 !important;
    right: -73% !important;
}
.happy-couple-pic{
    display: block;
    position: absolute !important;
    z-index: 1 !important;
    height: 1000px !important;
    top: 5.8% !important;
    right: 5% !important;
}
.mandala-two {
    z-index: 0 !important;
    position: relative !important;
    opacity: 0.2 !important;
    left: -27.0% !important;
    top: -78% !important;
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes rotating {
    from {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}

.rotating {
    -webkit-animation: rotating 100s linear infinite;
    -moz-animation: rotating 100s linear infinite;
    -ms-animation: rotating 100s linear infinite;
    -o-animation: rotating 100s linear infinite;
    animation: rotating 100s linear infinite;
}

.sign-in-form {
    z-index: 9 !important;
    padding-top: 100px;
    display: block !important;
    position: absolute !important;
    top: 10% !important;
    right: 10% !important;

}
</style>