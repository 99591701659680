<template>
    <v-container fluid class="pa-0">
        <v-skeleton-loader
            elevation="10"
            class="mb-5"
            type="card-heading,article, actions"
            v-if="data_loader"
        ></v-skeleton-loader>
        <v-row v-else>
            <v-col xl="11" lg="12" sm="12" cols="12"  v-if="user_basic_information.is_verified" class="ma-auto">
                <v-alert color="green" dismissible elevation="16" type="success">
                    This Profile Has been Verified
                </v-alert>
            </v-col>
            <v-col lg="12" cols="12">
                <v-card class="" outlined>
                    <ValidationObserver ref="prospectform" v-slot="{ handleSubmit }">
                        <v-form @submit.prevent="handleSubmit(updateUserAboutProspect)">
                            <v-card-title>
                                <h4>Prospect</h4>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <ValidationProvider name="Prospect" rules="required|max:500" v-slot="{ errors }">
                                    <tiptap-vuetify v-model="user_basic_information.about_prospect"
                                        :extensions="extenstions" placeholder="Prospect"
                                        :error-messages="errors" />
                                </ValidationProvider>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn class="orange-gradient white--text text-capitalize font-weight-bold pl-3 pr-3"
                                    type="submit" medium :loading="loaders.prospect_loader"
                                     dense>Update</v-btn>
                            </v-card-actions>
                        </v-form>
                    </ValidationObserver>
                </v-card>

            </v-col>
            <v-col lg="12" cols="12">
                <v-card class="" outlined>
                    <ValidationObserver ref="basicform" v-slot="{ handleSubmit }">
                        <v-form @submit.prevent="handleSubmit(updateUserBasicInformation)">
                            <v-card-title>
                                <h4>Basic Information</h4>
                            </v-card-title>
                            <v-card-subtitle>
                                <h5 class="blue-grey--text text-right">Profile Create For :<span class="pl-2 pr-2">{{
                                    user_basic_information.profile_created_by
                                }}</span>
                                    <span class="pa-2">|</span>
                                    Member ID :<span class="pl-2 pr-2">{{
                                        user_basic_information.profile_id
                                    }}</span>
                                </h5>
                            </v-card-subtitle>
                            <v-divider></v-divider>
                            <v-card-text>

                                <v-row>
                                    <v-col lg="2" cols="4" sm="2" md="2">
                                        <ValidationProvider name="Initials" rules="required" v-slot="{ errors }">
                                            <v-select :items="initials" label="Initials" outlined dense
                                                :error-messages="errors"
                                                v-model="user_basic_information.initials"></v-select>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col lg="3" cols="8" sm="4" md="3">

                                        <v-text-field label="First Name" outlined dense
                                            v-model="user_basic_information.first_name" disabled></v-text-field>
                                    </v-col>
                                    <v-col lg="3" cols="8" sm="4" md="3">
                                        <ValidationProvider name="Middle Name" rules="required|alpha" v-slot="{ errors }">
                                            <v-text-field label="Middle Name" outlined dense :error-messages="errors"
                                                v-model="user_basic_information.middle_name"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col lg="3" cols="8" sm="4" md="3">

                                        <v-text-field label="Last Name" outlined dense
                                            v-model="user_basic_information.last_name" disabled></v-text-field>

                                    </v-col>
                                    <v-col lg="2" cols="8" sm="4" md="3">

                                        <v-text-field label="Gender" outlined dense v-model="user_basic_information.gender"
                                            disabled></v-text-field>

                                    </v-col>
                                    <v-col lg="3" cols="8" sm="4" md="3">
                                        <ValidationProvider name="Mother Name" rules="required|alpha" v-slot="{ errors }">
                                            <v-text-field label="Mother Name" outlined dense :error-messages="errors"
                                                v-model="user_basic_information.mother_name"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col lg="3" cols="8" sm="4" md="3">

                                        <v-text-field label="Date of Birth" outlined dense
                                            v-model="user_basic_information.date_of_birth" :value="birthDate" type="date"
                                            disabled></v-text-field>

                                    </v-col>
                                    <v-col lg="3" cols="8" sm="4" md="3">
                                        <ValidationProvider name="Maritial Status" rules="required" v-slot="{ errors }">
                                            <v-select :items="maritial_status" label="Maritial Status" outlined dense
                                                :error-messages="errors"
                                                v-model="user_basic_information.maritial_status"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn class="orange-gradient white--text text-capitalize font-weight-bold pl-3 pr-3" medium
                                    dense type="submit" :loading="loaders.basic_info_loader">Update</v-btn>
                            </v-card-actions>
                        </v-form>
                    </ValidationObserver>
                </v-card>
            </v-col>
            <v-col lg="12" cols="12">
                <v-card class="" outlined>
                    <ValidationObserver ref="perosnalform" v-slot="{ handleSubmit }">
                        <v-form @submit.prevent="handleSubmit(updaterUserPersonalInformation)">
                            <v-card-title>
                                <h4>Physical Attributes</h4>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-row>
                                    <v-col lg="3" cols="12" sm="4" md="3">
                                        <ValidationProvider name="Height" rules="required" v-slot="{ errors }">
                                            <v-select :items="heightItems" outlined dense label="Height"
                                                :error-messages="errors"
                                                v-model="user_personal_information.height"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col lg="3" cols="12" sm="4" md="2">
                                        <ValidationProvider name="Blood Group" rules="required" v-slot="{ errors }">
                                            <v-select :items="blood_groups" outlined dense label="Blood Group"
                                                :error-messages="errors"
                                                v-model="user_personal_information.blood_group"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4" lg="3">
                                        <ValidationProvider name="Body Type" rules="required" v-slot="{ errors }">
                                            <v-select :items="body_type" outlined dense label="Body Type"
                                                :error-messages="errors"
                                                v-model="user_personal_information.body_type"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4" lg="3">
                                        <ValidationProvider name="Skin Complexity" rules="required" v-slot="{ errors }">
                                            <v-select :items="skin_complexity" outlined dense label="Skin Complexity"
                                                :error-messages="errors"
                                                v-model="user_personal_information.skin_complexity"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col lg="6" cols="12" sm="4" md="4">
                                        <ValidationProvider name="Health Information" rules="" v-slot="{ errors }">
                                            <v-combobox :items="health_information" multiple small-chips outlined dense
                                                :error-messages="errors" label="Health Information"
                                                v-model="user_personal_information.health_information"></v-combobox>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col cols="12" sm="4" md="4" lg="6">
                                        <ValidationProvider name="Hobbies" rules="" v-slot="{ errors }">
                                            <v-combobox :items="hobbies" outlined dense label="Hobbies" small-chips multiple
                                                :error-messages="errors"
                                                v-model="user_personal_information.hobbies"></v-combobox>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col cols="12" sm="4" md="3" lg="12">
                                        <ValidationProvider name="Hobbies" rules="required" v-slot="{ errors }">
                                            <v-radio-group row v-model="user_personal_information.is_physically_disabled"
                                                name="Physically Disabled" :error-messages="errors">
                                                <span class="pr-10"> Physically Disabled
                                                </span>
                                                <v-radio key="Yes" :value="1" label="Yes"></v-radio><v-radio key="No"
                                                    :value="0" label="No"></v-radio>
                                            </v-radio-group>
                                        </ValidationProvider>

                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn class="orange-gradient white--text text-capitalize font-weight-bold pl-3 pr-3" medium
                                    :loading="loaders.personal_info_loader" :disabled="loaders.personal_info_loader" dense
                                    type="submit">Update</v-btn>
                            </v-card-actions>
                        </v-form>
                    </ValidationObserver>
                </v-card>
            </v-col>
            <v-col lg="12" cols="12">

                <v-card class="" outlined>
                    <ValidationObserver ref="familyform" v-slot="{ handleSubmit }">
                        <v-form @submit.prevent="handleSubmit(updateUserFamilyInformation)">
                            <v-card-title>
                                <h4>Family Information</h4>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-row>
                                    <v-col lg="12" cols="12">
                                        <h4 class="pa-3">Father Information</h4>
                                        <v-divider class="pa-3"></v-divider>
                                        <v-row>
                                            <v-col lg="3" cols="8" sm="4" md="3">
                                                <ValidationProvider name="Father Living Status" rules="required"
                                                    v-slot="{ errors }">
                                                    <v-select :items="father_status" outlined dense
                                                        label="Father Living Status"
                                                        v-model="user_family_information.father_living_status"
                                                        :error-messages="errors"></v-select>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col lg="4" cols="8" sm="4" md="4"
                                                v-if="user_family_information.father_living_status == 'Employed'">
                                                <ValidationProvider name="Father Employeed With" rules="required"
                                                    v-slot="{ errors }">
                                                    <v-text-field outlined dense label="Father Employeed With"
                                                        v-model="user_family_information.father_employed_with"
                                                        :error-messages="errors"></v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col lg="4" cols="8" sm="4" md="4"
                                                v-if="user_family_information.father_living_status == 'Employed'">
                                                <ValidationProvider name="Father Employeed As" rules="required"
                                                    v-slot="{ errors }">
                                                    <v-text-field outlined dense label="Father Employeed As"
                                                        v-model="user_family_information.father_employed_as"
                                                        :error-messages="errors"></v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col lg="4" cols="8" sm="4" md="4"
                                                v-if="user_family_information.father_living_status == 'Business'">
                                                <ValidationProvider name="Father Nature of Business" rules="required"
                                                    v-slot="{ errors }">
                                                    <v-text-field outlined dense label="Father Nature of Business"
                                                        v-model="user_family_information.father_nature_of_business"
                                                        :error-messages="errors"></v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col lg="4" cols="8" sm="4" md="4"
                                                v-if="user_family_information.father_living_status == 'Retired'">
                                                <ValidationProvider name="Father Retired From" rules="required"
                                                    v-slot="{ errors }">
                                                    <v-text-field outlined dense label="Father Retired From"
                                                        v-model="user_family_information.father_retired_from"
                                                        :error-messages="errors"></v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col lg="4" cols="8" sm="4" md="4"
                                                v-if="user_family_information.father_living_status == 'Retired'">
                                                <ValidationProvider name="Father Retired As" rules="required"
                                                    v-slot="{ errors }">
                                                    <v-text-field outlined dense label="Father Retired As"
                                                        :error-messages="errors"
                                                        v-model="user_family_information.father_retired_as"></v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col lg="12" cols="12">
                                        <h4 class="pa-3">Mother Information</h4>
                                        <v-divider class="pa-3"></v-divider>
                                        <v-row>
                                            <v-col lg="3" cols="8" sm="4" md="3">
                                                <ValidationProvider name="Mother Living Status" rules="required"
                                                    v-slot="{ errors }">
                                                    <v-select :items="mother_status" outlined dense
                                                        label="Mother Living Status" :error-messages="errors"
                                                        v-model="user_family_information.mother_living_status"></v-select>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col lg="4" cols="8" sm="4" md="4"
                                                v-if="user_family_information.mother_living_status == 'Employed'">
                                                <ValidationProvider name="Mother Employeed With" rules="required"
                                                    v-slot="{ errors }">

                                                    <v-text-field outlined dense label="Mother Employeed With"
                                                        :error-messages="errors"
                                                        v-model="user_family_information.mother_employed_with"></v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col lg="4" cols="8" sm="4" md="4"
                                                v-if="user_family_information.mother_living_status == 'Employed'">
                                                <ValidationProvider name="Mother Employeed As" rules="required"
                                                    v-slot="{ errors }">
                                                    <v-text-field outlined dense label="Mother Employeed As"
                                                        :error-messages="errors"
                                                        v-model="user_family_information.mother_employed_as"></v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col lg="4" cols="8" sm="4" md="4"
                                                v-if="user_family_information.mother_living_status == 'Business'">
                                                <ValidationProvider name="Mother Nature of Business" rules="required"
                                                    v-slot="{ errors }">
                                                    <v-text-field outlined dense label="Mother Nature of Business"
                                                        :error-messages="errors"
                                                        v-model="user_family_information.mother_nature_of_business"></v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col lg="4" cols="8" sm="4" md="4"
                                                v-if="user_family_information.mother_living_status == 'Retired'">
                                                <ValidationProvider name="Mother Retired From" rules="required"
                                                    v-slot="{ errors }">
                                                    <v-text-field outlined dense label="Mother Retired From"
                                                        :error-messages="errors"
                                                        v-model="user_family_information.mother_retired_from"></v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col lg="4" cols="8" sm="4" md="4"
                                                v-if="user_family_information.mother_living_status == 'Retired'">
                                                <ValidationProvider name="Mother Retired As" rules="required"
                                                    v-slot="{ errors }">
                                                    <v-text-field outlined dense label="Mother Retired As"
                                                        :error-messages="errors"
                                                        v-model="user_family_information.mother_retired_as"></v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </v-col>

                                    <v-col lg="12" cols="12">
                                        <h4 class="pa-3">Siblings Information</h4>
                                        <v-divider class="pa-3"></v-divider>
                                        <v-row>
                                            <v-col lg="4" cols="12" sm="4" md="4">
                                                <ValidationProvider name="No. of Married Sisters"
                                                    rules="required|numeric|min:0" v-slot="{ errors }">
                                                    <v-select :items="numbericList" outlined dense
                                                        label="No. of Married Sisters"
                                                        v-model="user_family_information.no_of_married_sisters"
                                                        :error-messages="errors"></v-select>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col lg="4" cols="12" sm="4" md="4">
                                                <ValidationProvider name="No. of Married Brothers"
                                                    rules="required|numeric|min:0" v-slot="{ errors }">
                                                    <v-select :items="numbericList" outlined dense
                                                        label="No. of Married Brothers" :error-messages="errors"
                                                        v-model="user_family_information.no_of_married_brothers"></v-select>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col lg="4" cols="12" sm="4" md="4">
                                                <ValidationProvider name="No. of Unmarried Sisters"
                                                    rules="required|numeric|min:0" v-slot="{ errors }">
                                                    <v-select :items="numbericList" outlined dense :error-messages="errors"
                                                        label="No. of Unmarried Sisters"
                                                        v-model="user_family_information.no_of_unmarried_sisters"></v-select>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col lg="4" cols="12" sm="4" md="4">
                                                <ValidationProvider name="No. of Unmarried Brothers"
                                                    rules="required|numeric|min:0" v-slot="{ errors }">
                                                    <v-select :items="numbericList" outlined dense
                                                        label="No. of Unmarried Brothers" :error-messages="errors"
                                                        v-model="user_family_information.no_of_unmarried_brothers"></v-select>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </v-col>

                                    <v-col lg="12" cols="12">
                                        <h4 class="pa-3">Family Information</h4>
                                        <v-divider class="pa-3"></v-divider>
                                        <v-row>
                                            <v-col lg="4" cols="8" sm="6" md="4">
                                                <ValidationProvider name="Family Location" rules="" v-slot="{ errors }">
                                                    <v-text-field outlined dense label="Family Location"
                                                        v-model="user_family_information.family_location"
                                                        :error-messages="errors"></v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col lg="4" cols="8" sm="6" md="4">
                                                <ValidationProvider name="Native Place" rules="" v-slot="{ errors }">
                                                    <v-text-field outlined dense label="Native Place"
                                                        v-model="user_family_information.native_place"
                                                        :error-messages="errors"></v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col lg="4" cols="8" sm="6" md="4">
                                                <ValidationProvider name="Living with Family" rules="" v-slot="{ errors }">
                                                    <v-checkbox outlined dense label="Living with Family"
                                                        v-model="user_family_information.living_with_family"
                                                        :error-messages="errors"></v-checkbox>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col lg="2" cols="8" sm="6" md="2">
                                                <ValidationProvider name="Family Type" rules="" v-slot="{ errors }">
                                                    <v-select :items="family_type" outlined dense label="Family Type"
                                                        v-model="user_family_information.family_type"
                                                        :error-messages="errors"></v-select>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col lg="2" cols="8" sm="6" md="2">
                                                <ValidationProvider name="Family Value" rules="" v-slot="{ errors }">
                                                    <v-select :items="family_value" outlined dense label="Family Value"
                                                        v-model="user_family_information.family_value"
                                                        :error-messages="errors"></v-select>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col lg="2" cols="8" sm="6" md="2">
                                                <ValidationProvider name="Family Affluence" rules="" v-slot="{ errors }">
                                                    <v-select :items="family_affluence" outlined dense
                                                        :error-messages="errors" label="Family Affluence"
                                                        v-model="user_family_information.family_affluence"></v-select>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn class="orange-gradient white--text text-capitalize font-weight-bold pl-3 pr-3" medium
                                    :loading="loaders.family_info_loader" :disabled="loaders.family_info_loader" dense
                                    type="submit">Update</v-btn>
                            </v-card-actions>
                        </v-form>
                    </ValidationObserver>
                </v-card>
            </v-col>
            <v-col lg="12" cols="12">
                <v-card class="" outlined>
                    <ValidationObserver ref="religionform" v-slot="{ handleSubmit }">
                        <v-form @submit.prevent="handleSubmit(updateUserReligiousInformation)">
                            <v-card-title>
                                <h4>Religious Information</h4>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-row>
                                    <v-col lg="4" cols="8" sm="4" md="4">
                                        <ValidationProvider name="Religion" rules="required" v-slot="{ errors }">
                                            <v-select :items="religions" label="Religion" outlined dense disabled
                                                :error-messages="errors" :loading="loaders.religion_loader"
                                                @change="filterCaste"
                                                v-model="user_religious_information.religion"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col lg="4" cols="8" sm="4" md="4">
                                        <ValidationProvider name="Caste" rules="" v-slot="{ errors }">
                                            <v-select :items="filteredcastes" label="Caste" outlined dense
                                                :error-messages="errors" :loading="loaders.caste_loader"
                                                v-model="user_religious_information.caste"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col lg="4" cols="8" sm="4" md="4">
                                        <ValidationProvider name="Mother Tongue" rules="required" v-slot="{ errors }">
                                            <v-select :items="mother_tongues" label="Mother Tongue" outlined dense
                                                :error-messages="errors" :loading="loaders.mother_tongues_loader"
                                                v-model="user_religious_information.mother_tongue"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col lg="4" cols="8" sm="4" md="4">
                                        <ValidationProvider name="Gotra" rules="required" v-slot="{ errors }">
                                            <v-select :items="gotras" item-value="text" item-text="text" label="Gotra"
                                                :error-messages="errors" outlined dense :loading="loaders.gotras_loader"
                                                v-model="user_religious_information.gotra"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="12">
                                        <ValidationProvider name="Gotra" rules="required" v-slot="{ errors }">
                                            <v-radio-group row v-model="user_religious_information.is_caste_nobar"
                                                :error-messages="errors" label="Caste No Bar For Partner">

                                                <v-radio key="Yes" :value="1" label="Yes"></v-radio><v-radio key="No"
                                                    :value="0" label="No"></v-radio>
                                            </v-radio-group>
                                        </ValidationProvider>
                                    </v-col>

                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn class="orange-gradient white--text text-capitalize font-weight-bold pl-3 pr-3" medium
                                    :loading="loaders.religious_info_loader" :disabled="loaders.religious_info_loader" dense
                                    type="submit">Update</v-btn>
                            </v-card-actions>
                        </v-form>
                    </ValidationObserver>
                </v-card>
            </v-col>
            <v-col lg="12" cols="12">
                <v-card class="" outlined>
                    <ValidationObserver ref="socialform" v-slot="{ handleSubmit }">
                        <v-form @submit.prevent="handleSubmit(updateUserSocialInformation)">
                            <v-card-title>
                                <h4>Social Information</h4>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-row>

                                    <v-col lg="4" cols="8" sm="4" md="4">
                                        <ValidationProvider name="Eating Habbit" rules="required" v-slot="{ errors }">
                                            <v-select outlined dense label="Eating habbit" :items="diet"
                                                v-model="user_social_information.eating_habbit"
                                                :error-messages="errors"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col lg="4" cols="8" sm="4" md="4">
                                        <ValidationProvider name="Drinking Habbit" rules="required" v-slot="{ errors }">
                                            <v-select outlined dense label="Drinking Habbit" :items=habbit_status
                                                v-model="user_social_information.drinking_habbit"
                                                :error-messages="errors"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col lg="4" cols="8" sm="4" md="4">
                                        <ValidationProvider name="Smoking Habbit" rules="required" v-slot="{ errors }">
                                            <v-select outlined dense label="Smoking Habbit" :items=habbit_status
                                                v-model="user_social_information.smoking_habbit"
                                                :error-messages="errors"></v-select>
                                        </ValidationProvider>
                                    </v-col>

                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn class="orange-gradient white--text text-capitalize font-weight-bold pl-3 pr-3" medium
                                    :loading="loaders.social_info_loader" :disabled="loaders.social_info_loader" dense
                                    type="submit">Update</v-btn>
                            </v-card-actions>
                        </v-form>
                    </ValidationObserver>
                </v-card>
            </v-col>
            <v-col lg="12" cols="">
                <v-card class="" outlined>
                    <ValidationObserver ref="employeeform" v-slot="{ handleSubmit }">
                        <v-form @submit.prevent="handleSubmit(updateUserEmployeeInformation)">
                            <v-card-title>
                                <h4>Education and Profession Information</h4>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-row>
                                    <v-col lg="4" cols="12" sm="6" md="6">
                                        <ValidationProvider name="Highest Qualification" rules="required"
                                            v-slot="{ errors }">
                                            <v-autocomplete :items="educations" outlined dense label="Highest Qualification"
                                                item-value="Degree" v-model="user_employee_information.highest_education"
                                                :error-messages="errors">
                                                <template v-slot:item="data">
                                                    <v-list-item-content>
                                                        <v-list-item-title class="">{{
                                                            data.item.Degree
                                                        }}</v-list-item-title>
                                                        <v-list-item-subtitle class="">{{
                                                            data.item.category
                                                        }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                                <template v-slot:selection="data">
                                                    {{
                                                        data.item.Degree
                                                    }}
                                                </template>
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col lg="4" cols="12" sm="6" md="6">
                                        <ValidationProvider name="Specialized In" rules="required" v-slot="{ errors }">
                                            <v-text-field outlined dense label="Specialized In"
                                                v-model="user_employee_information.specialized_in"
                                                :error-messages="errors"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col lg="4" cols="12" sm="6" md="6">
                                        <ValidationProvider name="College / Institue Name" rules="required"
                                            v-slot="{ errors }">
                                            <v-text-field outlined dense label="College / Institue Name"
                                                :error-messages="errors"
                                                v-model="user_employee_information.college_name"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col lg="5" cols="12" sm="6" md="6">
                                        <ValidationProvider name="Occupation" rules="required" v-slot="{ errors }">
                                            <v-autocomplete :items="occupations" outlined dense label="Occupation"
                                                :error-messages="errors" item-value="occupation"
                                                v-model="user_employee_information.occupation">
                                                <template v-slot:item="data">
                                                    <v-list-item-content>
                                                        <v-list-item-title class="">{{
                                                            data.item.occupation
                                                        }}</v-list-item-title>
                                                        <v-list-item-subtitle class="">{{
                                                            data.item.category
                                                        }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                                <template v-slot:selection="data">
                                                    {{
                                                        data.item.occupation
                                                    }}
                                                </template>
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col lg="5" cols="12" sm="6" md="6" v-if="user_employee_information.occupation !== 'Not Working' ">
                                        <ValidationProvider name="Employee With" rules="required" v-slot="{ errors }">
                                            <v-text-field outlined dense label="Employee With"
                                                v-model="user_employee_information.employee_with"
                                                :error-messages="errors"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col lg="4" cols="12" sm="6" md="6" v-if="user_employee_information.occupation !== 'Not Working' ">
                                        <ValidationProvider name="Annual Income" rules="required" v-slot="{ errors }">
                                            <v-select :items="annual_income" outlined dense label="Annual Income"
                                                v-model="user_employee_information.annual_income"
                                                :error-messages="errors"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col lg="4" cols="12" sm="6" md="6" v-if="user_employee_information.occupation !== 'Not Working' ">
                                        <ValidationProvider name="Employee Location" rules="required|max:150"
                                            v-slot="{ errors }">
                                            <v-text-field outlined dense label="Employee Location" :error-messages="errors"
                                                v-model="user_employee_information.employee_location"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="12" v-if="user_employee_information.occupation !== 'Not Working' ">
                                        <ValidationProvider name="Keep Income Private" rules="required" v-slot="{ errors }">
                                            <v-radio-group row v-model="user_employee_information.keep_income_private"
                                                :error-messages="errors" label="Keep Income Private">

                                                <v-radio key="Yes" :value="1" label="Yes"></v-radio><v-radio key="No"
                                                    :value="0" label="No"></v-radio>
                                            </v-radio-group>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn class="orange-gradient white--text text-capitalize font-weight-bold pl-3 pr-3" medium
                                    :loading="loaders.employee_info_loader" :disabled="loaders.employee_info_loader" dense
                                    type="submit">Update</v-btn>
                            </v-card-actions>
                        </v-form>
                    </ValidationObserver>
                </v-card>
            </v-col>
            <v-col lg="12">
                <v-card class="" outlined>
                    <ValidationObserver ref="astroform" v-slot="{ handleSubmit }">
                        <v-form @submit.prevent="handleSubmit(updateUserAstroInformation)">
                            <v-card-title>
                                <h4>Astro Information</h4>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-row>
                                    <v-col lg="4" cols="12" sm="6" md="6">
                                        <ValidationProvider name="Place of Birth" rules="required" v-slot="{ errors }">
                                            <v-text-field outlined dense label="Place of Birth" :error-messages="errors"
                                                v-model="user_astro_information.place_of_birth"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col lg="4" cols="12" sm="6" md="6">

                                        <v-dialog ref="dialog" v-model="models.time_input"
                                            :return-value.sync="user_astro_information.birth_time" persistent width="290px">
                                            <template v-slot:activator="{ on, attrs }">
                                                <ValidationProvider name="Birth Time" rules="required" v-slot="{ errors }">
                                                    <v-text-field outlined dense v-model="user_astro_information.birth_time"
                                                        clearable :error-messages="errors" label="Birth Time" readonly
                                                        v-bind="attrs" v-on="on"></v-text-field>
                                                </ValidationProvider>
                                            </template>
                                            <v-time-picker v-if="models.time_input"
                                                v-model="user_astro_information.birth_time" full-width use-seconds
                                                color="orange">
                                                <v-spacer></v-spacer>
                                                <v-btn text color="orange" @click="models.time_input = false">
                                                    Cancel
                                                </v-btn>
                                                <v-btn text color="orange"
                                                    @click="$refs.dialog.save(user_astro_information.birth_time)">
                                                    OK
                                                </v-btn>
                                            </v-time-picker>
                                        </v-dialog>

                                    </v-col>
                                    <v-col lg="2" cols="12" sm="6" md="6">
                                        <ValidationProvider name="Nakshatra" rules="required" v-slot="{ errors }">
                                            <v-select :items="nakshatras" outlined dense label="Nakshatra"
                                                :error-messages="errors"
                                                v-model="user_astro_information.nakshatra"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col lg="2" cols="12" sm="6" md="6">
                                        <ValidationProvider name="Rashi" rules="required" v-slot="{ errors }">
                                            <v-select :items="rashis" outlined dense label="Rashi" :error-messages="errors"
                                                v-model="user_astro_information.rashi"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="12">
                                        <ValidationProvider name="Is Manglik" rules="required" v-slot="{ errors }">
                                            <v-radio-group row v-model="user_astro_information.is_manglik"
                                                label="Is Manglik" :error-messages="errors">

                                                <v-radio key="Yes" :value="1" label="Yes"></v-radio><v-radio key="No"
                                                    :value="0" label="No"></v-radio>
                                            </v-radio-group>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="12">
                                        <ValidationProvider name="Is Dosh Present" rules="required" v-slot="{ errors }">
                                            <v-radio-group row v-model="user_astro_information.is_doshi"
                                                label="Is Dosh Present" :error-messages="errors">

                                                <v-radio key="Yes" :value="1" label="Yes"></v-radio><v-radio key="No"
                                                    :value="0" label="No"></v-radio>
                                            </v-radio-group>
                                        </ValidationProvider>
                                    </v-col>

                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn class="orange-gradient white--text text-capitalize font-weight-bold pl-3 pr-3" medium
                                    :loading="loaders.astro_info_loader" :disabled="loaders.astro_info_loader" dense
                                    type="submit">Update</v-btn>
                            </v-card-actions>
                        </v-form>
                    </ValidationObserver>
                </v-card>
            </v-col>
            <v-col lg="12">
                <v-card class="" outlined>
                    <ValidationObserver ref="astroform" v-slot="{ handleSubmit }">
                        <v-form @submit.prevent="handleSubmit(updateUserPreference)">
                            <v-card-title>
                                <h4>Match Preference</h4>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-row>
                                    <v-col lg="4" cols="12" sm="6" md="6">
                                        <ValidationProvider name="Age From" rules="numeric" v-slot="{ errors }">
                                            <v-select :items="ageFrom" outlined dense label="Age From"
                                                :error-messages="errors" v-model="user_preferences.age_from"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col lg="4" cols="12" sm="6" md="6">
                                        <ValidationProvider name="Age Upto" rules="numeric" v-slot="{ errors }">
                                            <v-select :items="ageUpto" outlined dense label="Age Upto"
                                                :error-messages="errors" v-model="user_preferences.age_upto"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <!-- <v-col lg="4" cols="12" sm="6" md="6">
                                        <ValidationProvider name="Caste" rules="" v-slot="{ errors }">
                                            <v-select :items="filteredcastes" outlined dense label="Caste" :error-messages="errors"
                                                v-model="user_preferences.caste_id"></v-select >
                                        </ValidationProvider>
                                    </v-col> -->
                                    <v-col lg="4" cols="12" sm="6" md="6">
                                        <ValidationProvider name="Expected Height" rules="" v-slot="{ errors }">
                                            <v-select :items="heightItemsPreference" outlined dense label="Expected Height"
                                                :error-messages="errors"
                                                v-model="user_preferences.expected_height"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col lg="6" cols="12" sm="12" md="8">
                                        <ValidationProvider name="Caste Preferences" rules="" v-slot="{ errors }">
                                            <v-select :items="filteredcastes" multiple chips outlined dense
                                                label="Caste Preferences" :error-messages="errors"
                                                v-model="user_preferences.caste_preferences"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col lg="4" cols="12" sm="6" md="6">
                                        <ValidationProvider name="Family Affluence" rules="" v-slot="{ errors }">
                                            <v-select :items="family_affluence" outlined dense label="Family Affluence"
                                                :error-messages="errors"
                                                v-model="user_preferences.family_affluence"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col lg="4" cols="12" sm="6" md="6">
                                        <ValidationProvider name="Family Value" rules="" v-slot="{ errors }">
                                            <v-select :items="family_value" outlined dense label="Family Value"
                                                :error-messages="errors" v-model="user_preferences.family_value"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col lg="4" cols="12" sm="6" md="6">
                                        <ValidationProvider name="Family Type" rules="" v-slot="{ errors }">
                                            <v-select :items="family_type" outlined dense label="Family Type"
                                                :error-messages="errors" v-model="user_preferences.family_type"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col lg="4" cols="12" sm="6" md="6">
                                        <ValidationProvider name="Eating Habbit" rules="" v-slot="{ errors }">
                                            <v-select :items="diet" outlined dense label="Eating Habbit"
                                                :error-messages="errors"
                                                v-model="user_preferences.eating_habbit"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col lg="4" cols="12" sm="6" md="6">
                                        <ValidationProvider name="Smoking Habbit" rules="" v-slot="{ errors }">
                                            <v-select :items="habbit_status" outlined dense label="Smoking Habbit"
                                                :error-messages="errors"
                                                v-model="user_preferences.smoking_habbit"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col lg="4" cols="12" sm="6" md="6">
                                        <ValidationProvider name="Drinking Habbit" rules="" v-slot="{ errors }">
                                            <v-select :items="habbit_status" outlined dense label="Drinking Habbit"
                                                :error-messages="errors"
                                                v-model="user_preferences.drinking_habbit"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col lg="4" cols="12" sm="6" md="6">
                                        <ValidationProvider name="Highest Qualification" rules="" v-slot="{ errors }">
                                            <v-autocomplete :items="educations" outlined dense label="Highest Qualification"
                                                item-value="Degree" v-model="user_preferences.highest_education"
                                                :error-messages="errors">
                                                <template v-slot:item="data">
                                                    <v-list-item-content>
                                                        <v-list-item-title class="">{{
                                                            data.item.Degree
                                                        }}</v-list-item-title>
                                                        <v-list-item-subtitle class="">{{
                                                            data.item.category
                                                        }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                                <template v-slot:selection="data">
                                                    {{
                                                        data.item.Degree
                                                    }}
                                                </template>
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col lg="4" cols="12" sm="6" md="6">
                                        <ValidationProvider name="Annual Income" rules="" v-slot="{ errors }">
                                            <v-select :items="annual_income" outlined dense label="Annual Income"
                                                :error-messages="errors"
                                                v-model="user_preferences.annual_income"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="12">
                                        <ValidationProvider name="Is Manglik" rules="" v-slot="{ errors }">
                                            <v-radio-group row v-model="user_preferences.is_manglik" label="Is Manglik"
                                                :error-messages="errors">

                                                <v-radio key="Yes" :value="1" label="Yes"></v-radio><v-radio key="No"
                                                    :value="0" label="No"></v-radio>
                                            </v-radio-group>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="3" lg="12">
                                        <ValidationProvider name="Is Dosh Present" rules="" v-slot="{ errors }">
                                            <v-radio-group row v-model="user_preferences.is_dosh_present"
                                                label="Is Dosh Present" :error-messages="errors">

                                                <v-radio key="Yes" :value="1" label="Yes"></v-radio><v-radio key="No"
                                                    :value="0" label="No"></v-radio>
                                            </v-radio-group>
                                        </ValidationProvider>
                                    </v-col>

                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn class="orange-gradient white--text text-capitalize font-weight-bold pl-3 pr-3" medium
                                    :loading="loaders.user_preferences_loaders" :disabled="loaders.user_preferences_loaders"
                                    dense type="submit">Update</v-btn>
                            </v-card-actions>
                        </v-form>
                    </ValidationObserver>
                </v-card>
            </v-col>
            <v-col lg="12" cols="12">
                <v-btn class="orange-gradient white--text text-capitalize font-weight-bold pl-3 pr-3 float-right" medium
                    debse href="/preview-profile"> Preview Profile</v-btn>
            </v-col>
        </v-row>
        <v-snackbar :timeout="3000" v-model="snackbar" top color="indigo" class="black--text">
            Information updated Successfully.
        </v-snackbar>
    </v-container>
</template>
<script>
import axios from 'axios';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
export default {
    watch: {
        'user_religious_information.religion': {
            handler(newValue) {

                this.filterCaste();
            },
            immediate: true,
        }
    },


    computed: {

        heightItems: function () {
            let height_item = [];
            let start_feet = 4;
            let start_inch = 0;
            for (let i = start_feet; i <= 7; i++) {
                for (let j = start_inch; j <= 12; j++) {
                    height_item.push(i + "ft " + j + "in");
                }
            }
            return height_item;
        },
        heightItemsPreference: function () {
            let height_item = [];
            let start_feet = 4;
            let start_inch = 0;
            for (let i = start_feet; i <= 7; i++) {
                for (let j = start_inch; j <= 12; j++) {
                    height_item.push(i + "ft " + j + "in and Above");
                }
            }
            return height_item;
        },
        numbericList: function () {
            let list = [];
            for (let i = 0; i < 10; i++) {
                list.push(i);
            }
            return list;
        },
        ageFrom: function () {
            let list = [];
            for (let i = 21; i < 80; i++) {
                list.push(i);
            }
            return list;
        },
        ageUpto: function () {
            let list = [];
            let j = 21;

            if (this.user_preferences.age_from != null) {
                j = this.user_preferences.age_from;
            }
            for (let i = j; i < 80; i++) {
                list.push(i);
            }
            return list;
        },

    },
    mounted() {

    },
    created() {
        this.getAllListData();



    },
    methods: {
        birthDate: function () {
            if (this.user_basic_information.date_of_birth !== null) {
                const [year, month, day] = this.user_basic_information.date_of_birth.split("-");
                return `${day}/${month}/${year}`;
            }

            return null;
        },
        filterCaste: function () {

            this.filteredcastes = this.castes.filter(caste => caste.religion_id == this.user_religious_information.religion)

        },
        getUserDetails() {
            this.data_loader = true;
            axios.get(`${process.env.VUE_APP_API_URL}/getuserdetails`).then((response) => {
                if (response.status == 200) {
                    if (response.data.user !== null) {

                        this.user_basic_information = response.data.user;
                    }
                    if (response.data.user.user_personal_information !== null) {
                        this.user_personal_information = response.data.user.user_personal_information;
                    }
                    if (response.data.user.user_family_information !== null) {
                        this.user_family_information = response.data.user.user_family_information;
                    }
                    if (response.data.user.user_employee_information !== null) {
                        this.user_employee_information = response.data.user.user_employee_information;
                    }
                    if (response.data.user.user_social_information !== null) {
                        this.user_social_information = response.data.user.user_social_information;
                    }
                    if (response.data.user.user_religion_information !== null) {
                        this.user_religious_information = response.data.user.user_religion_information;
                        this.filterCaste();
                    }
                    if (response.data.user.user_astro_information !== null) {
                        this.user_astro_information = response.data.user.user_astro_information;
                    }

                    if (response.data.user.user_preference !== null) {
                        this.user_preferences = response.data.user.user_preference;
                    }

                }
            }).catch((error) => {
                console.log(error);
            }).finally(()=>{
                this.data_loader = false;
            });
        },
        getAllListData() {
            this.loaders.education_loader = true;
            this.loaders.mother_tongues_loader = true;
            this.loaders.religion_loader = true;
            this.loaders.caste_loader = true;
            this.loaders.gotras_loader = true;
            this.loaders.occupation_loader = true;
            axios.get(`${process.env.VUE_APP_API_URL}/getalllistdata`).then((response) => {
                if (response.status == 200) {
                    this.religions = response.data.religions;
                    this.castes = response.data.caste;
                    this.educations = response.data.educations;
                    this.mother_tongues = response.data.mother_tongues;
                    this.gotras = response.data.gotras;
                    this.nakshatras = response.data.nakshatras;
                    this.rashis = response.data.rashis;
                    this.occupations = response.data.occupations;
                    this.getUserDetails();
                }
            }).catch((error) => {
            }).finally(() => {
                this.loaders.education_loader = false;
                this.loaders.mother_tongues_loader = false;
                this.loaders.religion_loader = false;
                this.loaders.caste_loader = false;
                this.loaders.gotras_loader = false;
                this.loaders.occupation_loader = false;
            });
        },
        updateUserBasicInformation() {
            this.loaders.basic_info_loader = true;
            axios.post(`${process.env.VUE_APP_API_URL}/updateuserbasicinformation`, this.user_basic_information).then((response) => {
                if (response.status == 200) {
                    this.snackbar = true;
                }
            }).catch((error) => {
                if (error.response) {

                    if (error.response.status == 422) {
                        this.$refs.basicform.setErrors({
                            'Initials': error.response.data.errors.initials,
                            'Middle Name': error.response.data.errors.middle_name,
                            'Mother Name': error.response.data.errors.mother_name,
                            'Maritial Status': error.response.data.errors.maritial_status,
                        });
                    }
                }
            }).finally(() => {
                this.loaders.basic_info_loader = false;

            });
        },
        updaterUserPersonalInformation() {
            this.loaders.personal_info_loader = true;
            axios.post(`${process.env.VUE_APP_API_URL}/updateuserpersonalinformation`, this.user_personal_information).then((response) => {
                if (response.status == 200) {
                    this.snackbar = true;
                }
            }).catch((error) => {
                if (error.response) {

                    if (error.response.status == 422) {
                        this.$refs.perosnalform.setErrors({
                            'Height': error.response.data.errors.height,
                            'Blood Group': error.response.data.errors.blood_group,
                            'Body Type': error.response.data.errors.body_type,
                            'Skin Complexity': error.response.data.errors.skin_complexity,
                            'Physically Disabled': error.response.data.errors.is_physically_disabled,
                            'health_information': error.response.data.errors.health_information,
                            'hobbies': error.response.data.errors.hobbies,
                        });
                    }
                }
            }).finally(() => {
                this.loaders.personal_info_loader = false;
            });
        },
        updateUserFamilyInformation() {
            this.loaders.family_info_loader = true;
            axios.post(`${process.env.VUE_APP_API_URL}/updateuserfamilyinformation`, this.user_family_information).then((response) => {
                if (response.status == 200) {
                    this.snackbar = true;
                }
            }).catch((error) => {
                if (error.response) {

                    if (error.response.status == 422) {
                        this.$refs.familyform.setErrors({
                            'Father Living Status': error.response.data.errors.father_living_status,
                            'Father Employeed With': error.response.data.errors.father_employed_with,
                            'Father Employeed As': error.response.data.errors.father_employed_as,
                            'Father Retired From': error.response.data.errors.father_retired_from,
                            'Father Retired As': error.response.data.errors.father_retired_as,
                            'Father Nature of Business': error.response.data.errors.father_nature_of_business,
                            'Mother Living Status': error.response.data.errors.mother_living_status,
                            'Mother Employeed With': error.response.data.errors.mother_employed_with,
                            'Mother Employeed As': error.response.data.errors.mother_employed_as,
                            'Mother Retired From': error.response.data.errors.mother_retired_from,
                            'Mother Retired As': error.response.data.errors.mother_retired_as,
                            'Mother Nature of Business': error.response.data.errors.mother_nature_of_business,
                            'No. of Married Sisters': error.response.data.errors.no_of_married_sisters,
                            'No. of Married Brothers': error.response.data.errors.no_of_married_brothers,
                            'No. of Unmarried Sisters': error.response.data.errors.no_of_unmarried_sisters,
                            'No. of Unmarried Brothers': error.response.data.errors.no_of_unmarried_brothers,
                            'Family Location': error.response.data.errors.family_location,
                            'Native Place': error.response.data.errors.native_place,
                            'Living With Family': error.response.data.errors.living_with_family,
                            'Family Type': error.response.data.errors.family_type,
                            'Family Value': error.response.data.errors.family_value,
                            'Family Affluence': error.response.data.errors.family_affluence,

                        });
                    }
                }
            }).finally(() => {
                this.loaders.family_info_loader = false;
            });
        },
        updateUserReligiousInformation() {
            this.loaders.religious_info_loader = true;
            axios.post(`${process.env.VUE_APP_API_URL}/updateuserreligiousinformation`, this.user_religious_information).then((response) => {
                if (response.status == 200) {
                    this.snackbar = true;
                }
            }).catch((error) => {
                if (error.response) {

                    if (error.response.status == 422) {
                        this.$refs.religionform.setErrors({
                            // 'Religion': error.response.data.errors.religion,
                            'Caste': error.response.data.errors.caste,
                            'Mother Tongue': error.response.data.errors.mother_tongue,
                            'Gotra': error.response.data.errors.gotra,
                        });
                    }
                }
            }).finally(() => {
                this.loaders.religious_info_loader = false;
            });
        },
        updateUserSocialInformation() {
            this.loaders.social_info_loader = true;
            axios.post(`${process.env.VUE_APP_API_URL}/updateusersocialinformation`, this.user_social_information).then((response) => {
                if (response.status == 200) {
                    this.snackbar = true;
                }
            }).catch((error) => {
                if (error.response) {

                    if (error.response.status == 422) {
                        this.$refs.socialform.setErrors({
                            'Drinking Habbit': error.response.data.errors.drinking_habbit,
                            'Eating Habbit': error.response.data.errors.eating_habbit,
                            'Smoking Habbit': error.response.data.errors.smoking_habbit,

                        });
                    }
                }
            }).finally(() => {
                this.loaders.social_info_loader = false;
            });
        },
        updateUserEmployeeInformation() {
            this.loaders.employee_info_loader = true;
            axios.post(`${process.env.VUE_APP_API_URL}/updateuseremployeeinformation`, this.user_employee_information).then((response) => {
                if (response.status == 200) {
                    this.snackbar = true;
                }
            }).catch((error) => {
                if (error.response) {

                    if (error.response.status == 422) {
                        this.$refs.employeeform.setErrors({
                            'Highest Qualification': error.response.data.errors.highest_education,
                            'Specialized In': error.response.data.errors.specialized_in,
                            'College Name': error.response.data.errors.college_name,
                            'Occupation': error.response.data.errors.occupation,
                            'Employee With': error.response.data.errors.employee_with,
                            'Annual Income': error.response.data.errors.annual_income,
                            'Employee Location': error.response.data.errors.employee_location,
                            'Keep Income Private': error.response.data.errors.keep_income_private,
                        });
                    }
                }
            }).finally(() => {
                this.loaders.employee_info_loader = false;
            });
        },
        updateUserAstroInformation() {
            this.loaders.astro_info_loader = true;
            axios.post(`${process.env.VUE_APP_API_URL}/updateuserastroinformation`, this.user_astro_information).then((response) => {
                if (response.status == 200) {
                    this.snackbar = true;
                }
            }).catch((error) => {
                if (error.response) {

                    if (error.response.status == 422) {
                        this.$refs.astroform.setErrors({
                            'Place of Birth': error.response.data.errors.place_of_birth,
                            'Birth Time': error.response.data.errors.birth_time,
                            'Nakshatra': error.response.data.errors.nakshatra,
                            'Rashi': error.response.data.errors.rashi,
                            'Is Manglik': error.response.data.errors.is_manglik,
                            'Is Dosh Present': error.response.data.errors.is_doshi,
                        });
                    }
                }
            }).finally(() => {
                this.loaders.astro_info_loader = false;
            });
        },
        updateUserPreference() {
            this.loaders.user_preferences_loaders = true;
            axios.post(`${process.env.VUE_APP_API_URL}/updateuserpreferences`, this.user_preferences).then((response) => {
                if (response.status == 200) {
                    this.snackbar = true;
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status == 422) {
                        this.$refs.astroform.setErrors({
                            'Age From': error.response.data.errors.age_from,
                            'Age Upto': error.response.data.errors.age_upto,
                            'Caste': error.response.data.errors.caste_id,
                            'Expected Height': error.response.data.errors.height,
                            'Is Manglik': error.response.data.errors.is_manglik,
                            'Is Dosh Present': error.response.data.errors.is_doshi,
                            'Eating Habbit': error.response.data.errors.eating_habbit,
                            'Smoking Habbit': error.response.data.errors.smoking_habbit,
                            'Drinking Habbit': error.response.data.errors.drinking_habbit,
                            'Annual Income': error.response.data.errors.annual_income,
                            'Highest Qualification': error.response.data.errors.highest_education,
                            'Family Type': error.response.data.errors.family_type,
                            'Family Value': error.response.data.errors.family_value,
                            'Family Affluence': error.response.data.errors.family_affluence,
                        });
                    }
                }
            }).finally(() => {
                this.loaders.user_preferences_loaders = false;
            });
        },
        updateUserAboutProspect() {
            this.loaders.prospect_loader = true;
            axios.post(`${process.env.VUE_APP_API_URL}/updateuseraboutprospect`, this.user_basic_information).then((response) => {
                if (response.status == 200) {
                    this.snackbar = true;
                }
            }).catch((error) => {
                if (error.response) {

                    if (error.response.status == 422) {
                        this.$refs.prospectform.setErrors({
                            'Prospect': error.response.data.errors.about_prospect,

                        });
                    }
                }
            }).finally(() => {
                this.loaders.prospect_loader = false;
            });
        }
    },
    data() {
        return {
            data_loader:false,
            extenstions: [
                // History,
                // Blockquote,
                // Link,
                // Underline,
                // Strike,
                // Italic,
                // Bold,
                // [Heading, {
                //     options: {
                //         levels: [1, 2, 3]
                //     }
                // }],
                // Paragraph,
                // BulletList,
                // OrderedList,
                // ListItem,
                // HardBreak,
                // HorizontalRule
            ],
            loaders: {
                basic_info_loader: false,
                personal_info_loader: false,
                prospect_loader: false,
                religious_info_loader: false,
                family_info_loader: false,
                social_info_loader: false,
                employee_info_loader: false,
                astro_info_loader: false,
                religion_loader: false,
                caste_loader: false,
                mother_tongues_loader: false,
                education_loader: false,
                gotras_loader: false,
                occupation_loader: false,
                user_preferences_loaders: false,
            },
            snackbar: false,
            models: {
                time_input: false,
            },
            filteredcastes: [],
            initials: ["Mr", "Miss", "Dr", "Adv"],
            maritial_status: ["Never Married", "Widowed", "Divorced", "Awaiting Divorce"],
            gender: ["Bride", "Groom"],
            blood_groups: ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-", "Dont Know"],
            health_information: ["No Health Problems", "HIV+", "Diabetes", "Low BP", "High BP", "Heart Ailments"],
            hobbies: ["Book Reading", "Blogger", "Vlogger", "Hiking", "Traveller", "Cooking"],
            body_type: ["Slim", "Medium", "Athlete", "Heavy"],
            father_status: ["Employed", "Business", "Retired", "Not Employed", "Passed Away"],
            mother_status: ["Homemaker", "Employed", "Business", "Retired", "Not Employed", "Passed Away"],
            family_type: ["Joint", "Individual"],
            family_value: ["Traditional", "Moderate", "Liberal"],
            family_affluence: ["Affluent", "Rich", "Upper Middle Class", "Middle Class"],
            status: ["Yes", "No", "I Dont Know"],
            disabled_status: { "Yes": true, "No": false },
            nakshatras: [],
            rashis: [],
            diet: ["Veg", "Non Veg", "Ocassional Non Vegeterian", "Eggeterian", "Jain", "Vegan"],
            habbit_status: ["No", "Social", "Regular"],
            religions: [],
            castes: [],
            gotras: [],
            occupations: [],
            mother_tongues: [],
            annual_income: ["Upto INR 1 Lakh", "INR 1 Lakh to 2 Lakh", "INR 2 Lakh to 4 Lakh", "INR 4 Lakh to 7 Lakh", "INR 7 Lakh to 10 Lakh", "INR 10 Lakh to 15 Lakh", "INR 15 Lakh to 20 Lakh", "INR 20 Lakh to 30 Lakh", "INR 30 Lakh to 50 Lakh", "INR 50 Lakh to 75 Lakh", "INR 75 Lakh to 1 Crore", "INR 1 Crore above", "Not applicable",
            ],
            skin_complexity: ["Very fair", "Fair", "Wheatish", "Dark"],
            educations: [],
            user_basic_information: {
                initials: "",
                profile_id: "",
                profile_created_by: "",
                first_name: "",
                middle_name: "",
                last_name: "",
                mother_name: "",
                date_of_birth: "",
                maritial_status: "",
                gender: "",
                dial_code: "",
                about_prospect: "",
                is_verified: "",
                profile_picture: "",
                is_elite: "",
            },
            user_personal_information: {
                height: "",
                health_information: [],
                blood_group: "",
                hobbies: [],
                is_physically_disabled: false,
                body_type: "",
                skin_complexity: '',
            },
            user_family_information: {
                father_living_status: "",
                father_employed_with: "",
                father_employed_as: "",
                father_nature_of_business: "",
                father_retired_from: "",
                father_retired_as: "",
                mother_living_status: "",
                mother_employed_with: "",
                mother_employed_as: "",
                mother_nature_of_business: "",
                mother_retired_from: "",
                mother_retired_as: "",
                family_location: "",
                native_place: "",
                living_with_family: "",
                no_of_married_sisters: 0,
                no_of_married_brothers: 0,
                no_of_unmarried_sisters: 0,
                no_of_unmarried_brothers: 0,
                family_value: "",
                family_affluence: "",
            },
            user_religious_information: {
                religion: "",
                caste: "",
                mother_tongue: "",
                gotra: "",
                is_caste_nobar: "",
            },
            user_employee_information: {
                highest_education: "",
                specialized_in: "",
                college_name: "",
                occupation: "",
                employee_with: "",
                annual_income: "",
                employee_location: "",
                keep_income_private: 0,
            },
            user_social_information: {
                eating_habbit: "",
                smoking_habbit: "",
                drinking_habbit: "",
            },
            user_astro_information: {
                birth_time: "",
                is_manglik: "",
                place_of_birth: "",
                is_doshi: "",
                nakshatra: "",
                rashi: "",
            },
            user_preferences: {
                caste_preferences: [],
                is_manglik: false,
                is_dosh_present: false,
                family_value: 'Any',
                family_affluence: 'Any',
                family_type: 'Any',

                age_from: 18,
                age_upto: 80,
                eating_habbit: 'Any',
                smoking_habbit: 'Any',
                highest_education: 'Any',
                annual_income: 'Any',
                expected_height: 'Any',
            }
        };
    },
    components: { ValidationObserver, ValidationProvider, TiptapVuetify, }
}
</script>
<style scoped>.orange-gradient {
    background: linear-gradient(225deg, rgba(253, 44, 121, 1) 0%, rgba(253, 101, 91, 1) 100%) !important;
    color: #fff !important;
}</style>

