<template>
    <div>
        <v-container fluid class="blog-hero-section">
            <div class="marriage-quotes">
                <p class="quote text-capitalized">{{ marriageQuote.quote }}</p>
                <p class="author float-right">--By {{ marriageQuote.author }}</p>
            </div>
        </v-container>
<v-container class="mt-12">
        <v-row>
            <v-col v-for="(blog, index) in blogs" :key="index" cols="12" sm="12" md="4" lg="4" xl="3" >
                <v-card class="elevation-7 blog-card blog_item">
                    <v-img class="" max-height="400" min-height="300"
                        :src="blogImage()"></v-img>
                    <v-card-title class="text-capitalize">
                        {{getShortendBlogTitle(blog.title)}}
                    </v-card-title>
                    <v-card-text>
                        {{ blog.quote }}
                    </v-card-text>
                    <v-card-actions class="text-right">
                        <router-link :to="{name:'Blog',params:{blog_id:blog.slug_title}}">
                        <v-btn color="primary" small class="pa-3 text-capitalized text-right"  text>Read More</v-btn>
                        </router-link>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        </v-container>
        <FooterSection></FooterSection>
    </div>
</template>
<script>
import FooterSection from '@/components/Global/FooterSection.vue';
import axios from 'axios';

export default {
    computed: {
        marriageQuote() {
            return this.marraige_quotes[Math.floor(Math.random() * this.marraige_quotes.length)];
        },
        backgroundImage() {
            return Math.floor(Math.random() * 10);
        },
        
       
    },
    mounted() {
     
        if (typeof this.$redrawVueMasonry === "function") {
            this.$redrawVueMasonry();
        }
    },
    created(){
        this.getAllBlogs();
    },  
    methods:{
        getAllBlogs:function(){
            axios.get(`${process.env.VUE_APP_API_URL}/getallblogs`).then((response)=>{
                    if(response.status == 200){
                        this.blogs = response.data.blogs;

                    }
            }).catch((error)=>{

            }).finally(()=>{

            })
        },
        getShortendBlogTitle(title){
            return title.length > 50 ? (title.substring(0, 30) + '...') : title ;
        },
        blogImage(){
            return this.blog_img[Math.floor(Math.random()*this.blog_img.length)];
        }
    },
    data: () => ({
        blog_img: [require("@/assets/images/blog_one.jpeg"),require("@/assets/images/hero-background.jpg"),require("@/assets/images/blog_img_2.jpg"),require("@/assets/images/4.jpg"),require("@/assets/images/img_4.jpg"),require("@/assets/images/4.jpg"),require("@/assets/images/5.jpg")],
        blogs:[],
        marraige_quotes: [
            {
                quote: "There is no such cozy combination as man and wife.",
                author: "Menander"
            },
            {
                quote: "When a man opens a car door for his wife, it’s either a new car or a new wife.",
                author: "Prince Philip"
            },
            {
                quote: "My most brilliant achievement was my ability to be able to persuade my wife to marry me.",
                author: "Winston Churchill"
            },
            {
                quote: "Marriage lets you annoy one special person for the rest of your life.",
                author: "Anonymous"
            },
            {
                quote: "The great marriages are partnerships. It can’t be a great marriage without being a partnership.",
                author: "Helen Mirren"
            },
            {
                quote: "A successful marriage requires falling in love many times, always with the same person.",
                author: "Mignon McLaughlin"
            },
            {
                quote: "A good marriage is one which allows for change and growth in the individuals and in the way they express their love.",
                author: "Pearl S. Buck"
            },
            {
                quote: "Marriage is not about age; it’s about finding the right person.",
                author: "Sophia Bush"
            },
            {
                quote: "It is not a lack of love, but a lack of friendship that makes unhappy marriages.",
                author: "Friedrich Nietzsche"
            },
            {
                quote: "Marriage, to women as to men, must be a luxury, not a necessity; an incident of life, not all of it.",
                author: "Susan B. Anthony"
            },
            {
                quote: "A good marriage is the union of two good forgivers.",
                author: "Ruth Bell Graham"
            },
            {
                quote: "A good marriage is a contest of generosity.",
                author: "Diane Sawyer"
            },
            {
                quote: "great marriage isn’t something that just happens, it’s something that must be created.",
                author: "Fawn Weaver"
            },
            {
                quote: "Happy marriages begin when we marry the ones we love, and they blossom when we love the ones we marry",
                author: "Tom Mullen"
            },
            {
                quote: "A happy marriage is a long conversation which always seems too short.",
                author: "Andre Maurois"
            },
        ]
    }),
    components: { FooterSection }
}
</script>
<style scoped>

</style>