<template>
    <v-container>
        <v-row>
            <v-col lg="12" cols="12">
                <v-btn
                    class="text-right float-start orange-gradient white--text text-capitalize font-weight-bold pl-3 pr-3 elevation-4"
                    @click="dialogs.file_input = !dialogs.file_input">Upload Documents</v-btn>
            </v-col>
            <v-col lg="12" cols="12">
                <v-card>
                    <v-data-table :headers="interested_headers" :items="user_documents" :loading="loaders.list_loader">
                    <template v-slot:[`item.uri`]="{ item }">
                        <v-btn :href="item.uri" link icon target="_top"><v-icon color="primary" >mdi-shield-link-variant</v-icon></v-btn>
                    </template>
                    <template v-slot:[`item.status`]="{  }">
                       <v-chip color="warning" small class="elevation-4 font-weight-bold">Pending Approval</v-chip>
                    </template>


                    <template v-slot:[`item.actions`]="{ item }">
                        <v-dialog v-model="dialog" width="300">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn link text icon v-on="on" v-bind="attrs" @click="dialog = true">
                                    <v-icon small color="red" class="ma-1">
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </template>

                            <v-card>
                                <v-card-title class="headline lighten-2" primary-title>
                                    Confirmation
                                </v-card-title>

                                <v-card-text>
                                    Are you sure you want to delete document. Once deleted it cannot be revert.
                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="red" text @click="deleteDocument(item.id)"
                                        :loading="loaders.reject_loader">
                                        Confirm
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </template>
                </v-data-table>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="dialogs.file_input" width="600" height="500">
            <v-card>
                <ValidationObserver ref="documentuploadform" v-slot="{ handleSubmit }">
                    <v-form @submit.prevent="handleSubmit(uploadDocument)">

                        <v-card-title>
                            <v-col lg="12" cols="12">
                                <ValidationProvider name="Upload Document" rules="required" v-slot="{ errors }">
                                    <v-file-input counter show-size accept="image/*,application/pdf"
                                        label="Upload Document" clearable :error-messages="errors"
                                        v-model="user.documents"></v-file-input>
                                </ValidationProvider>
                            </v-col>
                            <v-col lg="12" cols="12">
                                <ValidationProvider name="Document Type" rules="required" v-slot="{ errors }">
                                    <v-select :items="document_types" label="Document Type" clearable
                                        :error-messages="errors" v-model="user.document_type"></v-select>
                                </ValidationProvider>
                            </v-col>
                        </v-card-title>
                        <v-card-text>
                            <p class="red--text text-capitalize">*Please note documents will be deleted automatically once its verified by Kesari Matrimony Team. </p>
                        </v-card-text>
                        <v-card-actions class="ma-auto">
                            <v-spacer></v-spacer>
                            <v-btn class="rounded-xl" color="primary" type="submit"
                                :loading="loaders.upload_document_loader">Upload</v-btn>
                        </v-card-actions>
                    </v-form>
                </ValidationObserver>
            </v-card>
        </v-dialog>
        <v-snackbar :timeout="5000" v-model="snackbar" top color="indigo" class="black--text">
            {{ message }}
        </v-snackbar>
        <v-snackbar :timeout="5000" v-model="error_snackbar" top color="red" class="white--text">
            {{ message }}
        </v-snackbar>
    </v-container>
</template>
<script>
import axios from 'axios';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
    data: () => ({
        dialog:false,
        interested_headers: [
            {
                text: 'Document Type',
                align: 'start',
                sortable: true,
                value: 'type',
            },
            

            {
                text: 'Link',
                align: 'center',
                sortable: false,
                value: 'uri',
            },
            {
                text: 'Document Status',
                align: 'start',
                sortable: true,
                value: 'status',
            },
            {
                text: 'Actions',
                align: 'center',
                sortable: false,
                value: 'actions',
            },

        ],
        user_documents: [],
        error_snackbar: false,
        user: {
            documents: [],
            document_type: ''
        },
        document_types: ['Identity Proof', 'Address Proof', 'Other'],
        loaders: {
            list_loader:false,
            upload_document_loader: false,
            delete_loader: false,
        },
        preview_urls: [],
        dialogs: {
            file_input: false,
        },
        snackbar: false,
        message: '',
    }),
    created() {
        this.getUserDocuments();
    },
    methods: {
        getUserDocuments() {
            this.loaders.list_loader = true;
            axios.get(`${process.env.VUE_APP_API_URL}/getuserdocuments`).then((response) => {
                if (response.status == 200) {
                    this.user_documents = response.data.user_documents;
                }
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                this.loaders.list_loader = false;
            })
        },
        deleteDocument: function (document_id) {
            this.loaders.delete_loader = true;
            let user_delete = this.user_documents.find(document => document.id === document_id);
            axios.post(`${process.env.VUE_APP_API_URL}/deleteuserdocument`, user_delete).then((response) => {
                if (response.status == 200) {
                    this.message = response.data.message;
                    this.snackbar = true;
                    // console.log(this.user_documents.findIndex((document)=> document.id === document_id));
                    this.user_documents.splice(this.user_documents.findIndex((document) => document.id === document_id), 1);
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status == 422) {
                        this.message = error.response.data.message;
                        this.error_snackbar = true;
                    }
                }
            }).finally(() => {
                this.loaders.delete_loader = false;
                this.dialog = false;
            })
        },

        uploadDocument: function () {
            this.loaders.uploade_image_loader = true;

            axios.post(`${process.env.VUE_APP_API_URL}/uploaddocuments`, this.user, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                if (response.status == 200) {
                    this.message = response.data.message;
                    this.snackbar = true;
                    this.user.document_type = '';
                    this.user.documents = [];
                    this.getUserDocuments();
                }

            }).catch((error) => {
                if (error.response) {
                    if (error.response.status == 422) {
                        this.$refs.documentuploadform.setErrors({
                            "Upload Documents": error.response.data.errors.documents,
                        });
                    }
                    if (error.response.status == 419) {
                        this.message = error.response.data.message;
                        this.error_snackbar = true;
                        this.user.documents = [];
                        this.user.document_type = '';
                        this.dialogs.file_input = false;
                    }
                }

            }).finally(() => {
                this.loaders.uploade_document_loader = false;
                this.user.documents = [];
                this.user.document_type = '';
                this.dialogs.file_input = false;


            });
        }
    },
    components: { ValidationObserver, ValidationProvider }
}
</script>
<style scoped>
.orange-gradient {
    background: linear-gradient(225deg, rgba(253, 44, 121, 1) 0%, rgba(253, 101, 91, 1) 100%) !important;
    color: #fff !important;
}
</style>