<template>
        <div class="pa-0 ma-0">
                <v-container>
                        <v-progress-circular indeterminate color="red" v-if="policy_loader"></v-progress-circular>
                        <div class="policy-documents" v-else>
                                <v-img :src="kesari_logo" height="100" width="150" contain> </v-img>
                                <h3 class="mb-10">Kesari Matrimony Privacy Policy
                                </h3>
                                <v-divider class="pa-3"></v-divider>
                                <div v-html="privacy_policies.body"></div>
                        </div>

                </v-container>
                <FooterSection></FooterSection>
        </div>
</template>
<script>
import FooterSection from '@/components/Global/FooterSection.vue';
import axios from 'axios';
export default {
        mounted() {
                this.getPrivacyPolicies();
        },
        methods: {
                getPrivacyPolicies: function () {
                        this.policy_loader = true;
                        axios.get(`${process.env.VUE_APP_API_URL}/getprivacypolicies`).then((response) => {
                                if (response.status == 200) {
                                        this.privacy_policies = response.data.privacy_policies;
                                }
                        }).catch((error) => {
                                console.log(error);
                        }).finally(() => {
                                this.policy_loader = false;
                        });
                }
        },

        data: () => ({
                policy_loader:false,
                kesari_logo: require("@/assets/images/kesari_logo.png"),
                privacy_policies: {
                        id: '',
                        module: 'privacy_policies',
                        title: '',
                        body: '',
                        is_publish: false,
                }
        }),
        components: { FooterSection }
}
</script>
